import { NoFlyZone } from 'shared/map/model/no-fly-zone.model';
import { createDroneZoneObject } from 'delivery/features/drone-zones/features/drone-zone-controls/features/drone-zone-form/utils/createDroneZoneObject.util';
import { NoFlyZoneControlsProps } from '../model/NoFlyZoneControlsProps.model';
import { useNoFlyZoneContext } from '../reducer/NoFlyZonePageContext';
import { FlowState } from '../reducer/noFlyZonePageDefaultState';
import { useRequestController } from '../../../../hooks';
import { NoFlyZoneForm } from '../../drone-zones/features/DroneZoneControls/NoFlyZoneForm/NoFlyZoneForm';
import { NoFlyZoneCreator } from '../../drone-zones/features/NoFlyZoneCreator/NoFlyZoneCreator';
import { NoFlyZonesList } from './NoFlyZonesList';
import { createOrUpdateNoFlyZone } from '../../drone-zones/features/DroneZoneControls/api/createOrUpdateNoFlyZone.api';

export const NoFlyZoneControls = ({
  systemId,
  flightDomainId,
  flowState,
  noFlyZones,
  worldBox,
  currentlyEditingZone,
  onSelectNoFlyZone,
}: NoFlyZoneControlsProps) => {
  const { requestController } = useRequestController('NoFlyZones');
  const { dispatchNoFlyZone } = useNoFlyZoneContext();

  const isLoading = flowState === FlowState.LOADING;
  const editedNewFlyZone = currentlyEditingZone ?? createDroneZoneObject({ type: 'noFlyZone' });

  const handleUpdateNoFlyZone = (noFlyZone: NoFlyZone) =>
    dispatchNoFlyZone({
      type: 'UPDATE_NO_FLY_ZONE',
      payload: noFlyZone,
    });

  const handleCancelNoFlyZone = (noFlyZone: NoFlyZone) => {
    dispatchNoFlyZone({ type: 'CANCEL_EDITING_NO_FLY_ZONE', payload: noFlyZone });
  };

  const handleSubmitNoFlyZone = (noFlyZone: NoFlyZone) => {
    createOrUpdateNoFlyZone({
      flightDomainId,
      noFlyZone,
      onSuccess: () => dispatchNoFlyZone({ type: 'SAVE_NO_FLY_ZONE_CHANGES', payload: null }),
      requestController,
      systemId,
    });
  };

  const handleNoFlyZoneCreationCancel = () =>
    dispatchNoFlyZone({ type: 'SET_FLOW_STATE', payload: FlowState.LIST });

  switch (flowState) {
    case FlowState.LOADING:
    case FlowState.LIST:
    case FlowState.DELETE:
      return (
        <NoFlyZonesList
          noFlyZones={noFlyZones}
          isLoading={isLoading}
          onSelectNoFlyZone={onSelectNoFlyZone}
          systemId={systemId}
          flightDomainId={flightDomainId}
        />
      );
    case FlowState.DRAW:
      return <NoFlyZoneCreator onCancelCreateNFZ={handleNoFlyZoneCreationCancel} />;
    case FlowState.CREATE:
      return (
        <NoFlyZoneForm
          noFlyZone={editedNewFlyZone}
          worldBox={worldBox}
          onUpdate={handleUpdateNoFlyZone}
          onCancel={handleCancelNoFlyZone}
          onSubmit={handleSubmitNoFlyZone}
        />
      );
    default:
      return null;
  }
};
