import { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { IIssueLogicRules1ST } from 'codegen/facility_settings';
import { createTreeItems } from './utils/createTreeItems';
import { getAllItemIds } from './utils/getAllItemIds';

const CustomTreeItem = styled(TreeItem)({
  [`& .${treeItemClasses.label}`]: {
    paddingLeft: '1em',
    fontSize: 'large',
    fontFamily: 'monospace',
  },
});

export const CustomIssueLogicVisualizer = ({
  issueLogicRules,
}: {
  issueLogicRules: IIssueLogicRules1ST;
}) => {
  const { rules } = issueLogicRules;
  const items = createTreeItems(rules);
  const [expandedItems, setExpandedItems] = useState(getAllItemIds(items));

  const handleItemExpansionToggle = (
    event: React.SyntheticEvent,
    itemId: string,
    isExpanded: boolean,
  ) => {
    if (isExpanded) {
      setExpandedItems((prevExpandedItems) => [...prevExpandedItems, itemId]);
    } else {
      setExpandedItems((prevExpandedItems) => prevExpandedItems.filter((id) => id !== itemId));
    }
  };

  return (
    <Box component="div" paddingTop="1em" className="v-flex-center" px={2}>
      <RichTreeView
        items={items}
        expandedItems={expandedItems}
        onItemExpansionToggle={handleItemExpansionToggle}
        slots={{ item: CustomTreeItem }}
      />
    </Box>
  );
};
