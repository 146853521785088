import { TreeViewBaseItem } from '@mui/x-tree-view/models';

export function getAllItemIds(treeData: TreeViewBaseItem[]) {
  const allItemIds: string[] = [];

  const traverse = (node: TreeViewBaseItem) => {
    allItemIds.push(node.id);
    if (node.children) {
      node.children.forEach(traverse);
    }
  };

  treeData.forEach(traverse);
  return allItemIds;
}
