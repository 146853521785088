import { Chip, Tooltip } from '@mui/material';
import { MuiColor } from '../utils/WHSFunctions';

export enum ISSUE_STATE {
  NEW = 'NEW',
  KNOWN = 'KNOWN',
  SNOOZED = 'SNOOZED',
  SOLVED = 'SOLVED',
  NONE = 'NONE',
  NA = 'NA',
}

type IssueStateCellProps = {
  /**
   * Type of the issue
   */
  state: ISSUE_STATE;
};

const ISSUE_STATE_COLOR = {
  [ISSUE_STATE.NEW]: 'error',
  [ISSUE_STATE.KNOWN]: 'error',
  [ISSUE_STATE.SNOOZED]: 'info',
  [ISSUE_STATE.SOLVED]: 'success',
  [ISSUE_STATE.NONE]: 'info',
  [ISSUE_STATE.NA]: 'info',
};

/**
 * Component rendering the content of a Data Grid Cell
 * containing the state of an issue
 * @param props
 * @returns JSX rendering of the component
 */
export const IssueStateCell = (props: IssueStateCellProps) => {
  const muiColor = ISSUE_STATE_COLOR[props.state] as MuiColor;
  const variant = props.state === 'NONE' ? 'outlined' : 'filled';
  return (
    <Tooltip title={props.state} className="c-data-grid-cell">
      <Chip size="small" color={muiColor} variant={variant} label={props.state} />
    </Tooltip>
  );
};
