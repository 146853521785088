import { Grid } from '@mui/material';
import { ReportSummaryData } from 'udb/inventory/reports/reducer/report-store/ReportStore.model';
import { IssueSummary, IssueSummaryCard } from './issue-summary-card/IssueSummaryCard';
import { ReportSummaryCard } from './report-summary-card/ReportSummaryCard';

export const ReportOverview = ({
  summarySpinner,
  issuesSpinner,
  issuesSummary,
  canReviewReport,
  reportNeedsReview,
  reportLocationData,
  reportExecutionData,
}: {
  summarySpinner: number;
  issuesSpinner: number;
  issuesSummary: IssueSummary;
  canReviewReport: boolean;
  reportNeedsReview: boolean;
  reportLocationData: ReportSummaryData;
  reportExecutionData: ReportSummaryData;
}) => (
  <Grid spacing={3} container className="c-page-content">
    <Grid lg={4} sm={12} xs={12} item>
      <ReportSummaryCard
        cardFor="summary data"
        isLoading={summarySpinner}
        reportSummaryData={reportLocationData}
      />
    </Grid>

    <Grid lg={4} sm={12} xs={12} item>
      <ReportSummaryCard
        cardFor="execution data"
        isLoading={summarySpinner}
        reportSummaryData={reportExecutionData}
      />
    </Grid>

    {(!canReviewReport || !reportNeedsReview) && (
      <Grid lg={4} sm={12} xs={12} item>
        <IssueSummaryCard isLoading={!!issuesSpinner} issuesSummary={issuesSummary} />
      </Grid>
    )}
  </Grid>
);
