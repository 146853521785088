import { Children, ReactNode } from 'react';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { SxProps, Theme, useTheme } from '@mui/system';

import { Box } from 'components/common/Box';
import Spinner from 'components/common/Spinner';
import { baseCardStyle } from './styles';

/**
 * Interface for the action buttons of the Base Card Props
 */
export interface IBaseCardActionButton {
  testId?: string;
  /**
   * Button label
   */
  label: string;
  clickHandler?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: 'text' | 'outlined' | 'contained';
  disabled?: boolean;
  fullWidth?: boolean;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

/**
 * Interface for the props of the BaseCard
 */
export interface IBaseCardProps {
  /**
   * specifies what is this card used for
   */
  cardFor: string;
  /**
   * show Card header section, default=true
   */
  showHeader?: boolean;

  sx?: SxProps<Theme>;
  /**
   * show Card content section, default=true
   */
  showContent?: boolean;
  /**
   * show Card action buttons section, default=true
   */
  showActionButtons?: boolean;
  /**
   * show Card header divider, default=true
   */
  showHeaderDivider?: boolean;
  /**
   * if this value is other then 0, spinner will be shown in content section, default=0
   */
  isLoading?: boolean | number;
  /**
   * Label attached to the spinner (when it is shown)
   */
  spinnerLabel?: any;
  /**
   * icon to be shown in the left part of Card header
   */
  avatar?: any;
  /**
   * Card header title
   */
  title: string;
  /**
   * Card header subtitle
   */
  subtitle: string | ReactNode;
  /**
   * Shows number of items in the upper right corner of Card header (ex. number of issues)
   */
  counter?: number | ReactNode;
  /**
   * String under the counter (ex. "issues")
   */
  counterSubject?: string;
  /**
   * Defines buttons to be rendered
   */
  actionButtons?: IBaseCardActionButton[];
  /**
   * Custom text for an empty state of the card
   */
  customEmptyState?: string;
  children: any;
  uploadCard?: any;
  dataTestId?: any;
  alertElement?: ReactNode;
  noData?: any;
}

const BaseCard = (props: IBaseCardProps) => {
  const {
    cardFor,
    showHeader = true,
    showContent = true,
    showActionButtons = true,
    showHeaderDivider = true,
    isLoading = 0,
    spinnerLabel,
    avatar,
    title,
    subtitle,
    noData,
    counter,
    counterSubject,
    actionButtons,
    children,
    uploadCard,
    dataTestId,
    customEmptyState,
    alertElement,
    sx = {},
  } = props;

  const { classes } = baseCardStyle();
  const theme = useTheme();

  return (
    <Card
      data-testid={dataTestId}
      className={classes.card}
      elevation={3}
      sx={{ background: noData ? theme.palette.shades.lightest : 'white', ...sx }}
    >
      {showHeader && (
        <CardHeader
          className={classes.cardSection}
          avatar={avatar}
          title={title}
          subheaderTypographyProps={{ style: { wordBreak: 'break-word' } }}
          subheader={noData ? 'No data available.' : subtitle}
          action={
            <Box
              className={classes.actionContainer}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              {noData ? (
                <Box mr={2.5} mt={2.5}>
                  <CloudOffIcon
                    sx={(theme) => ({
                      color: theme.palette.iconDisabled,
                    })}
                  />
                </Box>
              ) : (
                <Typography
                  data-testid={`c-occupancy-summary-${counterSubject}`}
                  className={classes.action}
                  variant="h4"
                  lineHeight={1.3}
                >
                  {counter ?? ' '}
                </Typography>
              )}
              <Typography className={classes.action}>{counterSubject}</Typography>
            </Box>
          }
          classes={{
            title: classes.title,
            subheader: classes.subheader,
          }}
        />
      )}

      {showHeaderDivider && <Divider />}

      {alertElement}

      {showContent && (
        <CardContent className={classes.cardContentSection}>
          {!isLoading && Children.count(children) ? (
            <List
              data-testid="c-card-content-wrapper"
              className={uploadCard ? classes.uploadCardContentList : classes.cardContentList}
              component="ul"
            >
              {children}
            </List>
          ) : (
            <Typography
              className={classes.emptyCard}
              variant="subtitle1"
              color="textSecondary"
              component="p"
            >
              {!isLoading && customEmptyState ? customEmptyState : `No ${cardFor} to show`}
            </Typography>
          )}
          {isLoading ? <Spinner label={spinnerLabel} /> : null}
        </CardContent>
      )}

      {showActionButtons && actionButtons && (
        <CardActions className={classes.cardSection}>
          {!isLoading &&
            actionButtons.map((button, i) => (
              <Button
                data-testid={button.testId}
                onClick={button.clickHandler}
                variant={button.variant}
                disabled={button.disabled || noData}
                fullWidth={button.fullWidth}
                color={button.color}
                key={`${title}-${dataTestId}-${button.label}`}
              >
                {button.label}
              </Button>
            ))}
        </CardActions>
      )}
    </Card>
  );
};

export default BaseCard;
