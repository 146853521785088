import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { cleanGridState } from './cleanGridState.util';

export const onSaveCustomGrid = ({
  gridApiRef,
}: {
  gridApiRef: React.MutableRefObject<GridApiPremium>;
}) => {
  if (gridApiRef?.current?.exportState) {
    let currentState = gridApiRef.current.exportState();
    currentState = cleanGridState(currentState);
    if (!Object.keys(currentState).length) {
      return; // The object is empty so, let's not add it to the URL
    }
    const serializedState = JSON.stringify(currentState);

    const savedGridState = localStorage.getItem('savedGridState');

    if (serializedState !== savedGridState) {
      localStorage.setItem('savedGridState', serializedState);
    }
  }
};
