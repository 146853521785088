export const serachFieldColumns = [
  'issues.0.type',
  'verity_status.state',
  'wms_status.state',
  'wasFoundAt',
  'wms_status.qtys.0',
  'wms_status.customers.0',
  'shouldBeAt',
  'issues.0.state',
];
