import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { find, findIndex, isArray } from 'lodash';
import { enqueueSnackbar } from 'notistack';

import Grid from '@mui/material/Grid';

import { getRowForFullReportTable } from 'common/functions/locationRows/locationRowsFunctions';
import { DISPLAY_ISSUE_TYPES_KEYS, ISSUE_STATES } from 'common/issueTypesAndStates';

import Spinner from 'components/common/Spinner';
import ReportStore from 'udb/inventory/reports/reducer/report-store/ReportStore';
import IssuesStore from 'store/IssuesStore';
import { ISSUE_ACTIONS } from 'common/Actions/actionTypes';
import { singleRequestHandler } from 'common/requestHelpers';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import { useFacilityModalsStore } from '../../../store/Modals';
import { useSettingsStore } from '../../../store/Settings/settingsStore';
import WarehouseStore from '../../../store/WarehouseStore';
import { useRequestController } from '../../../hooks';

import ModalBase from '../ModalBase';
import EnhancedTable from '../../EnhancedTable';
import {
  IFacilityModalsState,
  ILocationData,
} from '../../../store/Modals/facilityModals/IFacilityModalsStore';
import { IIssueST, ISlotStatusST } from '../../../interfaces/slotsAndIssuesInterfaces';
import { userHasPermission } from '../../../features/permissions/userHasPermission';
import { PERMISSION } from '../../../features/permissions/permissions.model';
import { LocationModalHelp } from './LocationModalHelp';
import { AmendValues } from './amend/AmendValues';
import { ImageCarousel } from './image-carousel/ImageCarousel';
import { ShareFeedback } from './ShareFeedback/ShareFeedback';
import { FeedbackReasonType } from './ShareFeedback/feedbackReason.model';
import { LocationModalHeader } from './header/LocationModalHeader';
import { getNavigationButtonsTooltip } from './utils/getNavigationButtonsTooltip';
import { getLocationModalTitle } from './utils/getLocationModalTitle';
import { getIsSnoozable } from './utils/getIsSnoozable';

interface ILocationModalProps {
  opened: boolean;
  closeModal: () => void;
  refreshDataOnClose: IFacilityModalsState['refreshDataOnClose'];
  data: ILocationData | any[]; // This needs to be fixed
  parentPage: IFacilityModalsState['parentPage'];
  reportId: string | null | undefined;
}

export const initialLocationData: ILocationData = {
  userOverride: '',
  verityState: '-',
  location: '',
  version: '-',
  verityValue: '',
  wmsState: '-',
  wmsValue: '',
  slotStatusVersion: '',
  isReview: false,
  userOverrideValue: '',
  length: 0,
  issueType: DISPLAY_ISSUE_TYPES_KEYS.NONE_V_DATA_OUTDATED,
};

export const LocationModal = (props: ILocationModalProps) => {
  const { opened, closeModal, refreshDataOnClose, data, parentPage, reportId } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const { WHS_FULL_REPORT_COLUMNS, REPORT_FULL_REPORT_COLUMNS } = useSettingsStore();
  const { facilityModalsState } = useFacilityModalsStore();

  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [locationsData] = useState(data);
  const [locationData, setLocationData] = useState<ILocationData>(initialLocationData);
  const [currentLocationData, setCurrentLocationData] = useState(
    isArray(data) ? find(data, (issue) => issue.selected) : data,
  );
  const [currentLocationIndex, setCurrentLocationIndex] = useState(
    isArray(data) ? findIndex(data, (issue) => issue.selected) : 0,
  );
  const [didUserOverwrite, setDidUserOverwrite] = useState(false);

  const { currentSystemId: systemId, facilitySettings } =
    useFacilityLevelStore().stateFacilityLevel;

  const showAmendForm = facilitySettings.allow_user_amend || false;
  const showReportLocationForm =
    facilitySettings.support_email_recipients !== '' &&
    userHasPermission(PERMISSION.SHARE_FEEDBACK_ON_LOCATION);

  const { requestController } = useRequestController(`${parentPage} - LocationModal`);

  // FIX-ME::TR::2021-08-11:: Re-instate when re-instating the download images function
  // const { name: facilityName } = state.facilityData;

  // update the modal's internal data structures
  // - the list of locations to navigate through
  // - the location currently being displayed
  const updateCurrentLocationAndLocationSet = useCallback(
    (loc: ISlotStatusST, issues: IIssueST[]) => {
      const rowDataAux = getRowForFullReportTable(loc.slot_label, loc, issues);
      const locData = rowDataAux.actions.data;

      setCurrentLocationData(locData);
    },
    [],
  );

  const refreshLocationData = useCallback(
    (locationLabel: string) => {
      switch (parentPage) {
        case 'WarehouseStatus':
          requestController.doRequest({
            request: WarehouseStore.getLocationsData,
            requestParams: [systemId, locationLabel, 1],
            callbackBeforeSend: () => setSpinnerLoading(true),
            callbackSuccess: (r) =>
              updateCurrentLocationAndLocationSet(
                r.locationsData[locationLabel],
                r.locationsData[locationLabel].issues,
              ),
            messageErrorFallback: 'Location Data could not be fetched.',
            callbackFinally: () => setSpinnerLoading(false),
          });
          break;

        case 'Report':
          requestController.doRequest({
            request: ReportStore.getReportData,
            requestParams: [
              systemId,
              reportId,
              locationLabel,
              locationLabel,
              requestController.signal,
            ],
            callbackBeforeSend: () => setSpinnerLoading(true),
            callbackSuccess: (r) =>
              updateCurrentLocationAndLocationSet(
                r.reportData.locations_data[locationLabel],
                r.reportData.issues[locationLabel],
              ),
            messageErrorFallback: 'Report Data could not be fetched.',
            callbackFinally: () => setSpinnerLoading(false),
          });
          break;

        default:
          break;
      }
    },
    [parentPage, reportId, requestController, systemId, updateCurrentLocationAndLocationSet],
  );

  const handleCloseModal = () => {
    if (didUserOverwrite) {
      refreshDataOnClose.refreshData();
    }
    searchParams.delete('location');
    setSearchParams(searchParams);
    closeModal();
  };

  const refreshData = () => {
    setDidUserOverwrite(true);
    refreshLocationData(locationData?.location);
  };

  const sendLocationInfo = (feedbackCategories: FeedbackReasonType[], message: string) => {
    const slotStatusVersion =
      currentLocationData.slotStatusVersion === '-' ? null : currentLocationData.slotStatusVersion;
    // Get the current URL and pass it as link to get back to this modal.
    // NOTE: as the location feedback can be done only form here, this is sufficient.
    const linkToTheCurrentLocation = window.location.href;

    return singleRequestHandler({
      request: WarehouseStore.sendLocationInfoEmail,
      requestParams: [
        systemId,
        currentLocationData.location,
        slotStatusVersion,
        currentLocationData?.wmsSlotStatusVersion || null,
        parentPage,
        reportId || null,
        feedbackCategories,
        message,
        linkToTheCurrentLocation,
      ],
      dispatcher: enqueueSnackbar,
      callbackBeforeSend: () => setSpinnerLoading(true),
      callbackFinally: () => setSpinnerLoading(false),
      messageSuccess: `Feedback on location ${currentLocationData.location} was submitted to Verity.`,
    });
  };

  // Question: why is this needed? locationData == currentLocationData?
  // update current location data
  useEffect(() => {
    // Update location data
    setLocationData(() => ({
      location: currentLocationData.location,
      issueState: currentLocationData.state,
      issueType: currentLocationData.issueType,

      wmsValue: currentLocationData.wmsValue,
      wmsState: currentLocationData.wmsState,
      wmsDate: currentLocationData.wmsDate,
      wmsArticleNo: currentLocationData.wmsArticleNo,
      wmsDescription: currentLocationData.wmsDescription,
      wmsQty: currentLocationData.wmsQty,
      wmsSlotStatusVersion: currentLocationData.wmsSlotStatusVersion,

      verityValue: currentLocationData.verityValue,
      verityState: currentLocationData.verityState,
      verityDate: currentLocationData.verityDate,
      verityAmended: currentLocationData.isReview ? currentLocationData.verityAmended : '',
      originalVerityState: currentLocationData.originalVerityState,
      originalVerityValue: currentLocationData.originalVerityValue,

      userOverride: currentLocationData.userOverride,
      userOverrideValue: currentLocationData.userOverrideValue,
      userOverrideUserName: currentLocationData.userOverrideUserName,
      showContentSetByUser: currentLocationData.userOverride,

      // Use version if only if it exists.
      // A version does not exist when there is no verity_slot_status for a given location
      version:
        currentLocationData.slotStatusVersion !== '-'
          ? currentLocationData.slotStatusVersion
          : null,

      length: currentLocationData.length,
      // Data to be displayed on the table
      rowData: currentLocationData.rowData,

      isReview: currentLocationData.isReview,
      isSnoozed: currentLocationData.isSnoozed,
      slotStatusVersion: currentLocationData.slotStatusVersion,
    }));
  }, [currentLocationData]);

  const issueId = locationData.rowData?.issueId;

  const snooze = () =>
    IssuesStore.toggleSnoozeIssue(systemId!, issueId, ISSUE_ACTIONS.SNOOZE).then(() => {
      refreshLocationData(locationData?.location);
    });

  const isSnoozed = locationData.rowData?.state === ISSUE_STATES.SNOOZED;

  const showAmendAndFeedbback = showReportLocationForm;
  const showAmendValuesOnly = showAmendForm && !showAmendAndFeedbback;

  return (
    <ModalBase
      help={LocationModalHelp}
      testId="c-location-modal"
      opened={opened}
      maxWidth={false}
      disableBackdropClick={true}
      fullScreen
      title={
        <LocationModalHeader
          locationData={locationData}
          allLocations={locationsData as ILocationData[]}
          title={getLocationModalTitle(locationData.issueType, locationData.location)}
          handleCloseModal={handleCloseModal}
          currentLocationData={currentLocationData}
          setCurrentLocationData={setCurrentLocationData}
          refreshLocationData={refreshLocationData}
          currentLocationIndex={currentLocationIndex}
          setCurrentLocationIndex={setCurrentLocationIndex}
          NavigationButtonsTooltip={getNavigationButtonsTooltip(facilityModalsState.locationInfo)}
        />
      }
      handleClose={handleCloseModal}
    >
      {spinnerLoading && <Spinner />}
      <EnhancedTable
        disablePagination={true}
        showHeader={false}
        headCells={
          parentPage === 'WarehouseStatus' ? WHS_FULL_REPORT_COLUMNS : REPORT_FULL_REPORT_COLUMNS
        }
        rows={locationData?.rowData ? [locationData.rowData] : []}
        isLoading={false}
        refreshData={{
          refreshData,
        }}
        showSearch={false}
        hideToolbar={true}
        enableHoverOnRows={false}
        allowSorting={false}
        currentActiveSpinner={{}}
        headerButton={undefined}
        tableFor=""
        tableSubtitle=""
        tableTitle=""
      />

      <Grid spacing={2} container>
        <Grid lg={6} md={6} sm={12} item>
          <ImageCarousel
            systemId={systemId || ''}
            locationData={locationData}
            currentLocationData={currentLocationData}
          />
        </Grid>
        {showAmendValuesOnly && (
          <Grid lg={6} md={6} sm={12} item>
            <AmendValues
              locationData={locationData}
              setSpinnerLoading={setSpinnerLoading}
              refreshLocationData={refreshLocationData}
              setDidUserOverwrite={setDidUserOverwrite}
              currentLocationIndex={currentLocationIndex}
            />
          </Grid>
        )}
        {showAmendAndFeedbback && (
          <Grid lg={6} md={6} sm={12} item>
            <AmendValues
              locationData={locationData}
              setSpinnerLoading={setSpinnerLoading}
              refreshLocationData={refreshLocationData}
              setDidUserOverwrite={setDidUserOverwrite}
              currentLocationIndex={currentLocationIndex}
            />
            <ShareFeedback
              key={locationData.location}
              isSnoozed={isSnoozed}
              isSnoozable={getIsSnoozable(locationData)}
              snooze={snooze}
              sendLocationInfo={sendLocationInfo}
            />
          </Grid>
        )}
      </Grid>
    </ModalBase>
  );
};
