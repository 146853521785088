import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMapStore } from 'shared/map-container/reducer/3DmapStore';
import { OpenFlyZonesDTO } from 'shared/map/model/open-fly-zone.model';
import { ControlledZone, ControlledZonesDTO } from 'shared/map/model/controlled-zone.model';
import { Vec6 } from 'shared/map/model/geometry.model';
import { webSocket } from 'shared/web-socket/web-socket';
import { DroneZoneDetailsPanel } from 'shared/map/features/drone-zone-details-panel/drone-zone-details-panel';
import { mapSelectedAndEditedZone } from 'shared/map/utils/map-selected-zone';
import { mapSortDetailsZones } from 'shared/map/utils/map-sort-details-zones/mapSortDetailsZones.util';
import { MapContainer } from 'shared/map-container/MapContainer';
import PageHeaderSection from 'components/Page/PageHeaderSection';
import { DroneZoneMapLegend } from 'shared/map/features/drone-zone-map-legend/DroneZoneMapLegend';
import { useStyles } from './DroneZonePage.styles';

import { DEFAULT_VISIBLE_ZONES, VisibleZones } from './defaults/visibleZones.defaults';
import { droneZonePageReducer } from './reducer/droneZonePage.reducer';
import { droneZonePageStateDefaults } from './defaults/droneZonePageState.defaults';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { subscribeToOpenFlyZoneSocket } from './web-socket/subscribeToOpenFlyZoneSocket';
import { subscribeToControlledZoneSocket } from './web-socket/subscribeToControlledZoneSocket';
import { FlowState } from './reducer/droneZonePage.model';
import { DroneZoneControls } from './features/drone-zone-controls/droneZoneControls';
import { DroneZonesMap, wrapperId } from './features/drone-zones-map/droneZonesMap';
import { DeleteDroneZoneModal } from './features/delete-drone-zone-modal/deleteDroneZoneModal';
import { subscribers } from './web-socket/subscribers';
import { DeliveryDroneZoneTypes } from './models/deliveryDroneZones.model';
import { DroneZoneCommand } from './features/drone-zone-command/droneZoneCommand.type';
import { droneZoneCommandFactory } from './features/drone-zone-command/droneZoneCommandFactory';
import { mapLegendPlanning } from './defaults/droneZoneMapLegends.defaults';
import { DroneZoneVisibilityToggles } from './features/drone-zones-visibility-toggle/droneZonesVisibilityToggles';
import { ControlledZoneAPI } from './api/controlled-zone';
import { OpenFlyZoneAPI } from './api/open-fly-zone';

export const DroneZonesPage = () => {
  const [
    {
      openFlyZones,
      controlledZones,
      flowState,
      currentlyDeletingZone,
      currentlyEditingZone,
      detailsZones,
      selectedZone,
    },
    dispatchZoneStatus,
  ] = useReducer(droneZonePageReducer, droneZonePageStateDefaults);

  const { enqueueSnackbar } = useSnackbar();
  const { systemId = '', flightDomainId = '' } = useParams();

  const { classes } = useStyles();
  const { mapState } = useMapStore();

  const mapHeight = mapState.map?.box?.[5];
  const isDetailsPanelOpen = !!(detailsZones?.length && mapHeight);

  const sortedDetailsZones = mapSortDetailsZones(
    { ...controlledZones, ...openFlyZones },
    detailsZones,
  );

  const [visibleZones, setVisibleZones] = useState<VisibleZones>(DEFAULT_VISIBLE_ZONES);

  const socket = webSocket();

  const setControlledZones = (controlledZonesDTO: ControlledZonesDTO) =>
    dispatchZoneStatus({
      type: 'SET_CONTROLLED_ZONES',
      payload: controlledZonesDTO,
    });

  const setOpenFlyZones = (openFlyZoneDTO: OpenFlyZonesDTO) =>
    dispatchZoneStatus({
      type: 'SET_OPEN_FLY_ZONES',
      payload: openFlyZoneDTO,
    });

  const handleToggleVisibleZones = (zoneType: DeliveryDroneZoneTypes) =>
    setVisibleZones((prevState) => ({ ...prevState, [zoneType]: !prevState[zoneType] }));

  const onDroneZoneCommand = (droneZoneCommand: DroneZoneCommand) => {
    droneZoneCommand.execute(dispatchZoneStatus);
  };

  const handleSelectFromDroneZoneGroup = (droneZone: ControlledZone) => {
    dispatchZoneStatus({
      type: 'SELECT_SINGLE_FROM_DRONE_ZONE_GROUP',
      payload: droneZone,
    });
  };

  const handleSelectDroneGroup = (droneZones: ControlledZone[]) => {
    dispatchZoneStatus({
      type: 'SELECT_MULTIPLE_DRONE_ZONE',
      payload: droneZones,
    });
  };

  const isDeletionModalVisible: boolean =
    flowState === FlowState.DELETE && currentlyDeletingZone !== null;

  const mappedControlledZones = useMemo(
    () =>
      mapSelectedAndEditedZone({
        zones: controlledZones,
        selectedZoneId: selectedZone?.id ?? '',
        editedZoneId: currentlyEditingZone?.id ?? '',
      }),
    [controlledZones, selectedZone?.id, currentlyEditingZone?.id],
  );

  const handleDroneZoneDragEnd = (droneZone: ControlledZone) =>
    dispatchZoneStatus({
      type: 'END_DRAGGING_DRONE_ZONE',
      payload: droneZone,
    });

  useEffect(() => {
    subscribeToOpenFlyZoneSocket(flightDomainId, setOpenFlyZones, socket);
    subscribeToControlledZoneSocket(flightDomainId, setControlledZones, socket);

    return () => Object.values(subscribers).forEach((subscriber) => socket.unsubscribe(subscriber));
  }, [socket, flightDomainId]);

  useComponentDidMount(async () => {
    dispatchZoneStatus({ type: 'SET_FLOW_STATE', payload: FlowState.LOADING });

    Promise.all([
      ControlledZoneAPI.getControlledZones(systemId, flightDomainId),
      OpenFlyZoneAPI.getOpenFlyZones(systemId, flightDomainId),
    ])
      .then(([controlledZoneResponse, openFlyZoneResponse]) => {
        setControlledZones(controlledZoneResponse.data.controlled_zones);
        setOpenFlyZones(openFlyZoneResponse.data.open_fly_zones);
      })
      .catch((e) => {
        enqueueSnackbar('Error fetching drone zones', { variant: 'error' });
        console.error(e);
      })
      .finally(() => {
        dispatchZoneStatus({ type: 'SET_FLOW_STATE', payload: FlowState.LIST });
      });
  });

  return (
    <>
      <PageHeaderSection
        title="Drone zones"
        subtitle="Create, control and preview drone zones"
        isGroundControlPage={false}
        showLoadedSince={false}
      />

      <Container maxWidth="xl">
        <div className={classes.mapWrapper}>
          <div className={classes.controlBarMap}>
            <DroneZoneVisibilityToggles
              visibleZones={visibleZones}
              onChange={(droneZoneType) => handleToggleVisibleZones(droneZoneType)}
            />
          </div>

          <div id={wrapperId} className={classes.map}>
            <MapContainer systemId={systemId} flightDomainId={flightDomainId}>
              <DroneZonesMap
                controlledZones={mappedControlledZones}
                openFlyZones={openFlyZones}
                visibleZones={visibleZones}
                isDrawingNewDroneZone={flowState === FlowState.DRAW}
                onEndDroneZoneDraw={(zone: ControlledZone) =>
                  onDroneZoneCommand(droneZoneCommandFactory.createCloneCommand(zone))
                }
                onDroneZoneClick={(zone: ControlledZone | null) => {
                  onDroneZoneCommand(droneZoneCommandFactory.createSelectCommand(zone));
                }}
                onDroneZoneGroupClick={handleSelectDroneGroup}
                onDroneZoneDragEnd={handleDroneZoneDragEnd}
              />
            </MapContainer>

            <div className={classes.legend}>
              <DroneZoneMapLegend entries={mapLegendPlanning} />
            </div>

            {isDetailsPanelOpen && (
              <div className={classes.details}>
                <DroneZoneDetailsPanel
                  zones={sortedDetailsZones}
                  areaHeight={mapHeight}
                  selectedZone={selectedZone}
                  onClose={() =>
                    onDroneZoneCommand(droneZoneCommandFactory.createSelectCommand(null))
                  }
                  onSelect={handleSelectFromDroneZoneGroup}
                />
              </div>
            )}
          </div>

          <div className={classes.sidemenu}>
            <DroneZoneControls
              systemId={systemId}
              flightDomainId={flightDomainId}
              flowState={flowState}
              visibleZones={visibleZones}
              currentlyEditingZone={currentlyEditingZone}
              controlledZones={mappedControlledZones}
              worldBox={mapState.map?.box! as Vec6}
              onDroneZoneCommand={onDroneZoneCommand}
            />
          </div>
        </div>
      </Container>

      {isDeletionModalVisible && (
        <DeleteDroneZoneModal
          droneZone={currentlyDeletingZone!}
          onClose={() => onDroneZoneCommand(droneZoneCommandFactory.createCancelDeletingCommand())}
          onDeleted={() =>
            onDroneZoneCommand(droneZoneCommandFactory.createDeletedCommand(currentlyDeletingZone!))
          }
        />
      )}
    </>
  );
};
