import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { Box, Tooltip } from '@mui/material';
import { GridPremiumToolbarButton } from 'common/Tables/toolbar/GridPremiumToolbarButton';
import { SearchField } from '../search-field/SearchField';
import { serachFieldColumns } from '../models/serachFieldColumns.model';

export const WHSToolbar = ({
  onSaveCustomGrid,
  onLoadCustomGrid,
  onSnooze,
  onUnSnooze,
  checkboxSelection,
  setCheckboxSelection,
  gridApiRef,
  searchTerm,
  onUpdateSearchTerm,
}: {
  onSaveCustomGrid: () => void;
  onLoadCustomGrid: () => void;
  onSnooze: () => void;
  onUnSnooze: () => void;
  checkboxSelection: boolean;
  setCheckboxSelection: React.Dispatch<React.SetStateAction<boolean>>;
  gridApiRef: React.MutableRefObject<GridApiPremium>;
  searchTerm: string;
  onUpdateSearchTerm: (newTerm: string) => void;
}) => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    <GridToolbarDensitySelector />
    <GridPremiumToolbarButton
      name="SELECT"
      tooltip="Select Rows"
      onClick={() => setCheckboxSelection(!checkboxSelection)}
      icon={<SelectAllIcon />}
    />
    {checkboxSelection && (
      <GridPremiumToolbarButton
        name="SNOOZE SELECTED"
        tooltip="Snooze select Rows"
        onClick={onSnooze}
        icon={<NotificationsPausedIcon />}
      />
    )}
    {checkboxSelection && (
      <GridPremiumToolbarButton
        name="UNSNOOZE SELECTED"
        tooltip="Unsnooze select Rows"
        onClick={onUnSnooze}
        icon={<AddAlertIcon />}
      />
    )}
    <GridPremiumToolbarButton
      name="SAVE STATE"
      tooltip="Save State"
      onClick={onSaveCustomGrid}
      icon={<BookmarkAddIcon />}
    />
    {localStorage.getItem('savedGridState') && (
      <GridPremiumToolbarButton
        name="LOAD STATE"
        tooltip="Load State"
        onClick={onLoadCustomGrid}
        icon={<BookmarkIcon />}
      />
    )}
    <Box component="div" marginLeft="auto">
      <Tooltip
        title="The export will contain only the visible fields including filters, sorting, etc.
    If you want to export the full content of the warehouse, please make sure that all columns are visilbe and filters are off."
      >
        <GridToolbarExport />
      </Tooltip>
      <SearchField
        searchTerm={searchTerm}
        onUpdateSearchTerm={onUpdateSearchTerm}
        gridApiRef={gridApiRef}
        columns={serachFieldColumns}
      />
    </Box>
  </GridToolbarContainer>
);
