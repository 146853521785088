import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useTabStyles = makeStyles()((theme: Theme) => ({
  tab: {
    color: theme.palette.shades.light,
    minHeight: '46px',
  },
  indicator: {
    display: 'none',
  },
  customTabsStyle: {
    '& .MuiTabScrollButton-root': {
      color: theme.palette.shades.light,
    },
    '& .MuiTabScrollButton-root.Mui-disabled': {
      display: 'none',
    },
    '& .MuiTabs-scroller .Mui-selected': {
      color: theme.palette.shades.darkest,
      backgroundColor: '#D9D9D940',
    },
  },
}));

type TableTabsProps = {
  tabs: {
    icon: JSX.Element;
    label: string;
    show: boolean;
  }[];
  activeTab: number;
  handleTabChange: (...params: any) => void;
};

export const WarehouseStatusXTabs = (props: TableTabsProps) => {
  // props
  const { tabs, activeTab, handleTabChange } = props;

  // styles
  const { classes } = useTabStyles();

  return (
    <Paper square elevation={0}>
      <Tabs
        value={activeTab}
        variant={tabs.length > 5 ? 'scrollable' : 'fullWidth'}
        onChange={handleTabChange}
        scrollButtons="auto"
        aria-label="tabs"
        classes={{
          indicator: classes.indicator,
          root: classes.customTabsStyle,
        }}
      >
        {tabs.map((tab) => (
          <Tab
            data-testid="c-table-tab"
            className={classes.tab}
            key={tab.label}
            icon={tab?.icon}
            iconPosition="start"
            label={tab.label}
          />
        ))}
      </Tabs>
    </Paper>
  );
};
