/* tslint:disable */
/* eslint-disable */
/**
 * Facility Settings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IFacilitySettings1ST
 */
export interface IFacilitySettings1ST {
  /**
   * User can manually upload WMS files
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  allow_manual_wms_file_upload?: boolean;
  /**
   * User can amend results collected by Verity
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  allow_user_amend?: boolean;
  /**
   * Specifies when the automatic abort should start
   * @type {number}
   * @memberof IFacilitySettings1ST
   */
  auto_abort_period_multiplier?: number | null;
  /**
   * Specifies when the automatic approval should start.
   * @type {number}
   * @memberof IFacilitySettings1ST
   */
  auto_approval_period_multiplier?: number | null;
  /**
   * Verity status for locations with issues will be automatically approved
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  auto_approve_verity_status?: boolean;
  /**
   * Specifies when the automatic update should start
   * @type {number}
   * @memberof IFacilitySettings1ST
   */
  auto_update_period_multiplier?: number | null;
  /**
   * Lengths in the interval that are not valid
   * @type {Array<number>}
   * @memberof IFacilitySettings1ST
   */
  barcode_invalid_lengths?: Array<number>;
  /**
   * The name of the handler that decides if Verity and WMS barcodes match
   * @type {string}
   * @memberof IFacilitySettings1ST
   */
  barcode_match_logic_name?: string | null;
  /**
   * The maximum length of a valid barcode
   * @type {number}
   * @memberof IFacilitySettings1ST
   */
  barcode_max_length?: number;
  /**
   * The minimum length of a valid barcode
   * @type {number}
   * @memberof IFacilitySettings1ST
   */
  barcode_min_length?: number;
  /**
   * The list of barcode types used in the facility
   * @type {Array<string>}
   * @memberof IFacilitySettings1ST
   */
  barcode_types?: Array<string>;
  /**
   * If set to true, udb will use wms substring match to clean the barcodes received from drones
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  barcode_wms_substring_match?: boolean;
  /**
   * Do we unconditionally trust the reviewers
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  carefully_trust_reviewers_barcodes?: boolean;
  /**
   * If enabled, the UD connects with the data backend. If disabled, the UD connects to the simulator.
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  connect_to_data_backend?: boolean;
  /**
   * If enabled, the UD connects with the navigation backend. If disabled, the UD connects to the simulator.
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  connect_to_navigation_backend?: boolean;
  /**
   * disclaimer text shown when asking the dual check on system unlock
   * @type {string}
   * @memberof IFacilitySettings1ST
   */
  dual_check_on_unlock_disclaimer?: string | null;
  /**
   * If enabled, the drone zones can be managed using the WMS integration in addition to manually.
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  enable_coexistence_wms_integration?: boolean;
  /**
   * If enabled, UD will stop to communicate task results/requests with control station and will use cloud services
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  enable_csfa_task_request_result?: boolean;
  /**
   * If enabled, UD will not request diagnostic view images to Cloud Insights Generation
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  enable_diagnostic_view_logic?: boolean;
  /**
   * If enabled, an additional check will be asked when unlocking the system
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  enable_dual_check_on_unlock?: boolean;
  /**
   * If enabled, Experimental Features are enabled for all the users of the facility
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  enable_experimental_features?: boolean;
  /**
   * MFA settings for all the users of the facility
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  enable_mfa?: boolean;
  /**
   * Enable SuperSet integration for analytics.
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  enable_superset_integration?: boolean;
  /**
   * Enabled the warehouse status reset
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  enable_warehouse_status_reset?: boolean;
  /**
   * If not enough results are available to submit as a batch to review
   * @type {number}
   * @memberof IFacilitySettings1ST
   */
  external_review_batch_max_wait_time_s?: number;
  /**
   * How many locations to submit together as a single review
   * @type {number}
   * @memberof IFacilitySettings1ST
   */
  external_review_batch_size_desired?: number;
  /**
   * Specifies whether to create new reviews
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  external_review_enabled?: boolean;
  /**
   * Specifies the number of assignments to create for each review
   * @type {number}
   * @memberof IFacilitySettings1ST
   */
  external_review_num_assignments_per_review?: number;
  /**
   * Specifies whether to incorporate the reviewer amendments in the Verity data
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  external_review_overrides_verity_data?: boolean;
  /**
   * Specifies if Verity\'s interpretation of the images are shown to the reviewers
   * @type {string}
   * @memberof IFacilitySettings1ST
   */
  external_review_show_prior?: IFacilitySettings1STExternalReviewShowPriorEnum;
  /**
   * The fraction of verity status for locations without issues that will require review
   * @type {number}
   * @memberof IFacilitySettings1ST
   */
  fraction_of_correct_results_to_be_reviewed?: number;
  /**
   * Names of handlers that modify the issue logics
   * @type {Array<string>}
   * @memberof IFacilitySettings1ST
   */
  issue_logic_filter_names?: Array<string>;
  /**
   *
   * @type {IIssueLogicRules1ST}
   * @memberof IFacilitySettings1ST
   */
  issue_logic_rules?: IIssueLogicRules1ST;
  /**
   * The HIT Layout Id allows one to use a pre-existing HIT design with placeholder
   * @type {string}
   * @memberof IFacilitySettings1ST
   */
  mturk_hit_layout_id?: string;
  /**
   * An amount of time, in seconds, after which the Amazon MTurk HIT is no longer available
   * @type {number}
   * @memberof IFacilitySettings1ST
   */
  mturk_hit_lifetime_in_seconds?: number;
  /**
   * Creating an Amazon MTurk HIT with a HIT Type
   * @type {string}
   * @memberof IFacilitySettings1ST
   */
  mturk_hit_type_id?: string;
  /**
   *
   * @type {IReportDeadlinesST}
   * @memberof IFacilitySettings1ST
   */
  report_deadlines?: IReportDeadlinesST;
  /**
   * Show customer information
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  show_customer?: boolean;
  /**
   * Show Ground Control application to users who have access to the ground control application
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  show_ground_control_app?: boolean;
  /**
   * Show Coexistence map page and cards to users who have access to the ground control application
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  show_coexistence_map?: boolean;
  /**
   * Show potential issues
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  show_potential_issues?: boolean;
  /**
   * Show report deadlines UI
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  show_report_deadlines?: boolean;
  /**
   * show the scheduled lock UI
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  show_scheduled_lock?: boolean;
  /**
   * Comma separated list of support email addresses
   * @type {string}
   * @memberof IFacilitySettings1ST
   */
  support_email_recipients?: string;
  /**
   * Trim leading digits from the barcode value
   * @type {boolean}
   * @memberof IFacilitySettings1ST
   */
  trim_leading_digits?: boolean;
  /**
   * The name of the API handler used to import client WMS data
   * @type {string}
   * @memberof IFacilitySettings1ST
   */
  wms_client_api_handler_name?: string | null;
  /**
   * The comma separated list of emails that are white-listed by the WMS email filter
   * @type {string}
   * @memberof IFacilitySettings1ST
   */
  wms_email_senders?: string;
  /**
   * The regex used to parse the name of the WMS moved location files
   * @type {string}
   * @memberof IFacilitySettings1ST
   */
  wms_file_name_regex_moved_locations?: string | null;
  /**
   * The regex used to parse the name of the WMS snapshots files
   * @type {string}
   * @memberof IFacilitySettings1ST
   */
  wms_file_name_regex_snapshot?: string | null;
  /**
   * The regex used to parse the file name of the WMS stock check files
   * @type {string}
   * @memberof IFacilitySettings1ST
   */
  wms_file_name_regex_stock_check?: string | null;
  /**
   * The maximum number of rows in a WMS excerpt that may reference the same location.
   * @type {number}
   * @memberof IFacilitySettings1ST
   */
  wms_max_num_entries_per_location?: number | null;
  /**
   * The name of the parser used to import WMS data
   * @type {string}
   * @memberof IFacilitySettings1ST
   */
  wms_parser_name?: string | null;
  /**
   * The regex used to validate slot labels when importing WMS data
   * @type {string}
   * @memberof IFacilitySettings1ST
   */
  wms_slot_label_regex?: string;
}

export const IFacilitySettings1STExternalReviewShowPriorEnum = {
  Always: 'ALWAYS',
  NotForControl: 'NOT_FOR_CONTROL',
  Never: 'NEVER',
  Null: 'null',
} as const;

export type IFacilitySettings1STExternalReviewShowPriorEnum =
  typeof IFacilitySettings1STExternalReviewShowPriorEnum[keyof typeof IFacilitySettings1STExternalReviewShowPriorEnum];

/**
 *
 * @export
 * @interface IFacilitySettingsGetResponseST
 */
export interface IFacilitySettingsGetResponseST {
  /**
   *
   * @type {IFacilitySettingsGetResponseSettingsST}
   * @memberof IFacilitySettingsGetResponseST
   */
  settings?: IFacilitySettingsGetResponseSettingsST;
}
/**
 *
 * @export
 * @interface IFacilitySettingsGetResponseSettingsST
 */
export interface IFacilitySettingsGetResponseSettingsST {
  /**
   * User can manually upload WMS files
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  allow_manual_wms_file_upload: boolean;
  /**
   * User can amend results collected by Verity
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  allow_user_amend: boolean;
  /**
   * Specifies when the automatic abort should start
   * @type {number}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  auto_abort_period_multiplier: number | null;
  /**
   * Specifies when the automatic approval should start.
   * @type {number}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  auto_approval_period_multiplier: number | null;
  /**
   * Verity status for locations with issues will be automatically approved
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  auto_approve_verity_status: boolean;
  /**
   * Specifies when the automatic update should start
   * @type {number}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  auto_update_period_multiplier: number | null;
  /**
   * Lengths in the interval that are not valid
   * @type {Array<number>}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  barcode_invalid_lengths: Array<number>;
  /**
   * The name of the handler that decides if Verity and WMS barcodes match
   * @type {string}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  barcode_match_logic_name: string | null;
  /**
   * The maximum length of a valid barcode
   * @type {number}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  barcode_max_length: number;
  /**
   * The minimum length of a valid barcode
   * @type {number}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  barcode_min_length: number;
  /**
   * The list of barcode types used in the facility
   * @type {Array<string>}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  barcode_types: Array<string>;
  /**
   * If set to true, udb will use wms substring match to clean the barcodes received from drones
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  barcode_wms_substring_match?: boolean;
  /**
   * Do we unconditionally trust the reviewers
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  carefully_trust_reviewers_barcodes: boolean;
  /**
   * If enabled, the UD connects with the data backend. If disabled, the UD connects to the simulator.
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  connect_to_data_backend: boolean;
  /**
   * If enabled, the UD connects with the navigation backend. If disabled, the UD connects to the simulator.
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  connect_to_navigation_backend: boolean;
  /**
   * disclaimer text shown when asking the dual check on system unlock
   * @type {string}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  dual_check_on_unlock_disclaimer?: string | null;
  /**
   * If enabled, the drone zones can be managed using the WMS integration in addition to manually.
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  enable_coexistence_wms_integration?: boolean;
  /**
   * If enabled, UD will stop to communicate task results/requests with control station and will use cloud services
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  enable_csfa_task_request_result: boolean;
  /**
   * If enabled, UD will not request diagnostic view images to Cloud Insights Generation
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  enable_diagnostic_view_logic?: boolean;
  /**
   * If enabled, an additional check will be asked when unlocking the system
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  enable_dual_check_on_unlock?: boolean;
  /**
   * If enabled, Experimental Features are enabled for all the users of the facility
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  enable_experimental_features: boolean;
  /**
   * MFA settings for all the users of the facility
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  enable_mfa: boolean;
  /**
   * Enable SuperSet integration for analytics.
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  enable_superset_integration: boolean;
  /**
   * Enabled the warehouse status reset
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  enable_warehouse_status_reset: boolean;
  /**
   * If not enough results are available to submit as a batch to review
   * @type {number}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  external_review_batch_max_wait_time_s: number;
  /**
   * How many locations to submit together as a single review
   * @type {number}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  external_review_batch_size_desired: number;
  /**
   * Specifies whether to create new reviews
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  external_review_enabled: boolean;
  /**
   * Specifies the number of assignments to create for each review
   * @type {number}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  external_review_num_assignments_per_review: number;
  /**
   * Specifies whether to incorporate the reviewer amendments in the Verity data
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  external_review_overrides_verity_data: boolean;
  /**
   * Specifies if Verity\'s interpretation of the images are shown to the reviewers
   * @type {string}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  external_review_show_prior: IFacilitySettingsGetResponseSettingsSTExternalReviewShowPriorEnum;
  /**
   * The fraction of verity status for locations without issues that will require review
   * @type {number}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  fraction_of_correct_results_to_be_reviewed: number;
  /**
   * Names of handlers that modify the issue logics
   * @type {Array<string>}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  issue_logic_filter_names: Array<string>;
  /**
   *
   * @type {IIssueLogicRules1ST}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  issue_logic_rules?: IIssueLogicRules1ST;
  /**
   * The HIT Layout Id allows one to use a pre-existing HIT design with placeholder
   * @type {string}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  mturk_hit_layout_id: string;
  /**
   * An amount of time, in seconds, after which the Amazon MTurk HIT is no longer available
   * @type {number}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  mturk_hit_lifetime_in_seconds: number;
  /**
   * Creating an Amazon MTurk HIT with a HIT Type
   * @type {string}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  mturk_hit_type_id: string;
  /**
   *
   * @type {IReportDeadlinesST}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  report_deadlines: IReportDeadlinesST;
  /**
   * Show customer information
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  show_customer: boolean;
  /**
   * Show Ground Control application to users who have access to the ground control application
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  show_ground_control_app?: boolean;
  /**
   * Show Coexistence map page and cards to users who have access to the ground control application
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  show_coexistence_map?: boolean;
  /**
   * Show potential issues
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  show_potential_issues: boolean;
  /**
   * Show report deadlines UI
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  show_report_deadlines: boolean;
  /**
   * show the scheduled lock UI
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  show_scheduled_lock?: boolean;
  /**
   * Comma separated list of support email addresses
   * @type {string}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  support_email_recipients: string;
  /**
   * Trim leading digits from the barcode value
   * @type {boolean}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  trim_leading_digits: boolean;
  /**
   * The name of the API handler used to import client WMS data
   * @type {string}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  wms_client_api_handler_name: string | null;
  /**
   * The comma separated list of emails that are white-listed by the WMS email filter
   * @type {string}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  wms_email_senders: string;
  /**
   * The regex used to parse the name of the WMS moved location files
   * @type {string}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  wms_file_name_regex_moved_locations: string | null;
  /**
   * The regex used to parse the name of the WMS snapshots files
   * @type {string}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  wms_file_name_regex_snapshot: string | null;
  /**
   * The regex used to parse the file name of the WMS stock check files
   * @type {string}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  wms_file_name_regex_stock_check: string | null;
  /**
   * The maximum number of rows in a WMS excerpt that may reference the same location.
   * @type {number}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  wms_max_num_entries_per_location: number | null;
  /**
   * The name of the parser used to import WMS data
   * @type {string}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  wms_parser_name: string | null;
  /**
   * The regex used to validate slot labels when importing WMS data
   * @type {string}
   * @memberof IFacilitySettingsGetResponseSettingsST
   */
  wms_slot_label_regex: string;
}

export const IFacilitySettingsGetResponseSettingsSTExternalReviewShowPriorEnum = {
  Always: 'ALWAYS',
  NotForControl: 'NOT_FOR_CONTROL',
  Never: 'NEVER',
  Null: 'null',
} as const;

export type IFacilitySettingsGetResponseSettingsSTExternalReviewShowPriorEnum =
  typeof IFacilitySettingsGetResponseSettingsSTExternalReviewShowPriorEnum[keyof typeof IFacilitySettingsGetResponseSettingsSTExternalReviewShowPriorEnum];

/**
 *
 * @export
 * @interface IFacilitySettingsPostResponseST
 */
export interface IFacilitySettingsPostResponseST {
  /**
   * Message
   * @type {string}
   * @memberof IFacilitySettingsPostResponseST
   */
  message?: string;
}
/**
 *
 * @export
 * @interface IFacilitySettingsST
 */
export interface IFacilitySettingsST {
  /**
   * User can manually upload WMS files
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  allow_manual_wms_file_upload?: boolean;
  /**
   * User can amend results collected by Verity
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  allow_user_amend?: boolean;
  /**
   * Specifies when the automatic abort should start
   * @type {number}
   * @memberof IFacilitySettingsST
   */
  auto_abort_period_multiplier?: number | null;
  /**
   * Specifies when the automatic approval should start.
   * @type {number}
   * @memberof IFacilitySettingsST
   */
  auto_approval_period_multiplier?: number | null;
  /**
   * Verity status for locations with issues will be automatically approved
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  auto_approve_verity_status?: boolean;
  /**
   * Specifies when the automatic update should start
   * @type {number}
   * @memberof IFacilitySettingsST
   */
  auto_update_period_multiplier?: number | null;
  /**
   * Lengths in the interval that are not valid
   * @type {Array<number>}
   * @memberof IFacilitySettingsST
   */
  barcode_invalid_lengths?: Array<number>;
  /**
   * The name of the handler that decides if Verity and WMS barcodes match
   * @type {string}
   * @memberof IFacilitySettingsST
   */
  barcode_match_logic_name?: string | null;
  /**
   * The maximum length of a valid barcode
   * @type {number}
   * @memberof IFacilitySettingsST
   */
  barcode_max_length?: number;
  /**
   * The minimum length of a valid barcode
   * @type {number}
   * @memberof IFacilitySettingsST
   */
  barcode_min_length?: number;
  /**
   * The list of barcode types used in the facility
   * @type {Array<string>}
   * @memberof IFacilitySettingsST
   */
  barcode_types?: Array<string>;
  /**
   * If set to true, udb will use wms substring match to clean the barcodes received from drones
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  barcode_wms_substring_match?: boolean;
  /**
   * Do we unconditionally trust the reviewers
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  carefully_trust_reviewers_barcodes?: boolean;
  /**
   * If enabled, the UD connects with the data backend. If disabled, the UD connects to the simulator.
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  connect_to_data_backend?: boolean;
  /**
   * If enabled, the UD connects with the navigation backend. If disabled, the UD connects to the simulator.
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  connect_to_navigation_backend?: boolean;
  /**
   * disclaimer text shown when asking the dual check on system unlock
   * @type {string}
   * @memberof IFacilitySettingsST
   */
  dual_check_on_unlock_disclaimer?: string | null;
  /**
   * If enabled, the drone zones can be managed using the WMS integration in addition to manually.
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  enable_coexistence_wms_integration?: boolean;
  /**
   * If enabled, UD will stop to communicate task results/requests with control station and will use cloud services
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  enable_csfa_task_request_result?: boolean;
  /**
   * If enabled, UD will not request diagnostic view images to Cloud Insights Generation
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  enable_diagnostic_view_logic?: boolean;
  /**
   * If enabled, an additional check will be asked when unlocking the system
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  enable_dual_check_on_unlock?: boolean;
  /**
   * If enabled, Experimental Features are enabled for all the users of the facility
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  enable_experimental_features?: boolean;
  /**
   * MFA settings for all the users of the facility
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  enable_mfa?: boolean;
  /**
   * Enable SuperSet integration for analytics.
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  enable_superset_integration?: boolean;
  /**
   * Enabled the warehouse status reset
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  enable_warehouse_status_reset?: boolean;
  /**
   * If not enough results are available to submit as a batch to review
   * @type {number}
   * @memberof IFacilitySettingsST
   */
  external_review_batch_max_wait_time_s?: number;
  /**
   * How many locations to submit together as a single review
   * @type {number}
   * @memberof IFacilitySettingsST
   */
  external_review_batch_size_desired?: number;
  /**
   * Specifies whether to create new reviews
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  external_review_enabled?: boolean;
  /**
   * Specifies the number of assignments to create for each review
   * @type {number}
   * @memberof IFacilitySettingsST
   */
  external_review_num_assignments_per_review?: number;
  /**
   * Specifies whether to incorporate the reviewer amendments in the Verity data
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  external_review_overrides_verity_data?: boolean;
  /**
   * Specifies if Verity\'s interpretation of the images are shown to the reviewers
   * @type {string}
   * @memberof IFacilitySettingsST
   */
  external_review_show_prior?: IFacilitySettingsSTExternalReviewShowPriorEnum;
  /**
   * The fraction of verity status for locations without issues that will require review
   * @type {number}
   * @memberof IFacilitySettingsST
   */
  fraction_of_correct_results_to_be_reviewed?: number;
  /**
   * Names of handlers that modify the issue logics
   * @type {Array<string>}
   * @memberof IFacilitySettingsST
   */
  issue_logic_filter_names?: Array<string>;
  /**
   *
   * @type {IIssueLogicRules1ST}
   * @memberof IFacilitySettingsST
   */
  issue_logic_rules?: IIssueLogicRules1ST;
  /**
   * The HIT Layout Id allows one to use a pre-existing HIT design with placeholder
   * @type {string}
   * @memberof IFacilitySettingsST
   */
  mturk_hit_layout_id?: string;
  /**
   * An amount of time, in seconds, after which the Amazon MTurk HIT is no longer available
   * @type {number}
   * @memberof IFacilitySettingsST
   */
  mturk_hit_lifetime_in_seconds?: number;
  /**
   * Creating an Amazon MTurk HIT with a HIT Type
   * @type {string}
   * @memberof IFacilitySettingsST
   */
  mturk_hit_type_id?: string;
  /**
   *
   * @type {IReportDeadlinesST}
   * @memberof IFacilitySettingsST
   */
  report_deadlines?: IReportDeadlinesST;
  /**
   * Show customer information
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  show_customer?: boolean;
  /**
   * Show Ground Control application to users who have access to the ground control application
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  show_ground_control_app?: boolean;
  /**
   * Show Coexistence map page and cards to users who have access to the ground control application
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  show_coexistence_map?: boolean;
  /**
   * Show potential issues
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  show_potential_issues?: boolean;
  /**
   * Show report deadlines UI
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  show_report_deadlines?: boolean;
  /**
   * show the scheduled lock UI
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  show_scheduled_lock?: boolean;
  /**
   * Comma separated list of support email addresses
   * @type {string}
   * @memberof IFacilitySettingsST
   */
  support_email_recipients?: string;
  /**
   * Trim leading digits from the barcode value
   * @type {boolean}
   * @memberof IFacilitySettingsST
   */
  trim_leading_digits?: boolean;
  /**
   * The name of the API handler used to import client WMS data
   * @type {string}
   * @memberof IFacilitySettingsST
   */
  wms_client_api_handler_name?: string | null;
  /**
   * The comma separated list of emails that are white-listed by the WMS email filter
   * @type {string}
   * @memberof IFacilitySettingsST
   */
  wms_email_senders?: string;
  /**
   * The regex used to parse the name of the WMS moved location files
   * @type {string}
   * @memberof IFacilitySettingsST
   */
  wms_file_name_regex_moved_locations?: string | null;
  /**
   * The regex used to parse the name of the WMS snapshots files
   * @type {string}
   * @memberof IFacilitySettingsST
   */
  wms_file_name_regex_snapshot?: string | null;
  /**
   * The regex used to parse the file name of the WMS stock check files
   * @type {string}
   * @memberof IFacilitySettingsST
   */
  wms_file_name_regex_stock_check?: string | null;
  /**
   * The maximum number of rows in a WMS excerpt that may reference the same location.
   * @type {number}
   * @memberof IFacilitySettingsST
   */
  wms_max_num_entries_per_location?: number | null;
  /**
   * The name of the parser used to import WMS data
   * @type {string}
   * @memberof IFacilitySettingsST
   */
  wms_parser_name?: string | null;
  /**
   * The regex used to validate slot labels when importing WMS data
   * @type {string}
   * @memberof IFacilitySettingsST
   */
  wms_slot_label_regex?: string;
}

export const IFacilitySettingsSTExternalReviewShowPriorEnum = {
  Always: 'ALWAYS',
  NotForControl: 'NOT_FOR_CONTROL',
  Never: 'NEVER',
  Null: 'null',
} as const;

export type IFacilitySettingsSTExternalReviewShowPriorEnum =
  typeof IFacilitySettingsSTExternalReviewShowPriorEnum[keyof typeof IFacilitySettingsSTExternalReviewShowPriorEnum];

/**
 *
 * @export
 * @interface IIssueLogicRules1ST
 */
export interface IIssueLogicRules1ST {
  /**
   *
   * @type {Array<IRulesST>}
   * @memberof IIssueLogicRules1ST
   */
  rules?: Array<IRulesST>;
}
/**
 *
 * @export
 * @interface IIssueLogicRulesST
 */
export interface IIssueLogicRulesST {
  /**
   *
   * @type {Array<IRulesST>}
   * @memberof IIssueLogicRulesST
   */
  rules?: Array<IRulesST>;
}
/**
 *
 * @export
 * @interface IReportDeadlinesST
 */
export interface IReportDeadlinesST {
  /**
   *
   * @type {string}
   * @memberof IReportDeadlinesST
   */
  mon: string | null;
  /**
   *
   * @type {string}
   * @memberof IReportDeadlinesST
   */
  tue: string | null;
  /**
   *
   * @type {string}
   * @memberof IReportDeadlinesST
   */
  wed: string | null;
  /**
   *
   * @type {string}
   * @memberof IReportDeadlinesST
   */
  thu: string | null;
  /**
   *
   * @type {string}
   * @memberof IReportDeadlinesST
   */
  fri: string | null;
  /**
   *
   * @type {string}
   * @memberof IReportDeadlinesST
   */
  sat: string | null;
  /**
   *
   * @type {string}
   * @memberof IReportDeadlinesST
   */
  sun: string | null;
}
/**
 *
 * @export
 * @interface IRulesActionST
 */
export interface IRulesActionST {
  /**
   *
   * @type {string}
   * @memberof IRulesActionST
   */
  issue_logic?: IRulesActionSTIssueLogicEnum;
}

export const IRulesActionSTIssueLogicEnum = {
  Default: 'DEFAULT',
  DoubleDepth21: 'DOUBLE_DEPTH_21',
  DoubleDepth22: 'DOUBLE_DEPTH_22',
  DoubleDepthMapBased: 'DOUBLE_DEPTH_MAP_BASED',
  NaBarcode: 'NA_BARCODE',
  PictureOnly: 'PICTURE_ONLY',
} as const;

export type IRulesActionSTIssueLogicEnum =
  typeof IRulesActionSTIssueLogicEnum[keyof typeof IRulesActionSTIssueLogicEnum];

/**
 *
 * @export
 * @interface IRulesConditionsInnerOperatorST
 */
export interface IRulesConditionsInnerOperatorST {
  /**
   *
   * @type {string}
   * @memberof IRulesConditionsInnerOperatorST
   */
  type: IRulesConditionsInnerOperatorSTTypeEnum;
  /**
   *
   * @type {IRulesConditionsInnerOperatorValueST}
   * @memberof IRulesConditionsInnerOperatorST
   */
  value?: IRulesConditionsInnerOperatorValueST;
}

export const IRulesConditionsInnerOperatorSTTypeEnum = {
  Contains: 'CONTAINS',
  Equals: 'EQUALS',
  StartsWith: 'STARTS_WITH',
  EndsWith: 'ENDS_WITH',
  AnyOf: 'ANY_OF',
  NotIn: 'NOT_IN',
  IsBarcode: 'IS_BARCODE',
  IsEmpty: 'IS_EMPTY',
  IsInvalid: 'IS_INVALID',
  Match: 'MATCH',
  IsDoubleDepth: 'IS_DOUBLE_DEPTH',
} as const;

export type IRulesConditionsInnerOperatorSTTypeEnum =
  typeof IRulesConditionsInnerOperatorSTTypeEnum[keyof typeof IRulesConditionsInnerOperatorSTTypeEnum];

/**
 * @type IRulesConditionsInnerOperatorValueST
 * @export
 */
export type IRulesConditionsInnerOperatorValueST = Array<string> | string;

/**
 *
 * @export
 * @interface IRulesConditionsInnerST
 */
export interface IRulesConditionsInnerST {
  /**
   *
   * @type {string}
   * @memberof IRulesConditionsInnerST
   */
  field?: IRulesConditionsInnerSTFieldEnum;
  /**
   *
   * @type {IRulesConditionsInnerOperatorST}
   * @memberof IRulesConditionsInnerST
   */
  operator?: IRulesConditionsInnerOperatorST;
  /**
   *
   * @type {string}
   * @memberof IRulesConditionsInnerST
   */
  quantifier?: IRulesConditionsInnerSTQuantifierEnum;
}

export const IRulesConditionsInnerSTFieldEnum = {
  LocationName: 'LOCATION_NAME',
  ExpectedState: 'EXPECTED_STATE',
  ExpectedContent: 'EXPECTED_CONTENT',
  Customers: 'CUSTOMERS',
  Location: 'LOCATION',
} as const;

export type IRulesConditionsInnerSTFieldEnum =
  typeof IRulesConditionsInnerSTFieldEnum[keyof typeof IRulesConditionsInnerSTFieldEnum];
export const IRulesConditionsInnerSTQuantifierEnum = {
  All: 'ALL',
  Any: 'ANY',
  None: 'NONE',
} as const;

export type IRulesConditionsInnerSTQuantifierEnum =
  typeof IRulesConditionsInnerSTQuantifierEnum[keyof typeof IRulesConditionsInnerSTQuantifierEnum];

/**
 *
 * @export
 * @interface IRulesST
 */
export interface IRulesST {
  /**
   *
   * @type {Array<IRulesConditionsInnerST>}
   * @memberof IRulesST
   */
  conditions?: Array<IRulesConditionsInnerST>;
  /**
   *
   * @type {IRulesActionST}
   * @memberof IRulesST
   */
  action?: IRulesActionST;
}

/**
 * FacilitySettingsApi - axios parameter creator
 * @export
 */
export const FacilitySettingsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Return settings for Facility.
     * @summary Return settings for Facility.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFacilitySettings: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFacilitySettings', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/facility/settings`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update settings for Facility.
     * @summary Update settings for Facility.
     * @param {string} systemIdStr
     * @param {IFacilitySettingsST} iFacilitySettingsST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setFacilitySettings: async (
      systemIdStr: string,
      iFacilitySettingsST: IFacilitySettingsST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('setFacilitySettings', 'systemIdStr', systemIdStr);
      // verify required parameter 'iFacilitySettingsST' is not null or undefined
      assertParamExists('setFacilitySettings', 'iFacilitySettingsST', iFacilitySettingsST);
      const localVarPath = `/{system_id_str}/facility/settings`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iFacilitySettingsST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FacilitySettingsApi - functional programming interface
 * @export
 */
export const FacilitySettingsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FacilitySettingsApiAxiosParamCreator(configuration);
  return {
    /**
     * Return settings for Facility.
     * @summary Return settings for Facility.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFacilitySettings(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFacilitySettingsGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFacilitySettings(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update settings for Facility.
     * @summary Update settings for Facility.
     * @param {string} systemIdStr
     * @param {IFacilitySettingsST} iFacilitySettingsST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setFacilitySettings(
      systemIdStr: string,
      iFacilitySettingsST: IFacilitySettingsST,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFacilitySettingsPostResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setFacilitySettings(
        systemIdStr,
        iFacilitySettingsST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FacilitySettingsApi - factory interface
 * @export
 */
export const FacilitySettingsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FacilitySettingsApiFp(configuration);
  return {
    /**
     * Return settings for Facility.
     * @summary Return settings for Facility.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFacilitySettings(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<IFacilitySettingsGetResponseST> {
      return localVarFp
        .getFacilitySettings(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update settings for Facility.
     * @summary Update settings for Facility.
     * @param {string} systemIdStr
     * @param {IFacilitySettingsST} iFacilitySettingsST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setFacilitySettings(
      systemIdStr: string,
      iFacilitySettingsST: IFacilitySettingsST,
      options?: any,
    ): AxiosPromise<IFacilitySettingsPostResponseST> {
      return localVarFp
        .setFacilitySettings(systemIdStr, iFacilitySettingsST, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * FacilitySettingsApi - object-oriented interface
 * @export
 * @class FacilitySettingsApi
 * @extends {BaseAPI}
 */
export class FacilitySettingsApi extends BaseAPI {
  /**
   * Return settings for Facility.
   * @summary Return settings for Facility.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FacilitySettingsApi
   */
  public getFacilitySettings(systemIdStr: string, options?: AxiosRequestConfig) {
    return FacilitySettingsApiFp(this.configuration)
      .getFacilitySettings(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update settings for Facility.
   * @summary Update settings for Facility.
   * @param {string} systemIdStr
   * @param {IFacilitySettingsST} iFacilitySettingsST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FacilitySettingsApi
   */
  public setFacilitySettings(
    systemIdStr: string,
    iFacilitySettingsST: IFacilitySettingsST,
    options?: AxiosRequestConfig,
  ) {
    return FacilitySettingsApiFp(this.configuration)
      .setFacilitySettings(systemIdStr, iFacilitySettingsST, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
