import { TextField, InputAdornment, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { useRef, useState, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { setFilterOnColumns } from './setFilterOnColumns';

export const SearchField = ({
  gridApiRef,
  columns,
  searchTerm,
  onUpdateSearchTerm,
}: {
  searchTerm: string;
  onUpdateSearchTerm: (newTerm: string) => void;
  gridApiRef: React.MutableRefObject<GridApiPremium>;
  columns: string[];
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState(searchTerm);

  const updateTerm = useMemo(
    () =>
      debounce((term: string) => {
        onUpdateSearchTerm(term);
        setFilterOnColumns(gridApiRef, term, columns);
      }, 300),
    [columns, gridApiRef, onUpdateSearchTerm],
  );

  return (
    <TextField
      sx={{ marginTop: '-5px' }}
      variant="outlined"
      size="small"
      color="primary"
      aria-label="filter"
      data-testid="c-search-filter"
      onChange={(event) => {
        const term = event.target.value;
        setValue(term);
        updateTerm(term);
      }}
      value={value}
      inputRef={inputRef}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              data-testid="c-clear-search-filter"
              aria-label="clear filter"
              onClick={() => {
                onUpdateSearchTerm('');
                setFilterOnColumns(gridApiRef, '', columns);
              }}
              size="small"
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
