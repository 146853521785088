import { Route, Routes, Navigate, useLocation } from 'react-router-dom';

// auth
import { WARNING_PAGES_URLS } from 'common/pages';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import PublicRoute from 'components/common/PublicRoute';
import { LoginCallback } from 'pages/Auth/sso-page/LoginCallback';
import SignIn from '../pages/Auth/SignIn';
import SignOut from '../pages/Auth/SignOut';
import { ForgotPassword } from '../pages/Auth/ForgotPassword';
import NewPassword from '../pages/Auth/NewPassword';
import MultiFactorAuth from '../pages/Auth/MultiFactorAuthentication';

// Custom routes components

/**
 * Functional component implementing a page under an Authenticated Route
 * @returns the functional component
 */
function AuthRoutes() {
  const location = useLocation();
  const logLocationPrefix = getLogPrefixForType('PAGE', location.pathname);
  const logPrefix = getLogPrefixForType('COMPONENT', 'AuthRoutes', logLocationPrefix);
  console.debug(logPrefix, 'render route');
  return (
    <Routes>
      <Route
        path="login-callback"
        element={
          <PublicRoute restricted={false}>
            <LoginCallback />
          </PublicRoute>
        }
      />
      <Route
        path="signin"
        element={
          <PublicRoute restricted={false}>
            <SignIn />
          </PublicRoute>
        }
      />
      <Route
        path="signout"
        element={
          <PublicRoute restricted={false}>
            <SignOut />
          </PublicRoute>
        }
      />
      <Route
        path="mfa-authentication"
        element={
          <PublicRoute restricted={false}>
            <MultiFactorAuth />
          </PublicRoute>
        }
      />
      <Route
        path="forgot-password"
        element={
          <PublicRoute restricted={false}>
            <ForgotPassword />
          </PublicRoute>
        }
      />

      <Route path="new-password" element={<NewPassword requiresCode={true} />} />
      <Route path="change-temporary-password" element={<NewPassword requiresCode={false} />} />

      <Route path="*" element={<Navigate to={WARNING_PAGES_URLS.NOT_FOUND} />} />
    </Routes>
  );
}

export default AuthRoutes;
