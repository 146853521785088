import { DISPLAY_ISSUE_TYPES, DISPLAY_ISSUE_TYPES_KEYS } from 'common/issueTypesAndStates';

/**
 * Get the title of the location modal for the given location
 * @param locationData Location Data
 * @returns title string
 */

export const getLocationModalTitle = (issueType: DISPLAY_ISSUE_TYPES_KEYS, location: string) => {
  const type = DISPLAY_ISSUE_TYPES[issueType] ? DISPLAY_ISSUE_TYPES[issueType] : '';
  return `${type} at ${location}`;
};
