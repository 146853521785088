import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { pickSort } from 'common/functions/otherFunctions';
import { NoFlyZone, NoFlyZones } from 'shared/map/model/no-fly-zone.model';
import { Box } from 'components/common/Box';
import { useGroundControlStore } from '../../../../store/GroundControl/groundControlLevelStore';
import { useNoFlyZoneContext } from '../reducer/NoFlyZonePageContext';
import { noFlyZoneNameToSelectedId } from '../utils/noFlyZoneNameToSelectedId';
import { BackDropLoader } from '../../../../pages/GroundControl/pages/Schedule/ScheduleLock/BackDropLoader';
import { NoFlyZoneItem } from './NoFlyZoneItem';
import { noFlyZoneListStyle } from '../style/noFlyZoneList.style';

export const NoFlyZonesList = ({
  noFlyZones,
  onSelectNoFlyZone,
  systemId,
  flightDomainId,
  isLoading,
}: {
  isLoading: boolean;
  noFlyZones: NoFlyZones;
  onSelectNoFlyZone: (noFlyZone: NoFlyZone) => void;
  systemId: string;
  flightDomainId: string;
}) => {
  const { classes } = noFlyZoneListStyle();
  const { stateGroundControl } = useGroundControlStore();
  const { dispatchNoFlyZone } = useNoFlyZoneContext();

  const focusedNoFlyZone = Object.values(noFlyZones).find((nfz: NoFlyZone) => nfz.isSelected);
  const focusedNoFlyZoneElement = focusedNoFlyZone
    ? document.getElementById(noFlyZoneNameToSelectedId(focusedNoFlyZone.name))
    : undefined;

  const handleCloneNoFlyZone = (noFlyZone: NoFlyZone) =>
    dispatchNoFlyZone({
      type: 'SHOW_NO_FLY_ZONE_CREATION_FORM',
      payload: noFlyZone,
    });

  const handleEditNoFlyZone = (noFlyZone: NoFlyZone) =>
    dispatchNoFlyZone({
      type: 'START_EDITING_NO_FLY_ZONE',
      payload: noFlyZone,
    });
  const handleDeleteNoFlyZone = (noFlyZone: NoFlyZone) =>
    dispatchNoFlyZone({ type: 'SHOW_NO_FLY_ZONE_DELETION_MODAL', payload: noFlyZone });

  useEffect(() => {
    focusedNoFlyZoneElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [focusedNoFlyZone, focusedNoFlyZoneElement]);

  const noFlyZonesArray = pickSort(
    Object.values(noFlyZones).map((i) => i),
    'asc',
    (o) => o.name.toLowerCase(),
  );

  const isBackdropVisible =
    isLoading ||
    !stateGroundControl.flightDomain.flight_domain_status?.locked ||
    stateGroundControl.isExecutingReturnHome ||
    stateGroundControl.dronesFlying;
  const noDataVisibility = isLoading ? 'hidden' : 'visible';
  return (
    <Box className={classes.wrapper}>
      <BackDropLoader isVisible={isBackdropVisible} showLoader={isLoading} />

      {noFlyZonesArray.length ? (
        <Box className={classes.nfzsWrapper}>
          {noFlyZonesArray.map((nfz) => (
            <NoFlyZoneItem
              noFlyZone={nfz}
              systemId={systemId}
              flightDomainId={flightDomainId}
              onClone={handleCloneNoFlyZone}
              onDelete={handleDeleteNoFlyZone}
              onEdit={handleEditNoFlyZone}
              onSelect={onSelectNoFlyZone}
              isDisabled={isBackdropVisible}
              key={nfz.id}
            />
          ))}
        </Box>
      ) : (
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          visibility={noDataVisibility}
        >
          <Typography color="textSecondary">There are no "no-fly zones"</Typography>
        </Box>
      )}
    </Box>
  );
};
