import { Theme } from '@mui/material';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import DroneIcon from 'components/common/FacilityMenu/Icons/DroneIcon';

import TooltipedIcon from 'components/common/TooltipedIcon';
import { IFleetStatusSummaryST } from 'codegen/flight_domain';
import { DroneOnChargerIcon } from '../../Icons/DroneOnChargerIcon';
import { TooltipTypes } from '../../../../store/GroundControl/IGroundControlStore';
import {
  numberOfDronesFlying,
  numberOfDronesLandedInPlace,
  numberOfDronesOnCharger,
} from './FleetStatus.utils';

interface FleetStatusProps {
  theme: Theme;
  webSocketError: boolean;
  fleet_status_summary: IFleetStatusSummaryST | null | undefined;
}

export const FleetStatus = ({ theme, webSocketError, fleet_status_summary }: FleetStatusProps) => {
  const hasNoData = !fleet_status_summary || webSocketError;
  const flying = hasNoData ? '-' : numberOfDronesFlying(fleet_status_summary);
  const onCharger = hasNoData ? '-' : numberOfDronesOnCharger(fleet_status_summary);
  const landedInPlace = hasNoData ? '-' : numberOfDronesLandedInPlace(fleet_status_summary);

  return (
    <Grid justifyContent="space-between" alignItems="center" container>
      <Grid display="flex" alignItems="center" item>
        <TooltipedIcon
          noMargin
          tooltip={TooltipTypes.FLYING}
          icon={<DroneIcon color={theme.palette.primary.light} />}
        />
        <Typography data-testid="c-n-drones-flying" pl={2} variant="body2" color="white">
          {flying}
        </Typography>
      </Grid>
      <Grid display="flex" alignItems="center" item>
        <TooltipedIcon
          noMargin
          tooltip={TooltipTypes.ON_CHARGERS}
          icon={
            <DroneOnChargerIcon
              isActive={true}
              colorActive={theme.palette.primary.light}
              colorInactive={theme.palette.grey[600]}
            />
          }
        />
        <Typography data-testid="c-n-drones-on-charger" pl={2} variant="body2" color="white">
          {onCharger}
        </Typography>
      </Grid>
      <Grid display="flex" alignItems="center" item>
        <TooltipedIcon
          noMargin
          tooltip={TooltipTypes.ERRORS}
          icon={<WarningAmberIcon sx={{ color: '#fff' }} />}
        />
        <Typography data-testid="c-n-drones-displaced" pl={2} variant="body2" color="white">
          {landedInPlace}
        </Typography>
      </Grid>
    </Grid>
  );
};
