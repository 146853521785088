import { Chip, Tooltip } from '@mui/material';
import {
  issueTypeEnumToStringMap,
  issueTypeEnumToColor,
  MuiColor,
  AllIssueTypes,
} from '../utils/WHSFunctions';

export const getIssueType = (issue: string): { label: string; color: MuiColor } => {
  let label = 'Unknown';
  let color: MuiColor = 'info';
  if (Object.keys(issueTypeEnumToStringMap).includes(issue)) {
    const key = issue as AllIssueTypes;
    label = issueTypeEnumToStringMap[key];
    color = issueTypeEnumToColor[key];
  }
  return { label, color };
};

const getIssueTypeColor = (issueType: string): MuiColor => {
  switch (issueType) {
    case 'Empty location':
    case 'Barcode mismatch':
    case 'Non-empty (barcode)':
    case 'Non-empty (no barcode)':
    case 'Duplicate barcodes':
      return 'error';
    case 'No barcode':
    case 'No information':
    case 'Invalid WMS data':
    case 'Invalid Verity data':
      return 'warning';
    case 'Double depth location':
      return 'secondary';
    default:
      return 'default';
  }
};

type IssueCellProps = {
  /**
   * Type of the issue
   */
  issueType: string;
};

/**
 * Component rendering the content of a Data Grid Cell
 * containing the type of an issue
 * @param props
 * @returns JSX rendering of the component
 */
export const IssueCell = (props: IssueCellProps) => {
  if (props.issueType === '-' || props.issueType === 'No Issue') {
    return (
      <Tooltip className="c-data-grid-cell" title="No Issue" sx={{ cursor: 'default' }}>
        <Chip size="small" variant="outlined" color="info" label="No Issue" />
      </Tooltip>
    );
  }

  return (
    <Tooltip title={props.issueType} className="c-data-grid-cell">
      <Chip size="small" color={getIssueTypeColor(props.issueType)} label={props.issueType} />
    </Tooltip>
  );
};
