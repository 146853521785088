import { Tooltip, IconButton, Typography, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  toolbarButton: {
    color: theme.palette.primary.main,
  },
  toolbarName: {
    paddingLeft: '.5em',
    fontSize: '13px',
    fontWeight: 500,
  },
}));

export const GridPremiumToolbarButton = ({
  name,
  tooltip,
  onClick,
  icon,
}: {
  name: string;
  tooltip: string;
  onClick: () => void;
  icon: JSX.Element;
}) => {
  const { classes } = useStyles();

  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={onClick} className={classes.toolbarButton}>
        {icon}
        <Typography className={classes.toolbarName}>{name}</Typography>
      </IconButton>
    </Tooltip>
  );
};
