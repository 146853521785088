import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { initialGridState } from '../models/InitialGridState.model';

export const onLoadCustomGrid = ({
  gridApiRef,
}: {
  gridApiRef: React.MutableRefObject<GridApiPremium>;
}) => {
  const gridStateString = localStorage.getItem('savedGridState');

  if (gridStateString) {
    const gridState = JSON.parse(gridStateString);

    if (Object.keys(gridState).length > 0) {
      gridApiRef.current.restoreState({ ...initialGridState, ...gridState });
    }
  }
};
