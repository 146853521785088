import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useSsoStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 150px)',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(6),
  },
  paper: {
    padding: theme.spacing(3),
    maxWidth: 500,
    minHeight: 400,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  toolbar: {
    width: '100%',
    justifyContent: 'flex-end',
  },
  appBar: {
    backgroundColor: theme.palette.accent.main,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  button: {
    color: '#fff',
  },
}));
