import { Grid } from '@mui/material';
import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { WarehouseOccupancyCard } from './WarehouseOccupancyCard';
import { WarehouseStatusCard } from './WarehouseStatusCard';

export const WarehouseStatusOverview = ({ systemId }: { systemId: string }) => (
  <Grid alignItems="stretch" spacing={3} container className="c-page-content">
    {userHasPermission(PERMISSION.VIEW_WAREHOUSE_STATUS) && (
      <Grid md={6} sm={12} xs={12} item>
        <WarehouseStatusCard systemId={systemId} />
      </Grid>
    )}
    <Grid md={6} sm={12} xs={12} item>
      <WarehouseOccupancyCard systemId={systemId} />
    </Grid>
  </Grid>
);
