/* eslint-disable no-prototype-builtins */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Card, Container } from '@mui/material';
import { DataGridPremium, GridInitialState, useGridApiRef } from '@mui/x-data-grid-premium';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { SHOW_PAGE_HEADER_MENU_ICON } from 'common/settings';
import PageHeaderSection from 'components/Page/PageHeaderSection';
import { WarehouseStatusOverview } from 'pages/WarehouseStatus/warehouse-status-overview/WarehouseStatusOverview';
import { ISSUE_ACTIONS } from 'common/Actions/actionTypes';
import { ILocationDataST } from 'interfaces';
import { useRequestController } from '../../hooks';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { useFacilityModalsStore } from '../../store/Modals';
import { columns } from './models/WHSColumns';
import { WHSStore } from './WHStore';
import { WHSToolbar } from './toolbar/WHSToolbar';
import { getGridRowHeight } from './utils/getGridRowHeight.util';
import { WarehouseStatusXTabs } from './WarehouseStatusXTabs';
import { WAREHOUSE_STATUS_TABS } from './models/WarehouseStatusXTabs.model';
import SnoozeMultipleIssuesModal from './snooze-issues/SnoozeMultipleIssuesModal';
import { handleRowClickEvent } from './utils/handleRowClickEventutil';
import { handleGridStateChange } from './utils/handleGridStateChange.util';
import { onSaveCustomGrid } from './utils/onSaveCustomGrid.util';
import { onLoadCustomGrid } from './utils/onLoadCustomGrid.util';

const logPrefix = getLogPrefixForType('PAGE', 'WHS Page');
const noRows = [] as never[];

const useRerender = () => {
  const [, setState] = useState(Date.now());
  return () => setState(Date.now());
};

const styles = {
  width: '100%',
  display: 'flex',
  '& .MuiDataGrid-columnHeaders': { backgroundColor: 'lightgray' },
  '& .MuiDataGrid-columnHeader': { backgroundColor: 'lightgray' },
  '& .MuiDataGrid-columnHeader input': { fontSize: 'small' },
  '& .row-even': { backgroundColor: 'rgb(250, 250, 250)' },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },
  '& .MuiDataGrid-row:hover': {
    cursor: 'pointer',
  },
};

const STARTING_TAB = 1;

/**
 * Warehouse status experimental page
 * @returns component
 */
export const WarehouseStatusX = ({
  disableVirtualization,
}: {
  disableVirtualization?: boolean;
}) => {
  const { stateFacilityLevel } = useFacilityLevelStore();
  const { dispatchFacilityModals } = useFacilityModalsStore();

  const { slots } = stateFacilityLevel;
  const rerender = useRerender();
  const { requestController } = useRequestController('WarehouseStatusX');
  const { systemId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<number>(
    Number(searchParams.get('activeTab') ?? STARTING_TAB),
  );
  const gridApiRef = useGridApiRef();

  const [checkboxSelection, setCheckboxSelection] = useState(false);
  const [isSnoozeModalOpen, setIsSnoozeModalOpen] = useState(false);
  const [snoozeAction, setSnoozeAction] = useState(ISSUE_ACTIONS.SNOOZE);

  const dataGridStateFromURL = searchParams.get('dataGridState');
  const [gridViewFromURL] = useState<GridInitialState>(
    dataGridStateFromURL ? JSON.parse(dataGridStateFromURL) : undefined,
  );

  const [searchTerm, setSearchTerm] = useState<string>('');

  const store = useMemo(() => {
    console.debug(logPrefix, 'running WHS store memo -');
    if (gridApiRef.current.hasOwnProperty('updateRows')) {
      return WHSStore(systemId, gridApiRef.current, requestController, slots);
    }
    console.debug(logPrefix, '- Grid API is not available, store not created');
    return null;
  }, [gridApiRef.current]);

  const loadLocationsData = () => {
    if (!store) {
      rerender();
    } else {
      store.loadLocationData();
    }
  };

  useEffect(() => {
    console.debug(logPrefix, `useEffect run start ${store ? 'with' : 'without'} store`);
    loadLocationsData();
  }, [store]);

  const rowCount = gridApiRef.current?.getRowsCount && gridApiRef.current.getRowsCount();

  console.debug(
    logPrefix,
    `rendering ${rowCount} rows in columns`,
    columns,
    'on api',
    gridApiRef.current,
  );

  const loadingString =
    store && !store.isEverythingLoaded()
      ? `- Loading... (${rowCount} of ${slots.length} locations)`
      : '';

  const selectedRows = () =>
    Array.from(gridApiRef.current.getSelectedRows(), ([id, value]) => value) as ILocationDataST[];

  const tableToolbar = () => (
    <WHSToolbar
      onSaveCustomGrid={() => onSaveCustomGrid({ gridApiRef })}
      onLoadCustomGrid={() => onLoadCustomGrid({ gridApiRef })}
      checkboxSelection={checkboxSelection}
      setCheckboxSelection={setCheckboxSelection}
      onSnooze={() => {
        setSnoozeAction(ISSUE_ACTIONS.SNOOZE);
        setIsSnoozeModalOpen(true);
      }}
      onUnSnooze={() => {
        setSnoozeAction(ISSUE_ACTIONS.UNSNOOZE);
        setIsSnoozeModalOpen(true);
      }}
      gridApiRef={gridApiRef}
      searchTerm={searchTerm}
      onUpdateSearchTerm={setSearchTerm}
    />
  );

  const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
    setActiveTab(newValue);
    const { gridState } = WAREHOUSE_STATUS_TABS[newValue];
    gridApiRef.current.restoreState({ ...gridState });

    searchParams.set('activeTab', newValue.toString());
    setSearchParams(searchParams);
  };

  return (
    <>
      <PageHeaderSection
        title="BETA Warehouse status"
        subtitle={`The latest information about your warehouse ${loadingString}`}
        showMenuIcon={SHOW_PAGE_HEADER_MENU_ICON.WAREHOUSE_STATUS}
      />
      <Container maxWidth="xl">
        <WarehouseStatusOverview systemId={systemId} />
        <Card
          sx={{
            mt: 2,
          }}
        >
          <WarehouseStatusXTabs
            tabs={WAREHOUSE_STATUS_TABS}
            activeTab={activeTab}
            handleTabChange={handleTabChange}
          />
        </Card>

        <Card
          sx={{
            height: 'calc(100vh - 22em)',
            display: 'flex',
          }}
        >
          <DataGridPremium
            sx={styles}
            columns={columns}
            apiRef={gridApiRef}
            rows={noRows}
            getRowId={(row) => row.slot_label}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'row-even' : 'row-odd'
            }
            getRowHeight={getGridRowHeight}
            columnHeaderHeight={45}
            slotProps={{ toolbar: { excelOptions: { disableToolbarButton: true } } }}
            slots={{
              toolbar: tableToolbar,
            }}
            loading={store?.isLoading()}
            onRowClick={(params) =>
              handleRowClickEvent({
                params,
                gridApiRef,
                dispatchFacilityModals,
                loadLocationsData,
              })
            }
            localeText={{
              // TODO:: remove this ts ignore after updating mui, right now the unstable header filters crash when
              //  using custom filters, as datagrid does not have the translation keys for the custom filters
              // @ts-ignore
              headerFilterOperatorNotContains: 'not contain',
              headerFilterOperatorIsNotAnyOf: 'is not any of',
            }}
            checkboxSelection={checkboxSelection}
            rowGroupingColumnMode="multiple"
            disableRowSelectionOnClick
            disableAggregation
            showColumnVerticalBorder
            headerFilters
            onStateChange={() =>
              handleGridStateChange({ gridApiRef, searchParams, setSearchParams })
            }
            initialState={
              gridViewFromURL
                ? { ...gridViewFromURL }
                : {
                    ...WAREHOUSE_STATUS_TABS[activeTab].gridState,
                  }
            }
            disableVirtualization={disableVirtualization}
          />
        </Card>
      </Container>
      {isSnoozeModalOpen && (
        <SnoozeMultipleIssuesModal
          locations={selectedRows()}
          action={snoozeAction}
          onClose={() => setIsSnoozeModalOpen(false)}
          refreshData={loadLocationsData}
        />
      )}
    </>
  );
};
