import LockIcon from '@mui/icons-material/Lock';
import { Box } from '@mui/material';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import TooltipedIcon from 'components/common/TooltipedIcon';
import { NoAccessIcon } from '../Icons/NoAccessIcon';

type GroundControlSideIconProps = {
  isLocked: boolean;
  isInCoexistence: boolean;
  isDroneOnly: boolean;
};

export const GroundControlSideIcon = ({
  isLocked,
  isInCoexistence,
  isDroneOnly,
}: GroundControlSideIconProps) => {
  if (isLocked) {
    return (
      <LockIcon
        fontSize="small"
        sx={{
          color: 'white',
        }}
      />
    );
  }

  if (!isInCoexistence || isDroneOnly) {
    return (
      <Box component="div" paddingLeft="4px" data-testid="c-icon-drone-only">
        <TooltipedIcon
          icon={<NoAccessIcon color="white" fontSize="30px" />}
          tooltip="Do not enter"
        />
      </Box>
    );
  }

  return (
    <Box component="div" paddingLeft="3px" data-testid="c-icon-coexistence">
      <TooltipedIcon
        icon={<DirectionsWalkIcon sx={{ color: 'white', fontSize: '26px' }} />}
        tooltip="Drones are mixed with people"
      />
    </Box>
  );
};
