import { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import {
  validRangeGtZero,
  validIfGtZero,
  validIfInRangeInclusive,
} from 'common/functions/otherFunctions';
import { LocalStore } from 'common/functions/storageFunctions';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import SettingsCard from './SettingsCard';
import WarehouseServices from '../../../services/WarehouseServices';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import { FacilityActionNames } from '../../../store/FacilityLevelStore/facilityLevelActions';
import { useSettingsStore } from '../../../store/Settings/settingsStore';
import { useRequestController } from '../../../hooks';
import { getAuthenticationOptions } from './options/getAuthenticationOptions';
import { getBackendOptions } from './options/getBackendOptions';
import { dualCheckOnUnlockOptions } from './options/dualCheckOnUnlockOptions';
import { getUiOptions } from './options/getUiOptions';
import { SettingsPageHeader } from './SettingsPageHeader';
import { ReportDeadlines } from './sections/report-deadlines/ReportDeadlines';
import { WmsIntegration } from './sections/wms-integration/WmsIntegration';
import { ShareFeedback } from './sections/share-feedback/ShareFeedback';
import { BarcodeTypesSection } from './sections/barcode-types/BarcodeTypesSection';
import { LoadSettinsFromFile } from './sections/load-settings-from-file/LoadSettinsFromFile';
import { BarcodeValidation } from './sections/barcode-validation/BarcodeValidation';
import { ReviewSettings } from './sections/reviews/ReviewsSettings';
import { SystemReset } from './sections/system-reset/SystemReset';
import { IssueLogic } from './sections/issue-logic/IssueLogic';

const logPrefix = getLogPrefixForType('COMPONENT', 'FacilitySetting');

export const FacilitySettings = () => {
  const { FACILITY_SETTINGS_CARDS_VISIBILITY } = useSettingsStore();

  const [, setActiveFacility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({} as any);

  const [autoAbortDisabled, setAutoAbortDisabled] = useState(false);
  const [autoApprovalDisabled, setAutoApprovalDisabled] = useState(false);
  const [autoUpdateDisabled, setAutoUpdateDisabled] = useState(false);

  const [facilitySettings, setFacilitySettings] = useState({} as IFacilitySettingsST);
  const [initialSettings, setInitialSettings] = useState({} as IFacilitySettingsST);

  const autoAbortRef = useRef<any>();
  const autoApprovalRef = useRef<any>();
  const autoUpdateRef = useRef<any>();

  const { dispatchFacilityLevel, asyncGetFlightDomains, stateFacilityLevel } =
    useFacilityLevelStore();

  const { systemId } = useParams();
  const facilityName = localStorage.getItem('facilityName');

  const { enqueueSnackbar } = useSnackbar();

  const { requestController } = useRequestController(logPrefix);

  const [isFacilitySettingsLoaded, setIsFacilitySettingsLoaded] = useState(
    !isEmpty(stateFacilityLevel.facilitySettings),
  );

  // This use effect ensures that if the user refreshes the page while on the
  // sub page facility settings, the save button and download buttons are displayed after
  // the settings are available on the FE and stored in state.facilitySettings
  useEffect(() => {
    console.debug(logPrefix, 'useEffect', 'set facility settings loaded');
    setIsFacilitySettingsLoaded(() => !isEmpty(stateFacilityLevel.facilitySettings));
  }, [stateFacilityLevel.facilitySettings]);

  const validateInputs = useCallback(() => {
    const { min, max } = validRangeGtZero(
      facilitySettings.barcode_min_length,
      facilitySettings.barcode_max_length,
    );
    let validAbort = autoAbortDisabled
      ? ''
      : validIfGtZero(facilitySettings.auto_abort_period_multiplier, true);
    let validUpdate = autoUpdateDisabled
      ? ''
      : validIfGtZero(facilitySettings.auto_update_period_multiplier, true);
    const areDeadlinesDefined = facilitySettings.report_deadlines
      ? Object.values(facilitySettings.report_deadlines).filter((d) => !!d).length
      : false;
    if (areDeadlinesDefined) {
      if (autoAbortDisabled) {
        validAbort = 'this value is mandatory when deadline is set';
      }
      if (autoUpdateDisabled) {
        validUpdate = 'this value is mandatory when deadline is set';
      }
    }
    const validApproval = validIfGtZero(facilitySettings.auto_approval_period_multiplier);
    const validCorrectResults = validIfInRangeInclusive(
      facilitySettings.fraction_of_correct_results_to_be_reviewed,
      0,
      1,
    );
    const validExternalReviewNumAssignments = validIfInRangeInclusive(
      facilitySettings.external_review_num_assignments_per_review,
      0,
      9,
    );
    const validExternalReviewBatchSize = validIfGtZero(
      facilitySettings.external_review_batch_size_desired,
    );
    const validExternalReviewBatchWaitTime = validIfGtZero(
      facilitySettings.external_review_batch_max_wait_time_s,
    );
    const validMturkLifetime = validIfGtZero(facilitySettings.mturk_hit_lifetime_in_seconds);

    setErrors((prevState: any) => ({
      ...prevState,
      barcodeMax: max,
      barcodeMin: min,
      validAbort,
      validUpdate,
      validApproval,
      validCorrectResults,
      validExternalReviewNumAssignments,
      validExternalReviewBatchSize,
      validExternalReviewBatchWaitTime,
      validMturkLifetime,
    }));
  }, [
    autoAbortDisabled,
    autoUpdateDisabled,
    facilitySettings.auto_abort_period_multiplier,
    facilitySettings.auto_approval_period_multiplier,
    facilitySettings.auto_update_period_multiplier,
    facilitySettings.barcode_max_length,
    facilitySettings.barcode_min_length,
    facilitySettings.external_review_batch_max_wait_time_s,
    facilitySettings.external_review_batch_size_desired,
    facilitySettings.external_review_num_assignments_per_review,
    facilitySettings.fraction_of_correct_results_to_be_reviewed,
    facilitySettings.mturk_hit_lifetime_in_seconds,
    facilitySettings.report_deadlines,
  ]);

  const handleValuesChange = useCallback(
    (prop: string, value: any) => {
      setFacilitySettings({ ...facilitySettings, [prop]: value });
    },
    [facilitySettings],
  );

  const handleSettings = () => {
    const validInputs = Object.values(errors).every((err) => err === '');

    if (!validInputs) {
      enqueueSnackbar('Please validate all inputs', { variant: 'error' });
      return null;
    }

    const requestPayload = {
      ...facilitySettings,
      auto_abort_period_multiplier: !autoAbortDisabled ? Number(autoAbortRef.current.value) : null,
      auto_approval_period_multiplier: !autoApprovalDisabled
        ? Number(autoApprovalRef.current.value)
        : null,
      auto_update_period_multiplier: !autoUpdateDisabled
        ? Number(autoUpdateRef.current.value)
        : null,
      wms_parser_name:
        facilitySettings.wms_parser_name !== 'Disabled' ? facilitySettings.wms_parser_name : null,
      wms_client_api_handler_name:
        facilitySettings.wms_client_api_handler_name !== 'Disabled'
          ? facilitySettings.wms_client_api_handler_name
          : null,
      barcode_match_logic_name:
        facilitySettings.barcode_match_logic_name !== 'Default'
          ? facilitySettings.barcode_match_logic_name
          : null,
    };

    return requestController.doRequest({
      request: WarehouseServices.setFacilitySettings,
      requestParams: [systemId, requestPayload],
      callbackBeforeSend: () => {
        setIsLoading(true);
      },
      callbackSuccess: (r) => {
        LocalStore.dataToLocalStorage({ settingsData: r.data.settings });
        dispatchFacilityLevel({
          type: FacilityActionNames.SET_FACILITY_SETTINGS,
          payload: r.data.settings,
        });

        !stateFacilityLevel.flightDomainsLoaded &&
          asyncGetFlightDomains(requestController, systemId!);
      },
      messageSuccess: 'Settings updated',
      messageErrorFallback: 'Failed to update Settings',
      callbackFinally: () => {
        setIsLoading(false);
        loadData();
      },
    });
  };

  const populateUI = useCallback(
    (settings: IFacilitySettingsST) => {
      const {
        wms_parser_name,
        wms_client_api_handler_name,
        barcode_match_logic_name,
        auto_update_period_multiplier,
        auto_abort_period_multiplier,
        auto_approval_period_multiplier,
      } = settings;

      setInitialSettings({
        ...settings,
        wms_parser_name: wms_parser_name || 'Disabled',
        wms_client_api_handler_name: wms_client_api_handler_name || 'Disabled',
        barcode_match_logic_name: barcode_match_logic_name || 'Default',
      });
      setFacilitySettings({
        ...settings,
        wms_parser_name: wms_parser_name || 'Disabled',
        wms_client_api_handler_name: wms_client_api_handler_name || 'Disabled',
        barcode_match_logic_name: barcode_match_logic_name || 'Default',
      });
      LocalStore.dataToLocalStorage({ settingsData: settings });

      if (auto_update_period_multiplier === null) {
        setAutoUpdateDisabled(true);
      } else {
        setAutoUpdateDisabled(false);
      }
      if (auto_abort_period_multiplier === null) {
        setAutoAbortDisabled(true);
      } else {
        setAutoAbortDisabled(false);
      }
      if (auto_approval_period_multiplier === null) {
        setAutoApprovalDisabled(true);
      } else {
        setAutoApprovalDisabled(false);
      }

      dispatchFacilityLevel({
        type: FacilityActionNames.SET_FACILITY_SETTINGS,
        payload: settings,
      });
    },
    [dispatchFacilityLevel],
  );

  const loadData = useCallback(() => {
    requestController.doRequest({
      request: WarehouseServices.getFacilitySettings,
      requestParams: [systemId],
      callbackBeforeSend: () => setIsLoading(true),
      callbackSuccess: (r) => {
        if (isEmpty(r.data.settings)) {
          setActiveFacility(false);
        } else {
          setActiveFacility(true);
          populateUI(r.data.settings);
        }
      },
      messageErrorFallback: `Failed to fetch the Settings for the facility with id ${systemId}`,
      callbackFinally: () => setIsLoading(false),
    });
  }, [populateUI, requestController, systemId]);

  // Here we handle uncontrolled number inputs with activation
  // for "Report automatic actions", to remove entered value when
  // checkbox is unchecked
  useEffect(() => {
    if (autoUpdateRef.current && autoUpdateDisabled) {
      autoUpdateRef.current.value = '';
    }
    if (autoAbortRef.current && autoAbortDisabled) {
      autoAbortRef.current.value = '';
    }
    if (autoApprovalRef.current && autoApprovalDisabled) {
      autoApprovalRef.current.value = '';
    }
  }, [
    autoAbortDisabled,
    autoAbortRef,
    autoApprovalDisabled,
    autoApprovalRef,
    autoUpdateDisabled,
    autoUpdateRef,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    validateInputs();
  }, [facilitySettings, validateInputs]);

  return (
    <>
      <SettingsPageHeader
        systemId={systemId!}
        isFacilitySettingsLoaded={isFacilitySettingsLoaded}
        onSaveButtonClick={handleSettings}
      />
      <Container maxWidth="xl">
        <Grid direction="column" container className="c-page-content">
          <div id="facility-settings-page">
            <Grid spacing={3} alignItems="flex-start" container>
              <Grid md={6} sm={12} xs={12} item>
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.SYSTEM_RESET && (
                  <SystemReset
                    isLoading={isLoading}
                    initialSettings={initialSettings}
                    facilitySettings={facilitySettings}
                    handleValuesChange={handleValuesChange}
                    facilityName={facilityName!}
                    loadData={loadData}
                  />
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.REPORT_DEADLINES && (
                  <ReportDeadlines
                    isLoading={isLoading}
                    facilitySettings={facilitySettings}
                    setFacilitySettings={setFacilitySettings}
                    validateInputs={validateInputs}
                    handleValuesChange={handleValuesChange}
                    errors={errors}
                    setErrors={setErrors}
                    autoUpdateDisabled={autoUpdateDisabled}
                    autoAbortDisabled={autoAbortDisabled}
                    autoApprovalDisabled={autoApprovalDisabled}
                    setAutoUpdateDisabled={setAutoUpdateDisabled}
                    setAutoAbortDisabled={setAutoAbortDisabled}
                    setAutoApprovalDisabled={setAutoApprovalDisabled}
                    autoUpdateRef={autoUpdateRef}
                    autoAbortRef={autoAbortRef}
                    autoApprovalRef={autoApprovalRef}
                  />
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.WMS_INTEGRATION && (
                  <WmsIntegration
                    systemId={systemId!}
                    isLoading={isLoading}
                    facilitySettings={facilitySettings}
                    handleValuesChange={handleValuesChange}
                  />
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.REPORT_REVIEWS && (
                  <ReviewSettings
                    isLoading={isLoading}
                    facilitySettings={facilitySettings}
                    handleValuesChange={handleValuesChange}
                    errors={errors}
                  />
                )}

                {FACILITY_SETTINGS_CARDS_VISIBILITY?.REPORT_LOCATION && (
                  <ShareFeedback
                    isLoading={isLoading}
                    facilitySettings={facilitySettings}
                    handleValuesChange={handleValuesChange}
                  />
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.DUAL_CHECK_ON_UNLOCK && (
                  <Grid md={12} sm={12} xs={12} item>
                    <SettingsCard
                      testId="c-backend-settings-card"
                      title="Dual Check on Unlock"
                      isLoading={isLoading}
                      items={dualCheckOnUnlockOptions(facilitySettings, handleValuesChange)}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid md={6} sm={12} xs={12} item>
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.LOAD_SETTINGS_FROM_FILE && (
                  <LoadSettinsFromFile populateUI={populateUI} />
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.UI && (
                  <Grid md={12} sm={12} xs={12} item>
                    <SettingsCard
                      title="UI"
                      testId="c-settings-ui-card"
                      isLoading={isLoading}
                      items={getUiOptions(facilitySettings, handleValuesChange)}
                    />
                  </Grid>
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.ISSUES && (
                  <IssueLogic
                    systemId={systemId!}
                    isLoading={isLoading}
                    facilitySettings={facilitySettings}
                    handleValuesChange={handleValuesChange}
                  />
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.BARCODES && (
                  <BarcodeValidation
                    isLoading={isLoading}
                    facilitySettings={facilitySettings}
                    handleValuesChange={handleValuesChange}
                    errors={errors}
                    setErrors={setErrors}
                  />
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.BARCODE_TYPES && (
                  <BarcodeTypesSection
                    isLoading={isLoading}
                    facilitySettings={facilitySettings}
                    setFacilitySettings={setFacilitySettings}
                  />
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.AUTHENTICATION && (
                  <Grid md={12} sm={12} xs={12} item>
                    <SettingsCard
                      title="Authentication"
                      testId="c-authentication-settings-card"
                      isLoading={isLoading}
                      items={getAuthenticationOptions(facilitySettings, handleValuesChange)}
                    />
                  </Grid>
                )}
                {FACILITY_SETTINGS_CARDS_VISIBILITY?.BACKEND && (
                  <Grid md={12} sm={12} xs={12} item>
                    <SettingsCard
                      testId="c-backend-settings-card"
                      title="Backend"
                      isLoading={isLoading}
                      items={getBackendOptions(facilitySettings, handleValuesChange)}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Container>
    </>
  );
};
