import { Button, Divider, Grid, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import MicrosoftIcon from '@mui/icons-material/Microsoft';

// material-ui icons
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// components
import { AUTH_PAGES_URLS } from 'common/pages';
import { getInvalidEmailErrorMessage } from 'common/validators';
import { Box } from 'components/common/Box';
import InputPassword from 'components/common/CustomFormComponents/InputPassword';

// helpers and functions

// styles
import { isValidEmailFormat } from 'common/isValidEmailFormat';
import sharedStyles from './styles';
import { REDIRECT_URL } from './sso-page/ssoPage.defaults';

export type Form = {
  username: string;
  password: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  /**
   * Additional content (paragraph,alert) that can be rendered between form and submit button
   */
  additionalInfo?: React.ReactNode;
  signInWithDifferentAccount?: React.ReactNode;
  showForgotPasswordLink: boolean;
};

export const SignInForm = (props: Form) => {
  const {
    username,
    password,
    additionalInfo,
    signInWithDifferentAccount,
    showForgotPasswordLink,
    handleSubmit,
    handleChange,
  } = props;
  const { classes } = sharedStyles();

  const allInputAreValid = username && password && isValidEmailFormat(username);

  const showSsoButton = import.meta.env.VITE_APP_ENABLE_SSO === 'true';
  const redirectURL = encodeURIComponent(REDIRECT_URL);
  const ssoUrl = import.meta.env.VITE_APP_SSO_SIGN_IN_URL?.replace(
    'REDIRECT_URI_PLACEHOLDER',
    redirectURL,
  );

  return (
    <Box className={classes?.form} gap={2} display="flex" flexDirection="column">
      {showSsoButton && (
        <>
          <Button
            variant="outlined"
            startIcon={<MicrosoftIcon />}
            fullWidth
            color="secondary"
            href={ssoUrl}
          >
            Sign in with microsoft
          </Button>
          <Divider className={classes.divider}>or</Divider>
        </>
      )}
      <form data-testid="c-sign-in-form" onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          className={classes?.inputField}
          fullWidth
          id="username"
          label="Email"
          name="username"
          autoComplete="email"
          error={Boolean(username && !isValidEmailFormat(username))}
          helperText={username && getInvalidEmailErrorMessage(username)}
          autoFocus
          inputProps={{ 'data-testid': 'username' }}
          onChange={handleChange}
        />
        <InputPassword
          id="password"
          variant="outlined"
          name="password"
          label="Password"
          autoComplete="current-password"
          customClasses={classes?.inputField}
          validate={false}
          inputProps={{ 'data-testid': 'password' }}
          onChange={handleChange}
        />
        <Box mb={2}>{additionalInfo}</Box>
        <Box mb={2}>{signInWithDifferentAccount}</Box>
        {showForgotPasswordLink && (
          <Grid style={{ marginBottom: '20px' }} justifyContent="center" container>
            <Grid item>
              <Link
                data-testid="forgotPasswordLink"
                className={classes?.routerLink}
                to={AUTH_PAGES_URLS.FORGOT_PASSWORD}
              >
                Forgot your password?
              </Link>
            </Grid>
          </Grid>
        )}
        <Button
          data-testid="signInButton"
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          disabled={!allInputAreValid}
          endIcon={<KeyboardArrowRightIcon />}
          style={{ margin: '16px 0' }}
        >
          Sign In
        </Button>
      </form>
    </Box>
  );
};
