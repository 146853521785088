/* eslint-disable react-hooks/exhaustive-deps */
import { Canvas } from '@react-three/fiber';
import { Dispatch, useMemo, useRef } from 'react';
import { Vector2, WebGLRenderer } from 'three';
import { Perf } from 'r3f-perf';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { Box } from 'components/common/Box';
import { MapAction } from '../../reducer/3DmapReducer';
import { MapState } from '../../reducer/3DmapState';
import { MutableState } from '../../reducer/MutableState';
import { useForceUpdate } from '../../../../hooks/useForceUpdate';
import { DisableScroll } from '../../utils/DisableScroll';
import { MeshWorldBox } from '../mesh-world-box/MeshWorldBox';
import { Facility } from '../facility/Facility';
import { useEffectOnce } from '../../../../hooks/useEffectOnce';
import { FacilityManager } from '../facility-manager/FacilityManager';
import { NodeST } from '../../MapContainer.model';
import { mapStyle } from './MapCanvas.style';

const useStyles = makeStyles()((theme: Theme) => ({
  mapCanvasWrapper: {
    borderRadius: theme.spacing(0.5),
    overflow: 'hidden',

    '&::after': {
      content: "''",
      position: 'absolute',
      insetBlock: 0,
      insetInline: 0,
      pointerEvents: 'none',
      borderRadius: 'inherit',
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: 'inset 0 2px 8px -2px rgba(0,0,0,0.1), inset 0 2px 12px -4px rgba(0,0,0,0.2)',
    },
  },
  controlsOverlay: {
    position: 'absolute',
    insetInlineEnd: theme.spacing(1),
    insetBlockEnd: theme.spacing(1),
  },
}));

const logPrefix = getLogPrefixForType('COMPONENT', '3DMap');

/**
 * Main 3D map component.
 * Creates and sets up the 3D map.
 * @param props reducer state and dispatch
 * @returns JSX.Element
 */
export const MapCanvas = ({
  mapState,
  mutableState,
  floor,
  zoomControls,
  mapElements,
  camera,
  canvasSize,
  wrapperId,
  mapDispatch,
  onMouseLeave,
  onPointerMissed,
}: {
  mapState: MapState | (MapState & { map: NodeST; flightDomainName: string });
  mutableState: MutableState<unknown>;
  canvasSize: { width: number; height: number };
  wrapperId: string;
  floor: JSX.Element;
  camera: JSX.Element;
  zoomControls?: JSX.Element;
  mapElements?: JSX.Element | JSX.Element[];
  mapDispatch: Dispatch<MapAction>;
  onMouseLeave?: () => void;
  onPointerMissed?: () => void;
}) => {
  const { classes } = useStyles();
  const { options, map } = mapState as MapState & {
    map: NodeST;
    flightDomainName: string;
  };

  const disableScroll = useMemo(() => new DisableScroll(), []);
  const forceUpdate = useForceUpdate('3Dmap');
  const rendererRef = useRef<WebGLRenderer>();
  const wrapper = document.getElementById(wrapperId);
  MutableState.forceUpdateMap = forceUpdate;

  const facilityManager = useMemo(() => FacilityManager.getInstance(mapState), [map]);
  const facility = useMemo(
    () => <Facility facilityManager={facilityManager} lod={mapState.lod} />,
    [facilityManager, mapState.lod],
  );

  const handleMouseEnter = () => {
    mutableState.cameraState.blockCameraMovement = false;
    disableScroll.on();
  };

  const handleMouseLeave = () => {
    mutableState.cameraState.blockCameraMovement = true;
    mutableState.interaction.isFloorHeld = false;
    disableScroll.off();

    if (onMouseLeave) {
      onMouseLeave();
    }
  };

  const handleResize = () => {
    const currWrapper = document.getElementById(wrapperId);
    if (!currWrapper) {
      console.debug(logPrefix, 'handling resize of map FAILED due to non-existing wrapper element');
      return;
    }
    const width = currWrapper.clientWidth;
    const height = currWrapper.clientHeight;

    const rendererCurrentSize = new Vector2();
    rendererRef.current?.getSize(rendererCurrentSize);

    if (rendererCurrentSize.x === width && rendererCurrentSize.y === height) {
      console.debug(logPrefix, 'renderer already set to correct size, no change');
      return;
    }

    console.debug(logPrefix, `handleResize to (${width}, ${height})`);
    mapDispatch({ type: 'setCanvasProportion', payload: [width, height] });
    rendererRef.current?.setSize(width, height, true);
    console.debug(logPrefix, `${mutableState.mapName} resized to ${width}x${height}`);
  };

  useEffectOnce(mutableState.mapName, () => {
    window.addEventListener('resize', handleResize);
    // handling resize immediately after render to set correct canvas proportions
    handleResize();
    console.debug(logPrefix, 'resize event listener added');
    return () => {
      window.removeEventListener('resize', handleResize);
      console.debug(logPrefix, 'resize event listener removed');
    };
  });

  console.debug(logPrefix, `rendering 3DMap for FD ${mapState.flightDomainName}`);

  return (
    <Box
      position="relative"
      width="100%"
      className={classes.mapCanvasWrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Canvas
        flat
        style={{
          width: canvasSize.width ? canvasSize.width : wrapper?.offsetWidth,
          height: canvasSize.height,
        }}
        gl={(canvas) => {
          const renderer = new WebGLRenderer({
            canvas,
            antialias: true,
            logarithmicDepthBuffer: true,
          });
          renderer.setPixelRatio(window.devicePixelRatio);
          rendererRef.current = renderer;
          return renderer;
        }}
        onPointerMissed={onPointerMissed}
      >
        <color attach="background" args={[mapStyle.floor.void.backgroundColor]} />

        {options.facility.showWorldBoxWireframe ? <MeshWorldBox box={map.box} /> : null}

        {options.facility.showPerformanceMonitor ? <Perf /> : null}

        <ambientLight />

        {camera}

        {mapElements}

        {facility}

        {floor}
      </Canvas>

      <div className={classes.controlsOverlay}>{zoomControls}</div>
    </Box>
  );
};
