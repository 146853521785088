import { TreeViewBaseItem } from '@mui/x-tree-view/models';
import { IRulesST } from 'codegen/facility_settings';

export const createTreeItems = (rules?: IRulesST[]): TreeViewBaseItem[] => {
  if (!rules) {
    return [];
  }
  return rules.map((r: any, i: number) => ({
    children: [
      {
        id: `conditions ${i}`,
        label: 'conditions',
        children: r.conditions?.map((c: any, j: number) => ({
          id: `condition ${i}->${j}`,
          label: `${c.field} ${c.operator.type} ${c.operator.value}`,
        })),
      },
      {
        id: `actions ${i}`,
        label: 'action',
        children: [{ id: `action ${i}`, label: `issue logic: ${r.action.issue_logic}` }],
      },
    ],
    id: `rule ${i + 1}`,
    label: `rule ${i + 1}`,
  }));
};
