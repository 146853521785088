import moment from 'moment-timezone';

import { createElement } from 'react';

import { RELATIVE_DATETIME_FORMAT, DATETIME_FORMAT_WITH_WEEKDAY } from 'common/datetimeFormats';
import { ISSUE_TYPES } from 'common/issueTypesAndStates';
import { REPORT_STATES } from 'common/reportStates';
import {
  DISPATCHED_AND_ONGOING_REPORT_ACTIONS,
  ONGOING_REPORT_ACTIONS,
  REPORT_ACTIONS,
} from 'common/Actions/actionTypes';
import { sort } from 'common/functions/otherFunctions';
import {
  ISSUE_TAB_ALLOWED_DATA,
  SNOOZED_TAB_ALLOWED_DATA,
  POTENTIAL_TAB_ALLOWED_DATA,
  INCONCLUSIVE_TAB_ALLOWED_DATA,
  INVALID_TAB_ALLOWED_DATA,
} from 'common/tabDataDefinitions';
import {
  getRowForFullReportTable,
  getRowForLocationsToReviewTable,
  getRowAndSingleIssueForIssueTable,
} from 'common/functions/locationRows/locationRowsFunctions';
import { convertRruleToUIComponents, getRecurrenceText } from 'common/functionsReportSpec';
import { isReportFinishedIncomplete } from 'common/functions/reportFunctions';
import { policyForAmendedLocationsInReport } from 'common/functions/slot/slotFunctions';
import {
  getReqParamsStringForTablesFromInventoryRequest,
  getReqParamUserFacingName,
  getReqParamUserFacingValues,
} from 'common/functions/requestParameterFunctions';
import { IReportST } from 'codegen/report';
import { IInventoryRequestST } from 'codegen/inventory_request';

import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { IRequestController } from 'hooks';
import { IIssueST, ILocationDataST, IReportRequestActionInternal, ISlotStatusST } from 'interfaces';
import { ILocationReportData } from 'pages/WarehouseStatus/warehouseStatusFunctions';
import { IconColor } from 'ts-types/IconColor';
import { LocalStore } from 'common/functions/storageFunctions';
import { formatReportProgress } from '../../reportsFunctions';
import { Report } from '../../Reports.model';
import { getReportPriorityIcons } from '../../utils/getReportPriorityIcons';
import { IProcessReportSummariesData } from './processReportSummariesData.model';
import ReportServices from '../../api/ReportServices';
import { IssueSummary } from '../../features/report/report-overview/issue-summary-card/IssueSummaryCard';
import { getReportSummaryData } from './report-summary/getReportSummaryData';
import { getReportExecutionData } from './report-execution/getReportExecutionData';
import { getIssuesSummary } from './getIssueSummary';
import { ReportSummary } from './ReportStore.model';

/**
 * GET ALL REPORT SPECIFICATIONS
 * @param systemId ID of the facility the specification are read for
 * @returns fullResponse - route raw response
 * reportSpecifications - all specifications sorted in ascending order by request_default_name
 */
const getReportSpecifications = (systemId: string) =>
  ReportServices.getReportSpecifications(systemId).then((r) => {
    const reportSpecifications = sort({
      array: r.data.items,
      sortingOrder: 'asc',
      sortBy: 'request_default_name',
    });

    return {
      fullResponse: r,
      reportSpecifications,
    };
  });

/**
 * Get single report specification
 * @param systemId facility ID
 * @param id report ID
 * @returns fullResponse - route raw response
 */
const getReportSpecification = (systemId: string, id: string) =>
  ReportServices.getReportSpecification(systemId, id).then((r) => ({
    fullResponse: r,
  }));

export type ReportActionButton = { label: string; disabled: boolean; clickHandler: () => void };

export const generateRowData = (row: IReportST) => {
  const rowData = {} as Report;
  rowData.rawData = row;

  // Access to Report Actions
  const displayUpdateReportAction = userHasPermission(PERMISSION.UPDATE_REPORTS);
  const displayAbortReportAction = userHasPermission(PERMISSION.ABORT_ONGOING_REPORTS);
  const displayRestoreAction =
    row.state === REPORT_STATES.DELETED &&
    userHasPermission(PERMISSION.ARCHIVED_REPORTS_MANAGEMENT);
  const displayArchiveAction =
    row.state !== REPORT_STATES.DELETED &&
    row.state !== REPORT_STATES.ONGOING &&
    row.state !== REPORT_STATES.SCHEDULED &&
    userHasPermission(PERMISSION.ARCHIVED_REPORTS_MANAGEMENT);

  const { isRecurring } = convertRruleToUIComponents(row?.request?.rrule);
  const inventoryOperations: IReportRequestActionInternal = {
    data: row,
    actions: [],
  };

  const priority = getReportPriorityIcons(row.request.priority);

  if (displayRestoreAction) {
    inventoryOperations.actions.push({ label: REPORT_ACTIONS.RESTORE, disabled: false });
  }

  if (displayArchiveAction) {
    inventoryOperations.actions.push({ label: REPORT_ACTIONS.ARCHIVE, disabled: false });
  }

  // Ongoing reports can be updated by certain users
  // note: reports to review are technically ongoing, so this will also apply to those
  if (row.state === REPORT_STATES.ONGOING && displayUpdateReportAction) {
    inventoryOperations.actions.push({ label: ONGOING_REPORT_ACTIONS.UPDATE, disabled: false });
  }

  // Ongoing and dispatched reports can be aborted by certain users
  const isReportAbortable =
    [REPORT_STATES.ONGOING, REPORT_STATES.SCHEDULED].includes(row.state as REPORT_STATES) &&
    displayAbortReportAction;

  if (isReportAbortable) {
    inventoryOperations.actions.push({
      label: DISPATCHED_AND_ONGOING_REPORT_ACTIONS.ABORT,
      disabled: false,
    });
  }

  rowData.id = row.report_id;
  rowData.reportType = row.request.report_spec.report_spec_name;
  rowData.reportName = row.request.report_name || '-';
  rowData.requestParams = getReqParamsStringForTablesFromInventoryRequest(row.request);
  rowData.scannedLocations = row.location_data_count.SCANNED;
  rowData.completion = row.completion;
  rowData.scheduledBy = row.request.requesting_user_email || 'n.a.';
  rowData.scheduledFor = row.scheduled_for_utc;
  rowData.recurrence = getRecurrenceText(
    row.request.report_spec.report_spec_name,
    isRecurring,
    row.request.recurrence_description,
  );
  rowData.startedAt = row.started_at || '-';
  rowData.finishedAt = row.finished_at || '-';
  rowData.allLocations = isReportFinishedIncomplete(row)
    ? `${row.location_data_count.SCANNED}/${getAllIncludedLocations(row)}`
    : getAllIncludedLocations(row).toString();
  rowData.locationsToScan = row.num_locations_to_inspect;
  rowData.progress = formatReportProgress(row.progress);
  rowData.issues =
    row.issue_count.totals[ISSUE_TYPES.WMS_BARCODE_VERITY_EMPTY] +
    row.issue_count.totals[ISSUE_TYPES.WMS_BARCODE_NOT_EQUAL_VERITY_BARCODE] +
    row.issue_count.totals[ISSUE_TYPES.WMS_EMPTY_VERITY_BARCODE] +
    row.issue_count.totals[ISSUE_TYPES.WMS_EMPTY_VERITY_NOTEMPTY];
  rowData.status = row.state;
  rowData.priority =
    userHasPermission(PERMISSION.PRIORITY_PICKER_INTERNAL) && row.request.priority > 1 ? (
      <span>{row.request.priority}</span>
    ) : (
      createElement(priority.icon, { color: priority.color as IconColor })
    );

  rowData.actions = inventoryOperations;
  rowData.allowRedirection = true;

  return rowData;
};

/**
 * Included Locations = All Locations - Excluded
 * @param report Report data
 * @returns the # of included locations
 */
const getAllIncludedLocations = (report: IReportST) =>
  report.num_locations - (report.location_data_count.EXCLUDED ?? 0);

export const processReportSummariesData = (
  reports: IReportST[],
  data?: IProcessReportSummariesData | undefined,
) => {
  const timezone = LocalStore.getTimezone();

  let reportsFinishedAll = data?.reportsFinishedAll || [];
  let reportsOngoingAll = data?.reportsOngoingAll || [];
  let reportsToReviewAll = data?.reportsToReviewAll || [];
  let reportsArchivedAll = data?.reportsArchivedAll || [];
  let reportsInfo = data?.reportsInfo || [];

  reports.forEach((row) => {
    const rowData = generateRowData(row);

    if (row.state === REPORT_STATES.FINISHED.toString()) {
      reportsFinishedAll.push(rowData);
    } else if (
      [REPORT_STATES.ONGOING.toString(), REPORT_STATES.SCHEDULED.toString()].includes(row.state)
    ) {
      row.num_locations_to_review && reportsToReviewAll.push(rowData);
      reportsOngoingAll.push(rowData);
    } else if (row.state === REPORT_STATES.DELETED.toString()) {
      reportsArchivedAll.push(rowData);
    }
  });

  reportsFinishedAll = sort({
    array: reportsFinishedAll,
    sortingOrder: 'desc',
    sortBy: 'finishedAt',
  });

  reportsToReviewAll = sort({
    array: reportsToReviewAll,
    sortingOrder: 'desc',
    sortBy: 'startedAt',
  });

  reportsOngoingAll = sort({
    array: reportsOngoingAll,
    sortingOrder: 'desc',
    sortBy: 'startedAt',
  });

  reportsArchivedAll = sort({
    array: reportsArchivedAll,
    sortingOrder: 'desc',
    sortBy: 'startedAt',
  });

  // ######################################################
  // Homepage Report cards info - number of reports and
  // date of the latest report
  // ######################################################
  reportsInfo = {
    ...reportsInfo,
    finishedReports: {
      numberOfReports: reportsFinishedAll.length,
      lastUpdatedAt: reportsFinishedAll[0]
        ? moment(reportsFinishedAll[0].finishedAt)
            .tz(timezone)
            .calendar(null, RELATIVE_DATETIME_FORMAT)
        : '',
    },
    ongoingReports: {
      numberOfReports: reportsOngoingAll.length,
      mostRecentStart: reportsOngoingAll[0]
        ? moment(
            reportsOngoingAll[0].startedAt !== '-'
              ? reportsOngoingAll[0].startedAt
              : reportsOngoingAll[0].scheduledFor,
          )
            .tz(timezone)
            .calendar(null, RELATIVE_DATETIME_FORMAT)
        : '',
    },
    toReviewReports: {
      numberOfReports: reportsToReviewAll.length,
      lastUpdatedAt: reportsToReviewAll[0]
        ? moment(reportsToReviewAll[0].startedAt)
            .tz(timezone)
            .calendar(null, RELATIVE_DATETIME_FORMAT)
        : '',
    },
  };

  // ######################################################
  // Data for Latest reports card on Homepage
  // ######################################################
  const slicedFinishedReports = reportsFinishedAll.slice(0, 4);
  const finishedReports = slicedFinishedReports.map((report: Report) => ({
    reportId: report.id,
    reportType: report.reportType,
    date: `finished on ${moment(report.finishedAt)
      .tz(timezone)
      .format(DATETIME_FORMAT_WITH_WEEKDAY)}`,
    recurrence: report.recurrence !== '-',
    recurrenceDescription: report.recurrence || '',
    reportStatus: report.status,
    reportName: report.reportName,
    totalLocations: isReportFinishedIncomplete(report.rawData)
      ? `${report.scannedLocations} of ${report.locationsToScan}`
      : report.allLocations.toString(),
    totalIssues: report.issues,
  }));

  // ######################################################
  // Data for Ongoing reports card on Homepage
  // ######################################################
  const ongoingReports = reportsOngoingAll.map((report: Report) => ({
    reportId: report.id,
    reportType: report.reportType,
    date: `${
      report.startedAt !== '-'
        ? `started on ${moment(report.startedAt).tz(timezone).format(DATETIME_FORMAT_WITH_WEEKDAY)}`
        : `dispatched on ${moment(report.scheduledFor)
            .tz(timezone)
            .format(DATETIME_FORMAT_WITH_WEEKDAY)}`
    }`,
    recurrence: report.recurrence !== '-',
    recurrenceDescription: report.recurrence || '',
    progress: report.progress,
    completion: report.completion,
    reportStatus: report.status,
    reportName: report.reportName,
    totalLocations: `${report.allLocations}`,
    locationsToScan: `${report.locationsToScan}`,
    actions: report.actions,
  }));

  // ######################################################
  // Data for To review card on Homepage
  // ######################################################
  const toReviewReports = reportsToReviewAll.map((report: any) => ({
    reportId: report.id,
    reportType: report.reportType,
    date: `started on ${moment(report.startedAt)
      .tz(timezone)
      .format(DATETIME_FORMAT_WITH_WEEKDAY)}`,
    recurrence: report.recurrence !== '-',
    recurrenceDescription: report.recurrence || '',
    progress: report.progress,
    completion: report.completion,
    reportStatus: report.status,
    reportName: report.reportName,
    totalLocations: report.allLocations,
    locationsToScan: report.locationsToScan,
    actions: report.actions,
  }));

  return {
    reportsFinishedAll,
    reportsOngoingAll,
    reportsToReviewAll,
    reportsArchivedAll,
    reportsInfo,
    finishedReports,
    ongoingReports,
    toReviewReports,
  };
};

/**
 * Recursively fetch report summaries
 */
const asyncGetReportSummariesRecursively = async (
  systemId: string,
  params: any,
  /**
   * Request Controller
   */
  requestController: IRequestController,
  /**
   * Request ID for the request inside the passed request controller
   */
  requestId: string,
  /**
   * Axios Abort Signal
   */
  signal: AbortSignal,
  /**
   * if true it loads only the first batch of reports (e.g. in the home page).
   * if false it recursively keeps on loading all the reports (batch by batch).
   */
  getJustFirstPage: boolean,
  /**
   * Call-back invoked after each page is fetched. Used e.g. to partially populate reports list
   * as the list is loaded.
   */
  callBackPerPage: any = null,
) => {
  // Control variable to indicate that all report summaries have been fetched
  let allReportSummariesFetched = false;

  // Store the results to return to the invoking function
  let results;

  // Used on the first request with all params
  // replaced by page token on subsequent requests
  let reqParams = { ...params };

  // Get report summaries in a paginated fashion
  // loop while there is a pageToken is in the response
  // and the cancel token promise isn't resolved
  while (!allReportSummariesFetched && !requestController.isRequestCancelled(requestId)) {
    // We don't use a try/catch here because, errors are dealt with
    // on the files where this function is called
    const resp = await requestController.doRequest({
      request: ReportServices.getReportSummaries,
      requestParams: [systemId, reqParams, signal],
      messageErrorFallback: 'The Report Summaries could not be fetched.',
    });

    // We only process the response, in case the cancel token promise is not resolved.
    // If it is resolved, it was meanwhile we waited for a response, and it means that
    // the response will be undefined.
    if (!requestController.isRequestCancelled(requestId)) {
      const reports = resp.data.items;
      const pageToken = resp.data.page_token;

      results = processReportSummariesData(reports, results);
      if (callBackPerPage) {
        callBackPerPage(results);
      }

      if (!pageToken || getJustFirstPage) {
        allReportSummariesFetched = true;
      } else {
        reqParams = { pageToken };
        continue;
      }
    }
  }

  return results;
};

// ###########################################################################
// GET report data
//
// Receives:
//    id - id of the report
//    fromSlot - the name of the slot from where to start the list
//    untilSlot - the name of the last slot to retrieve
// Returns:
//   reportData - dictionary with data for all the locations within the given
//                range
// ###########################################################################
const getReportData = (
  systemId: string,
  id: string,
  fromSlot: string,
  untilSlot: string,
  signal: AbortSignal,
) =>
  ReportServices.getReport(systemId, id, fromSlot, untilSlot, signal).then((r) => ({
    reportData: r.data.item,
  }));

export type GetReportData = {
  fullResponse: IReportST;
  pageTitle: string;
  pageSubtitle: string;
  issues: ILocationReportData[];
  amended: ILocationReportData[];
  snoozed: ILocationReportData[];
  potential: ILocationReportData[];
  inconclusive: ILocationReportData[];
  invalid: ILocationReportData[];
  fullReport: ILocationReportData[];
  issuesSummary: IssueSummary;
  locationsToReview: ILocationReportData[];
  reportNeedsReview: boolean;
  reportState: string;
  reportName: string;
};
// ###########################################################################
// GET REPORT - get data to be displayed on single report page
//
// Returns:
//   fullResponse - route raw response
//   pageTitle - title of the page, equal to report spec name
//   pageSubtitle - holds the date this report is scheduled for
//   issues - holds data for Issues tab on single report page
//   amended - holds data for Amended tab on single report page
//   snoozed - holds data for Snoozed tab on single report page
//   potential - holds data for Potential tab on single report page
//   inconclusive - holds data for Inconclusive tab on single report page
//   fullReport - holds data for Full report tab on single report page
//   locationsToReview - holds data for Slots to review tab on single report page
//   issuesSummary - holds data for Issues card on single report page
//   reportNeedsReview - if the report needs to be reviewed by Verity user
// ###########################################################################
const getReport = (
  systemId: string,
  id: string,
  fullReportSummary: any,
  fromSlot: any,
  untilSlot: any,
  signal: AbortSignal,
) => {
  const reportData: any = [];

  let report = {
    ...fullReportSummary,
    locations_data: {},
    locations: [],
    locations_data_for_review: {},
    issues: {},
  };

  // Compile data full report and amended tabs
  return getReportData(systemId, id, fromSlot, untilSlot, signal).then((r) => {
    reportData.push(r.reportData);
    // Join all locations
    reportData.forEach((locationSet: any) => {
      report = {
        ...report,
        locations_data: {
          ...report.locations_data,
          ...locationSet.locations_data,
        },
        locations: [...report.locations, ...locationSet.locations],
        locations_data_for_review: {
          ...report.locations_data_for_review,
          ...locationSet.locations_data_for_review,
        },
        issues: {
          ...report.issues,
          ...locationSet.issues,
        },
      };
    });

    const locationsData: Record<string, ILocationDataST> = report.locations_data;
    const issuesData: Record<string, IIssueST[]> = report.issues;
    const reportState: REPORT_STATES = report.state;
    const reportName: string = report.request.report_name;
    const reportNeedsReview = !!report.num_locations_to_review;
    let locationsToReviewData: Record<string, ISlotStatusST> =
      report.locations_data_for_review || {};

    // The call to the 'approve' route should only consider slots
    // that have a verity status, so we filter those that don't out
    // this is also done in the backend, but this way we have another
    // layer assuring integrity of the data presented to the user
    locationsToReviewData = Object.assign(
      {},
      ...Object.entries(locationsToReviewData)
        // lint rule is disabled because looping through an object is not possible without [k, v]
        // eslint-disable-next-line no-unused-vars
        .filter(([k, v]) => v.verity_status)
        .map(([k, v]) => ({ [k]: v })),
    );

    let issues: any[] = [];
    let amended: any[] = [];
    let snoozed: any[] = [];
    let potential: any[] = [];
    let inconclusive: any[] = [];
    let invalid: any[] = [];
    let fullReport: any[] = [];
    let locationsToReview: any[] = [];

    const pageTitle = report.request.report_spec.report_spec_name;
    const pageSubtitle = '';

    const issuesSummary = getIssuesSummary(report);

    // ######################################################
    // Get data for Full report and Amended tab
    // ######################################################
    Object.entries(locationsData).forEach(([location, locationData]) => {
      let rowData = {};
      rowData = getRowForFullReportTable(location, locationData, locationData.issues, true);

      // Add locations for full report tab
      fullReport = [...fullReport, rowData];

      // Add locations for amended tab
      if (policyForAmendedLocationsInReport(locationData)) {
        amended = [...amended, rowData];
      }
    });

    // ######################################################
    // Get data for Issues, Snoozed, Inconclusive, Invalid
    // and Potential tabs
    // ######################################################
    Object.entries(issuesData).forEach(([location, locationIssueData]) => {
      const rowDataAndSingleIssueData = getRowAndSingleIssueForIssueTable(
        location,
        locationIssueData,
      );
      const { rowData, singleIssueData } = rowDataAndSingleIssueData;

      // Add issues to respective tabs
      if (
        ISSUE_TAB_ALLOWED_DATA.STATES.includes(singleIssueData.state) &&
        ISSUE_TAB_ALLOWED_DATA.ISSUE_TYPES.includes(singleIssueData.type as unknown as ISSUE_TYPES)
      ) {
        issues = [...issues, rowData];
      } else if (SNOOZED_TAB_ALLOWED_DATA.STATES.includes(singleIssueData.state)) {
        snoozed = [...snoozed, rowData];
      } else if (
        POTENTIAL_TAB_ALLOWED_DATA.ISSUE_TYPES.includes(
          singleIssueData.type as unknown as ISSUE_TYPES,
        )
      ) {
        potential = [...potential, rowData];
      } else if (
        INCONCLUSIVE_TAB_ALLOWED_DATA.ISSUE_TYPES.includes(
          singleIssueData.type as unknown as ISSUE_TYPES,
        )
      ) {
        inconclusive = [...inconclusive, rowData];
      } else if (
        INVALID_TAB_ALLOWED_DATA.ISSUE_TYPES.includes(
          singleIssueData.type as unknown as ISSUE_TYPES,
        )
      ) {
        invalid = [...invalid, rowData];
      }
    });

    // ######################################################
    // Get data for Locations to review tab
    // ######################################################
    Object.entries(locationsToReviewData).forEach(([location, locationData]) => {
      const rowData = getRowForLocationsToReviewTable(
        location,
        locationData,
        report.issues[location],
      );
      locationsToReview = [...locationsToReview, rowData];
    });

    return {
      fullResponse: report,
      pageTitle,
      pageSubtitle,
      issues,
      amended,
      snoozed,
      potential,
      inconclusive,
      invalid,
      fullReport,
      issuesSummary,
      locationsToReview,
      reportNeedsReview,
      reportState,
      reportName,
    };
  });
};

/**
 * Get report summary
 */
const getReportSummary = (
  systemId: string,
  id: string,
  signal: AbortSignal,
): Promise<ReportSummary> =>
  ReportServices.getReportSummary(systemId, id, signal).then((r) => {
    const reportSummary: IReportST = r.data.item;
    const reportNeedsReview = !!reportSummary.num_locations_to_review;
    let requestParams: any = [];

    reportSummary.request.params.forEach((param) => {
      const labelString = getReqParamUserFacingName(
        param,
        reportSummary.request as IInventoryRequestST,
      );
      const valueString = getReqParamUserFacingValues(
        param,
        reportSummary.request as IInventoryRequestST,
      );

      // We ignore request params that have no values or aliases for their values
      // for example PALLET_LOCATION, on an Empty-Locations inventory request
      if (valueString !== '') {
        requestParams = [
          ...requestParams,
          {
            label: labelString,
            value: valueString,
          },
        ];
      }
    });

    const reportLocationData = getReportSummaryData(reportSummary);

    const reportExecutionData = getReportExecutionData(requestParams, reportSummary);

    return {
      fullResponse: reportSummary,
      reportLocationData,
      reportExecutionData,
      reportNeedsReview,
    };
  });

const sendReportEmail = (systemId: string, id: string, signal: AbortSignal) =>
  ReportServices.sendReportEmail(systemId, id, signal);

const archiveReports = (systemId: string, ids: string[]) =>
  ReportServices.archiveReports(systemId, ids);

const restoreReports = (systemId: string, ids: string[]) =>
  ReportServices.restoreReports(systemId, ids);

const reportStore = {
  getReportSpecifications,
  getReportSpecification,
  getReport,
  getReportSummary,
  asyncGetReportSummariesRecursively,
  getReportData,
  sendReportEmail,
  archiveReports,
  restoreReports,
};

export default reportStore;
