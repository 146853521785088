import { Container } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import { Box } from 'components/common/Box';
import { IssueSummary } from './report-overview/issue-summary-card/IssueSummaryCard';
import { CurrentTab } from './utils/GetTabData';
import { ReportSummaryData } from '../../reducer/report-store/ReportStore.model';
import { ReportOverview } from './report-overview/ReportOverview';
import { ReportLocationsGrid } from './report-locations-grid/ReportLocationsGrid';

export type ReportProps = {
  canReviewReport: boolean;
  reportNeedsReview: boolean;
  issuesSpinner: number;
  issuesSummary: IssueSummary;
  summarySpinner: number;
  reportLocationData: ReportSummaryData;
  reportExecutionData: ReportSummaryData;
  currentTabData: CurrentTab;
  reportState: string;
  tabs: { icon: JSX.Element; label: string; show: boolean }[];
  getReportData: (reportId: string, nLocationsPerRequest: number) => void;
  activeTab: number;
  reviewLocationsTab: number;
  fullReportSpinner: number;
  currentActiveSpinner: {};
  changeTab: (tabValue: number) => void;
};

export const Report = ({
  canReviewReport,
  reportNeedsReview,
  issuesSpinner,
  issuesSummary,
  summarySpinner,
  reportLocationData,
  reportExecutionData,
  currentTabData,
  reportState,
  tabs,
  changeTab,
  getReportData,
  activeTab,
  reviewLocationsTab,
  fullReportSpinner,
  currentActiveSpinner,
}: ReportProps) => {
  const { reportId = '' } = useParams();

  return (
    <Container maxWidth="xl">
      <ReportOverview
        summarySpinner={summarySpinner}
        issuesSpinner={issuesSpinner}
        issuesSummary={issuesSummary}
        canReviewReport={canReviewReport}
        reportNeedsReview={reportNeedsReview}
        reportLocationData={reportLocationData}
        reportExecutionData={reportExecutionData}
      />

      {!isEmpty(tabs) && (
        <Box>
          <ReportLocationsGrid
            tabs={tabs}
            activeTab={activeTab}
            changeTab={changeTab}
            reviewLocationsTab={reviewLocationsTab}
            currentTabData={currentTabData}
            reportId={reportId}
            isLoading={fullReportSpinner}
            currentActiveSpinner={currentActiveSpinner}
            reportState={reportState}
            reportNeedsReview={reportNeedsReview}
            getReportData={getReportData}
          />
        </Box>
      )}
    </Container>
  );
};
