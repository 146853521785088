import {
  GridColDef,
  GridFilterInputMultipleValue,
  GridFilterItem,
  GridFilterOperator,
} from '@mui/x-data-grid';

export const isNotAnyOfOperator: GridFilterOperator<any, number> = {
  label: 'is not any of',
  headerLabel: 'is not any of',
  value: 'isNotAnyOf',
  getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }

    const valuesToExclude = filterItem.value.map((value: string) => value.trim());
    return (value) => !valuesToExclude.includes(value?.toString());
  },
  InputComponent: GridFilterInputMultipleValue,
};
