import {
  GridCellParams,
  GridColDef,
  GridTreeNode,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridStringOperators,
} from '@mui/x-data-grid-premium';
import BlockIcon from '@mui/icons-material/Block';
import { Tooltip } from '@mui/material';
import { ILocationDataST } from 'codegen/warehouse_status';
import { AllIssueTypes } from '../utils/WHSFunctions';
import { TooltipedCell } from '../TooltipedCell';
import {
  contentFoundValueGetter,
  customerValueGetter,
  dateTimeValueFormatter,
  exclusionStatusValueGetter,
  expectedContentValueGetter,
  firstFoundOnGroupingValueGetter,
  firstFoundOnValueGetter,
  foundDateGroupingValueGetter,
  foundDateValueGetter,
  issueStatusValueGetter,
  issueValueGetter,
  shouldBeAtValueGetter,
  wasFoundAtValueGetter,
  wmsArticleNumberValueGetter,
  wmsDateGroupingValueGetter,
  wmsDateValueGetter,
  wmsQuantityValueGetter,
} from '../utils/WHSCellValueGetters';
import {
  ContentExpectedCell,
  ContentFoundCell,
  IssueCell,
  IssueStateCell,
  ISSUE_STATE,
  ExclusionStatusCell,
} from '../Cell';
import { ContentShouldBeAtCell } from '../Cell/ContentShouldBeAtCell';
import { ContentWasFoundAtCell } from '../Cell/ContentWasFoundAtCell';
import { WmsQuantityCell } from '../Cell/WmsQuantityCell';
import { isNotAnyOfOperator } from '../utils/customFilters/isNotAnyOfOperator';
import { notContainsOperator } from '../utils/customFilters/notContainsOperator';

// The render cell function should only return true when its a leaf node and not a group node
// or when its the autogenerated expandable group column
const isNotGroupCell = (field: string, rowNode: GridTreeNode): boolean =>
  !(rowNode.type === 'group' && !field.startsWith('__row_group'));

const customGridStringOperators = getGridStringOperators().filter(
  ({ value }) => !['isEmpty', 'isNotEmpty'].includes(value),
);

const customGridDateOperators = getGridDateOperators().filter(
  ({ value }) => !['isEmpty', 'isNotEmpty'].includes(value),
);

const specColumns: GridColDef<ILocationDataST>[] = [
  {
    headerName: 'Location',
    field: 'slot_label',
    minWidth: 100,
    groupable: false,
    valueGetter: (value, row) => row.slot_label || '-',
    renderCell: (params) => isNotGroupCell(params.field, params.rowNode) && params.value,
  },
  {
    renderHeader: (params) => (
      <Tooltip title="Excluded locations will not be visited by drones">
        <BlockIcon />
      </Tooltip>
    ),
    headerAlign: 'center',
    headerName: 'Exclusion status',
    field: 'exclusion_status',
    minWidth: 60,
    groupable: true,
    type: 'boolean',
    valueGetter: exclusionStatusValueGetter,
    groupingValueGetter: exclusionStatusValueGetter,
    renderCell: (params) => <ExclusionStatusCell row={params.row} />,
    filterOperators: getGridBooleanOperators(),
  },
  {
    headerName: 'Expected content',
    field: 'wms_status.state',
    groupable: false,
    minWidth: 170,
    valueGetter: expectedContentValueGetter,
    renderCell: (params) => <ContentExpectedCell row={params.row} />,
  },
  {
    headerName: 'Was found at',
    field: 'wasFoundAt',
    valueGetter: wasFoundAtValueGetter,
    groupingValueGetter: wasFoundAtValueGetter,
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && <ContentWasFoundAtCell row={params.row} />,
  },
  {
    headerName: 'WMS article nr.',
    field: 'wms_status.article_nos.0',
    minWidth: 60,
    groupable: false,
    valueGetter: wmsArticleNumberValueGetter,
    groupingValueGetter: wmsArticleNumberValueGetter,
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <TooltipedCell value={params.formattedValue} />
      ),
  },
  {
    headerName: 'WMS quantity',
    field: 'wms_status.qtys.0',
    groupable: false,
    minWidth: 60,
    valueGetter: wmsQuantityValueGetter,
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && <WmsQuantityCell row={params.row} />,
  },
  {
    headerName: 'Customer',
    field: 'wms_status.customers.0',
    groupable: true,
    width: 50,
    valueGetter: customerValueGetter,
    groupingValueGetter: customerValueGetter,
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <TooltipedCell value={params.formattedValue} />
      ),
  },
  {
    headerName: 'WMS date',
    field: 'wms_status.changed_at',
    minWidth: 130,
    type: 'dateTime',
    valueGetter: wmsDateValueGetter,
    valueFormatter: dateTimeValueFormatter,
    groupingValueGetter: wmsDateGroupingValueGetter,
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <TooltipedCell value={params.formattedValue} />
      ),
    filterOperators: customGridDateOperators,
  },
  {
    headerName: 'Content found',
    field: 'verity_status.state',
    groupable: false,
    minWidth: 170,
    valueGetter: contentFoundValueGetter,
    renderCell: (params) => <ContentFoundCell row={params.row} />,
  },
  {
    headerName: 'Should be at',
    field: 'shouldBeAt',
    groupable: false,
    valueGetter: shouldBeAtValueGetter,
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && <ContentShouldBeAtCell row={params.row} />,
  },
  {
    headerName: 'Found date',
    field: 'verity_status.collected_at',
    minWidth: 140,
    type: 'dateTime',
    valueGetter: foundDateValueGetter,
    valueFormatter: dateTimeValueFormatter,
    groupingValueGetter: foundDateGroupingValueGetter,
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <TooltipedCell value={params.formattedValue} />
      ),
    filterOperators: customGridDateOperators,
  },
  {
    headerName: 'First found on',
    field: 'issues.0.first_found_on',
    minWidth: 130,
    type: 'dateTime',
    valueGetter: firstFoundOnValueGetter,
    valueFormatter: dateTimeValueFormatter,
    groupingValueGetter: firstFoundOnGroupingValueGetter,
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <TooltipedCell value={params.formattedValue} />
      ),
    filterOperators: customGridDateOperators,
  },
  {
    headerName: 'Issue',
    field: 'issues.0.type',
    minWidth: 120,
    valueGetter: issueValueGetter,
    groupingValueGetter: issueValueGetter,
    filterOperators: [...customGridStringOperators, notContainsOperator, isNotAnyOfOperator],
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <IssueCell issueType={(params.formattedValue as AllIssueTypes) || '-'} />
      ),
  },
  {
    headerName: 'Issue status',
    field: 'issues.0.state',
    minWidth: 90,
    valueGetter: issueStatusValueGetter,
    groupingValueGetter: issueStatusValueGetter,
    filterOperators: [...customGridStringOperators, notContainsOperator, isNotAnyOfOperator],
    renderCell: (params: GridCellParams<any>) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <IssueStateCell state={params.formattedValue as ISSUE_STATE} />
      ),
  },
];

export const columns: GridColDef[] = specColumns.map((col) => ({
  ...col,
  aggregable: false,
  headerClassName: 'datagrid-header',
  align: 'center',
  flex: 1,
  filterOperators: col.filterOperators ?? customGridStringOperators,
}));
