import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useCallback, useEffect, useState } from 'react';
import { VERITY_STATES, WMS_STATES } from 'common/slotStates';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { getSanitizedInputValues } from 'common/functions/otherFunctions';
import { useRequestController } from 'hooks';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { locationStyles } from '../../styles';
import { AmendValuesSaveButton } from './AmendValuesSaveButton';
import { SelectContentCorrect } from './SelectContentCorrect';
import { SelectOverwriteContentType } from './SelectOverwriteContentType';
import { SelectOverwriteMode } from './SelectOverwriteMode';
import { IOverwritingItem, OVERWRITE_MODES } from '../AmendValues.model';
import { overwriteVerityValue } from './utils/overwriteVerityValue';
import { validateFormData } from './utils/validateFormData';

type AmendValuesOverrideFormProps = {
  locationData: ILocationData;
  currentLocationIndex: number;
  setSpinnerLoading: (value: React.SetStateAction<boolean>) => void;
  refreshLocationData: (locationLabel: string) => void;
  setDidUserOverwrite: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AmendValuesOverrideForm = ({
  locationData,
  currentLocationIndex,
  setSpinnerLoading,
  refreshLocationData,
  setDidUserOverwrite,
}: AmendValuesOverrideFormProps) => {
  const { classes } = locationStyles();

  const [overwriteMode, setOverwriteMode] = useState('');
  const [overwriteVerityCorrect, setOverwriteVerityCorrect] = useState('');
  const [overwriteContentType, setOverwriteContentType] = useState('');
  const [overwriteBarcodes, setOverwriteBarcodes] = useState<string>('');
  const [overwriteComment, setOverwriteComment] = useState('');
  const { requestController } = useRequestController('Amend Values');
  const { stateFacilityLevel } = useFacilityLevelStore();
  const { currentSystemId: systemId } = useFacilityLevelStore().stateFacilityLevel;

  const clearFormData = useCallback(() => {
    setOverwriteVerityCorrect('');
    setOverwriteMode('');
    setOverwriteContentType('');
    setOverwriteBarcodes('');
    setOverwriteComment('');
  }, [
    setOverwriteVerityCorrect,
    setOverwriteMode,
    setOverwriteContentType,
    setOverwriteBarcodes,
    setOverwriteComment,
  ]);

  useEffect(() => {
    clearFormData();
  }, [clearFormData, currentLocationIndex]);

  const handleSubmit = useCallback(() => {
    setSpinnerLoading(true);

    const barcodes = getSanitizedInputValues(overwriteBarcodes);

    const overwritingItem: IOverwritingItem = {
      verity_correct: locationData.userOverride ? false : overwriteVerityCorrect,
      set_to_expected: overwriteMode === OVERWRITE_MODES.USE_WMS,
      barcodes: overwriteContentType === VERITY_STATES.BARCODE ? barcodes : [],
      state: overwriteContentType || locationData.verityState,
      comment: overwriteComment,
    };

    const locationLabel = locationData.location;
    const locationVersion = locationData.version;

    overwriteVerityValue({
      systemId: systemId!,
      requestController,
      overwritingItem,
      location: locationLabel,
      version: locationVersion,
      callbackSuccess: () => {
        refreshLocationData(locationLabel);
        clearFormData();
        setDidUserOverwrite(true);
      },
      callbackError: () => {
        setSpinnerLoading(false);
      },
    });
  }, [
    clearFormData,
    locationData.location,
    locationData.userOverride,
    locationData.verityState,
    locationData.version,
    overwriteBarcodes,
    overwriteComment,
    overwriteContentType,
    overwriteMode,
    overwriteVerityCorrect,
    refreshLocationData,
    requestController,
    setDidUserOverwrite,
    setSpinnerLoading,
    systemId,
  ]);

  const validateAndSubmitFormData = useCallback(() => {
    const {
      barcode_min_length: barcodeMinLength,
      barcode_max_length: barcodeMaxLength,
      barcode_invalid_lengths: barcodeInvalidLengths,
    } = stateFacilityLevel.facilitySettings;

    const isValid = validateFormData({
      barcodeMinLength,
      barcodeMaxLength,
      barcodeInvalidLengths,
      overwriteContentType: overwriteContentType as VERITY_STATES,
      overwriteBarcodes,
    });

    if (isValid) {
      handleSubmit();
    }
  }, [handleSubmit, overwriteBarcodes, overwriteContentType, stateFacilityLevel.facilitySettings]);

  // Change form content based on the overwrite mode selected by the user
  useEffect(() => {
    switch (overwriteMode) {
      case OVERWRITE_MODES.MANUAL:
        setOverwriteContentType('');
        setOverwriteBarcodes('');
        break;

      case OVERWRITE_MODES.USE_VERITY:
        setOverwriteContentType(locationData.verityState);
        setOverwriteBarcodes(
          locationData.verityState === VERITY_STATES.BARCODE ? locationData.verityValue : '',
        );
        break;

      case OVERWRITE_MODES.USE_WMS:
        setOverwriteContentType(locationData.wmsState || '');
        setOverwriteBarcodes(
          locationData.wmsState === WMS_STATES.BARCODE ? locationData.wmsValue : '',
        );
        break;
      default:
        setOverwriteContentType('');
        setOverwriteBarcodes('');
        break;
    }
  }, [overwriteMode, locationData]);

  return (
    <Accordion className="customAccordion">
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          align="right"
          className={classes.accordionTitle}
          color="textSecondary"
          component="p"
        >
          Edit content found
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Box
          display="flex"
          component="div"
          flexDirection="column"
          className={classes.formWrapper}
          style={{ maxWidth: '100%' }}
        >
          <div>
            {!locationData.userOverride && (
              <SelectContentCorrect
                overwriteVerityCorrect={overwriteVerityCorrect}
                setOverwriteVerityCorrect={setOverwriteVerityCorrect}
              />
            )}

            <SelectOverwriteMode
              locationData={locationData}
              overwriteMode={overwriteMode}
              setOverwriteMode={setOverwriteMode}
              overwriteVerityCorrect={overwriteVerityCorrect}
            />

            <SelectOverwriteContentType
              locationData={locationData}
              overwriteMode={overwriteMode}
              overwriteContentType={overwriteContentType}
              setOverwriteContentType={setOverwriteContentType}
            />
            {overwriteContentType === VERITY_STATES.BARCODE && (
              <FormControl className={classes.formControl} variant="outlined">
                <TextField
                  disabled={overwriteMode !== 'manually'}
                  label={`Enter barcodes for ${locationData.location}`}
                  variant="outlined"
                  value={overwriteBarcodes}
                  onChange={(e) => setOverwriteBarcodes(e.target.value)}
                />
              </FormControl>
            )}

            <FormControl className={classes.formControl} variant="outlined">
              <TextField
                disabled={overwriteMode === ''}
                label="Why are you amending this value?"
                multiline
                rows={1}
                variant="outlined"
                value={overwriteComment}
                onChange={(e) => setOverwriteComment(e.target.value)}
              />
            </FormControl>

            <AmendValuesSaveButton
              locationData={locationData}
              validateAndSubmitFormData={validateAndSubmitFormData}
              overwriteMode={overwriteMode}
              overwriteContentType={overwriteContentType}
              overwriteVerityCorrect={overwriteVerityCorrect}
              overwriteBarcodes={overwriteBarcodes}
            />
          </div>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
