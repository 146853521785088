import { useState, useEffect, useCallback } from 'react';

import MicrosoftIcon from '@mui/icons-material/Microsoft';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import { USER_MANAGEMENT_ACTIONS } from 'common/Actions/actionTypes';
import EnhancedTable from 'components/EnhancedTable';

import { IUserResponseSTStatusEnum } from 'codegen/user_admin';

import PageHeaderSection from 'components/Page/PageHeaderSection';
import UserStore from '../../../store/UserStore';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import { FacilityModalsActionTypes } from '../../../store/Modals/types';
import { useFacilityModalsStore } from '../../../store/Modals';
import { useSettingsStore } from '../../../store/Settings/settingsStore';
import { useUserLevelStore } from '../../../store/UserLevelStore/userLevelStore';

import { useRequestController } from '../../../hooks';
import { IUserST } from '../../../interfaces/userInterfaces';

const Users = () => {
  const { systemId } = useParams();

  const { USER_MANAGEMENT_COLUMNS } = useSettingsStore();

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { stateFacilityLevel } = useFacilityLevelStore();
  const { stateUserLevel } = useUserLevelStore();
  const { dispatchFacilityModals } = useFacilityModalsStore();

  const { requestController } = useRequestController('Users');

  const refreshData = { refreshData: () => getAllUsers() };

  const usersData = {
    title: 'Users',
    subtitle: `Here is a list of all the users associated with ${stateFacilityLevel.facilityData?.client}`,
    headCells: USER_MANAGEMENT_COLUMNS || [],
    action: {
      label: 'Add user',
      action: () =>
        dispatchFacilityModals({
          type: FacilityModalsActionTypes.ADD_OR_EDIT_USER,
          payload: {},
          refreshData,
        }),
    },
  };

  const handleEditDeleteUser = (row: IUserST, action: FacilityModalsActionTypes) =>
    dispatchFacilityModals({
      type: action,
      payload: row,
      refreshData,
    });

  const getAllUsers = useCallback(() => {
    const { signal } = requestController.reserveSlotForRequest();

    requestController.doRequest({
      request: UserStore.getAllUsers,
      requestParams: [systemId, signal],
      callbackBeforeSend: () => setIsLoading(true),
      callbackSuccess: (response) => {
        setUsers(response);

        const usersEmails = response.map((user: IUserST) => ({
          email: user.email,
          username: `${user.given_name} ${user.family_name}`,
        }));

        dispatchFacilityModals({
          type: FacilityModalsActionTypes.ALL_USER_EMAILS,
          payload: usersEmails,
        });
      },
      messageErrorFallback: `Failed to get the user list for the facility with id ${systemId}`,
      callbackFinally: () => setIsLoading(false),
    });
  }, [dispatchFacilityModals, requestController, systemId]);

  useEffect(getAllUsers, [getAllUsers]);

  const usersWithActions = users.map((user: IUserST) => ({
    ...user,
    status_icon:
      user.status === IUserResponseSTStatusEnum.ExternalProvider ? <MicrosoftIcon /> : '',
    actions: {
      actions: [
        {
          label: USER_MANAGEMENT_ACTIONS.EDIT.action,
          disabled: false,
          onClick: () => handleEditDeleteUser(user, FacilityModalsActionTypes.ADD_OR_EDIT_USER),
        },
        {
          label: USER_MANAGEMENT_ACTIONS.DELETE.action,
          disabled: stateUserLevel.usernameHashed === user.username,
          onClick: () => handleEditDeleteUser(user, FacilityModalsActionTypes.DELETE_USER),
        },
      ],
    },
  }));

  return (
    <>
      <PageHeaderSection title="Administration" showMenuIcon={false} showLoadedSince={false} />

      <Container maxWidth="xl">
        <Grid direction="column" container className="c-page-content">
          <EnhancedTable
            tableFor={usersData.title}
            tableTitle={usersData.title}
            tableSubtitle={usersData.subtitle}
            headCells={usersData.headCells}
            rows={usersWithActions}
            isLoading={isLoading}
            refreshData={{ refreshData: getAllUsers }}
            currentActiveSpinner={{ Users: isLoading }}
            headerButton={usersData.action}
          />
        </Grid>
      </Container>
    </>
  );
};

export default Users;
