/**
 * Generate a tooltip string based on the location info (in particular on the
 * selected tab and the search term)
 * @param locationInfo Location Info
 * @returns a tooltip string
 */
export const getNavigationButtonsTooltip = (locationInfo: {
  tab?: string;
  searchTerm?: string;
}) => {
  let tooltip = '';

  if (locationInfo.tab) {
    tooltip = `from ${locationInfo.tab} tab`;
  }
  if (locationInfo.searchTerm) {
    tooltip = [tooltip, `filtered by term "${locationInfo.searchTerm}"`].join(' ');
  }
  return tooltip;
};
