import { isArray } from 'lodash';
import { Box, Typography, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ClearIcon from '@mui/icons-material/Clear';
import Badge from 'components/common/Badge';
import { ISSUE_BADGE_COLORS, ISSUE_BADGE_COLORS_KEYS } from 'common/colors';
import { DISPLAY_ISSUE_TYPES_MESSAGE } from 'common/issueTypesAndStates';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { NavigationButtons } from '../navigation/NavigationButtons';
import { locationStyles } from '../styles';

type LocationModalHeaderProps = {
  locationData: ILocationData;
  allLocations: ILocationData[];
  title: string;
  handleCloseModal: () => void;
  currentLocationData: any;
  setCurrentLocationData: React.Dispatch<any>;
  refreshLocationData: (locationLabel: string) => void;
  currentLocationIndex: number;
  setCurrentLocationIndex: React.Dispatch<React.SetStateAction<number>>;
  NavigationButtonsTooltip: string;
};

export const LocationModalHeader = ({
  locationData,
  allLocations,
  title,
  handleCloseModal,
  currentLocationData,
  setCurrentLocationData,
  refreshLocationData,
  currentLocationIndex,
  setCurrentLocationIndex,
  NavigationButtonsTooltip,
}: LocationModalHeaderProps) => {
  const { classes } = locationStyles();

  const showNavigation = isArray(allLocations) && allLocations.length > 1;
  return (
    <>
      <Box component="div" textAlign="left" p={3}>
        <Box component="div" display="flex" alignItems="center">
          <Badge
            bgcolor={
              ISSUE_BADGE_COLORS[(locationData?.state as ISSUE_BADGE_COLORS_KEYS) || 'NONE']
                ?.background
            }
            color={
              ISSUE_BADGE_COLORS[(locationData?.state as ISSUE_BADGE_COLORS_KEYS) || 'NONE']?.text
            }
            bigBadge={false}
          >
            {(currentLocationData?.state && `${currentLocationData.state} ISSUE`) || 'NO ISSUE'}
          </Badge>
          <Typography
            className={classes.modalTitle}
            color="secondary"
            variant="h5"
            data-testid="title"
          >
            {title}
          </Typography>
          <IconButton
            aria-label="refresh"
            onClick={() => refreshLocationData(locationData?.location)}
            size="large"
          >
            <RefreshIcon />
          </IconButton>
        </Box>
        <Box component="div" display="flex" className={classes.subtitleAndNavigation}>
          <Typography style={{ fontWeight: 400 }} color="textSecondary" variant="h6">
            {DISPLAY_ISSUE_TYPES_MESSAGE[locationData.issueType] || 'No issue at this location'}
          </Typography>
          {showNavigation && (
            <NavigationButtons
              locationsData={allLocations}
              setCurrentLocationData={setCurrentLocationData}
              currentLocationIndex={currentLocationIndex}
              setCurrentLocationIndex={setCurrentLocationIndex}
              tooltip={NavigationButtonsTooltip}
            />
          )}
        </Box>
      </Box>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={handleCloseModal}
        data-testid="c-close-modal-btn"
        size="large"
      >
        <ClearIcon />
      </IconButton>
    </>
  );
};
