import { useCallback, useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Paper, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { ImageCarouselContainer } from 'components/common/ImageCarousel/ImageCarouselContainer';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import IssuesStore from 'store/IssuesStore';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { singleRequestHandler } from 'common/requestHelpers';
import { locationStyles } from '../styles';

export const ImageCarousel = (props: {
  locationData: ILocationData;
  currentLocationData: any;
  systemId: string;
}) => {
  const { systemId, locationData, currentLocationData } = props;
  const { location, verityState, slotStatusVersion, isReview } = locationData;
  const [locationImageURLs, setLocationImageURLs] = useState([]);
  const [carouselSpinner, setCarouselSpinner] = useState(false);
  const [imageAvailabilityMessage, setImageAvailabilityMessage] = useState('');
  const { stateFacilityLevel } = useFacilityLevelStore();

  const { classes } = locationStyles();

  const getSlotImages = useCallback(() => {
    if (currentLocationData.slotStatusVersion !== '-') {
      singleRequestHandler({
        request: IssuesStore.getImages,
        requestParams: [
          systemId,
          currentLocationData.location,
          currentLocationData.slotStatusVersion,
          currentLocationData.isReview,
        ],
        dispatcher: enqueueSnackbar,
        callbackBeforeSend: () => {
          setCarouselSpinner(true);
        },
        callbackSuccess: (r: { data: { urls: any; num_requested: any } }) => {
          setLocationImageURLs(() => (r.data?.urls ? r.data?.urls : []));
          const numPendingImages = r.data?.num_requested ? r.data?.num_requested : 0;
          const numCurrentlyAvailableImages = r.data?.urls ? r.data?.urls.length : 0;

          if (numCurrentlyAvailableImages === 0 && numPendingImages === 0) {
            setImageAvailabilityMessage('There are no images available for this location.');
          } else if (numPendingImages > 0) {
            // We don't explicity tell the user how many pictures will be uploaded, because pending image requests may fail.
            // The client user does not need to know that some requests failed, as long as they don't all fail.
            // Exposing this number could result in a user expecting 3 pictures, and seeing the counter go down as their
            // requests fail, which would cause a lot of confusion.

            setImageAvailabilityMessage('Waiting for images to be uploaded.');
          } else {
            setImageAvailabilityMessage('');
          }

          // TODO::TR::2021-08-19:: Design a way to display the 3 counters to the verity users:
          // numFailedImages, numPendingImages, numCurrentlyAvailableImages
          // const numFailedImages = r.data?.num_failed ? r.data?.num_failed : 0;
        },
        callbackFinally: () => {
          setCarouselSpinner(false);
        },
      });
    } else {
      setLocationImageURLs(() => []);
    }
  }, [
    currentLocationData.isReview,
    currentLocationData.location,
    currentLocationData.slotStatusVersion,
    systemId,
  ]);

  useEffect(() => {
    getSlotImages();
  }, [getSlotImages, currentLocationData]);

  useEffect(() => {
    if (
      stateFacilityLevel.inventory.imageAvailable?.data?.image_id &&
      currentLocationData.imageIds.includes(
        stateFacilityLevel.inventory.imageAvailable.data.image_id,
      )
    ) {
      getSlotImages();
    }
  }, [stateFacilityLevel.inventory.imageAvailable, currentLocationData.imageIds, getSlotImages]);

  if (!isEmpty(locationImageURLs)) {
    return (
      <Paper className={classes.paper} elevation={3}>
        <ImageCarouselContainer
          systemId={systemId!}
          location={location}
          isLoading={carouselSpinner}
          imageURLs={locationImageURLs}
          slotStatusVersion={slotStatusVersion}
          isReview={isReview}
          imageNavigationOnTop={true}
          keyboardNavigation="vertical"
        />
      </Paper>
    );
  }
  const displayText =
    verityState === '-'
      ? 'Images are not available because Verity has no data for this location.'
      : imageAvailabilityMessage;

  return (
    <Paper className={classes.paper} elevation={3}>
      <div className={classes.imageContainer}>
        <Typography
          className={classes.imageContainerText}
          color="textSecondary"
          align="center"
          variant="h6"
        >
          {displayText}
        </Typography>
      </div>
    </Paper>
  );
};
