import { useEffect } from 'react';
import { pickSort } from 'common/functions/otherFunctions';
import { NoFlyZone } from 'shared/map/model/no-fly-zone.model';
import { BackDropLoader } from '../../../../../pages/GroundControl/pages/Schedule/ScheduleLock/BackDropLoader';
import { noFlyZoneListStyle } from './NoFlyZoneList.style';
import { NoFlyZonesListItem } from './components/NoFlyZonesListItem/NoFlyZonesListItem';
import { DroneZonePlaceholder } from '../DroneZonePlaceholder/DroneZonePlaceholder';
import { NoFlyZonesProps } from './NoFlyZonesList.model';
import { zoneElementFromSelectedZone } from '../helpers/zoneElementFromSelectedZone';
import { useDroneZoneContext } from '../../reducer/DroneZonePageContext';

export const NoFlyZonesList = ({
  zones = {},
  onSelect,
  systemId,
  flightDomainId,
  isLoading,
  isSystemLocked,
  areActionsEnabled,
}: NoFlyZonesProps) => {
  const { classes, cx } = noFlyZoneListStyle();
  const { dispatchDroneZone } = useDroneZoneContext();

  const focusedZoneElement = zoneElementFromSelectedZone(zones);

  useEffect(() => {
    focusedZoneElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [focusedZoneElement]);

  const sortedZones = pickSort(
    Object.values(zones).map((i) => i),
    'asc',
    (o) => o.name.toLowerCase(),
  );

  const handleCloneNoFlyZone = (noFlyZone: NoFlyZone) =>
    dispatchDroneZone({
      type: 'SHOW_NO_FLY_ZONE_CREATION_FORM',
      payload: noFlyZone,
    });
  const handleEditNoFlyZone = (noFlyZone: NoFlyZone) =>
    dispatchDroneZone({
      type: 'START_EDITING_NO_FLY_ZONE',
      payload: noFlyZone,
    });
  const handleDeleteNoFlyZone = (noFlyZone: NoFlyZone) =>
    dispatchDroneZone({ type: 'SHOW_NO_FLY_ZONE_DELETION_MODAL', payload: noFlyZone });

  const isListVisible = !isLoading && !!sortedZones.length;
  const isPlaceholderVisible = !isLoading && !sortedZones.length;
  const isOverlayVisible = isLoading || !isSystemLocked;

  return (
    <div className={classes.wrapper}>
      <BackDropLoader isVisible={isOverlayVisible} showLoader={isLoading} />

      {isListVisible && (
        <ul className={cx(classes.wrapperBody, classes.zoneListWrapper)}>
          {sortedZones.map((nfz) => (
            <NoFlyZonesListItem
              key={nfz.id}
              droneZone={nfz}
              systemId={systemId}
              flightDomainId={flightDomainId}
              onSelect={onSelect}
              onEdit={handleEditNoFlyZone}
              onDelete={handleDeleteNoFlyZone}
              onClone={handleCloneNoFlyZone}
              isSystemLocked={isSystemLocked}
              areActionsEnabled={areActionsEnabled}
            />
          ))}
        </ul>
      )}

      {isPlaceholderVisible && (
        <div className={classes.wrapperBody}>
          <DroneZonePlaceholder message='There are no "no-fly zones"' />
        </div>
      )}
    </div>
  );
};
