import { Paper } from '@mui/material';

import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { locationStyles } from '../styles';
import { AmendValuesNoOverride } from './AmendValuesNoOverride';
import { AmendValuesOverrideForm } from './override-form/AmendValuesOverrideForm';

type AmendValuesProps = {
  locationData: ILocationData;
  setSpinnerLoading: (value: React.SetStateAction<boolean>) => void;
  refreshLocationData: (locationLabel: string) => void;
  setDidUserOverwrite: React.Dispatch<React.SetStateAction<boolean>>;
  currentLocationIndex: number;
};

export const AmendValues = ({
  locationData,
  currentLocationIndex,
  setSpinnerLoading,
  refreshLocationData,
  setDidUserOverwrite,
}: AmendValuesProps) => {
  const { classes } = locationStyles();
  const { facilitySettings } = useFacilityLevelStore().stateFacilityLevel;
  const showAmendForm = facilitySettings.allow_user_amend || false;

  return (
    <Paper className={classes.paper} elevation={3}>
      {showAmendForm && locationData.verityState === '-' && <AmendValuesNoOverride />}
      {showAmendForm && locationData.verityState !== '-' && (
        <AmendValuesOverrideForm
          currentLocationIndex={currentLocationIndex}
          locationData={locationData}
          setSpinnerLoading={setSpinnerLoading}
          refreshLocationData={refreshLocationData}
          setDidUserOverwrite={setDidUserOverwrite}
        />
      )}
    </Paper>
  );
};
