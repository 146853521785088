import { Alert, Box } from '@mui/material';

export const DomainSwitchMessage = () => (
  <Alert sx={{ fontSize: 'large', width: '50em' }} severity="info" key={0}>
    <Box component="div" padding="1em">
      <Box component="div">
        The Verity Dashboard will switch domains from <b>verity.ch</b> to <b>verity-app.net</b>.
      </Box>
      <Box component="div" marginTop={3}>
        Your browser will automatically redirect you to the new domain if you try to access the
        Verity Dashboard via the previous domain.
      </Box>
    </Box>
  </Alert>
);

export const ArrivedFromOldDomainMessage = () => (
  <Alert sx={{ fontSize: 'large', width: '50em' }} severity="info" key={0}>
    <Box component="div" padding="1em">
      <Box component="div">
        You are connecting from the old domain <b>verity.ch</b>, please update your bookmarks to{' '}
        <b>verity-app.net</b>.
      </Box>
    </Box>
  </Alert>
);
