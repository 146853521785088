import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';

import GetAppIcon from '@mui/icons-material/GetApp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import { Box } from 'components/common/Box';
import TooltipedIcon from 'components/common/TooltipedIcon';
import Alert from 'components/common/Alert';
import ActionsMenu from 'components/common/ActionsMenu';
import Spinner from 'components/common/Spinner';
import SkeletonPageHeader from 'components/Skeletons/SkeletonPageHeader';

import { loadSinceInterval } from 'common/functions/dateTimeFunctions';
import { SelectTimeInterval } from './select-time-interval/SelectTimeInterval';
import { PageHeaderSectionTitle } from './section-title/PageHeaderSectionTitle';

import { useClientLevelStore } from '../../store/ClientLevelStore/clientLevelStore';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';

import { pageHeaderStyles } from './styles';

import { useHideOnScroll } from '../../hooks/useHideOnScroll';
import { PageHeaderBreadcrumbs } from './PageHeaderBreadcrumbs';
import { intervalSelectorType } from './select-time-interval/selectTimeInterval.model';

type PageHeaderSectionProps = {
  title: string;
  subtitle?: string;
  showMenuIcon?: boolean;
  headerActions?: any;
  showLoadedSince?: boolean;
  showDownloadBtn?: boolean;
  downloadSpinning?: boolean;
  onClickDownload?: (...args: any) => void;
  defaultTimeInterval?: loadSinceInterval | intervalSelectorType;
  showFacilityName?: boolean;
  getDataFrom?: (...args: any) => void;
  enableIntervalSelectors?: boolean;
  customBtn?: JSX.Element | null;
  isReportPage?: boolean;
  isSchedulingPage?: boolean;
  isGroundControlPage?: boolean;
  alert?: any;
};

const PageHeaderSection = ({
  title,
  subtitle,
  showMenuIcon,
  headerActions,
  showLoadedSince = false,
  showDownloadBtn,
  downloadSpinning,
  onClickDownload,
  defaultTimeInterval = 'all',
  showFacilityName = true,
  getDataFrom,
  enableIntervalSelectors = true,
  customBtn,
  isReportPage = false,
  isSchedulingPage = false,
  isGroundControlPage = false,
  alert,
}: PageHeaderSectionProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const stickyElement = useHideOnScroll({ threshold: 50 });
  const { stateClientLevel } = useClientLevelStore();
  const { stateFacilityLevel } = useFacilityLevelStore();

  const { classes } = pageHeaderStyles();

  const { systemId } = useParams();

  const currentFacility = stateClientLevel.facilityList.find(
    (facility) => facility.id === systemId,
  );
  const currentFacilityName = currentFacility?.name || '';

  const haveMultipleFlightDomains = stateFacilityLevel.flightDomains.length > 1;

  // eslint-disable-next-line consistent-return
  const getTopValue = useCallback(() => {
    if (isGroundControlPage) {
      return stickyElement ? 60 : 120;
    }
    return stickyElement ? 0 : 60;
  }, [isGroundControlPage, stickyElement]);

  return (
    <Container
      maxWidth="xl"
      sx={{
        zIndex: 1100,
        top: getTopValue(),
        pt: 2,
        position: 'sticky',
        background: '#fff',
        transition: theme.transitions.create(['top', 'margin', 'padding', 'background', 'border'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginBottom: '6px',
      }}
      data-testid="c-page-header"
    >
      <Container disableGutters={true} maxWidth="xl">
        <Box
          width="100%"
          sx={{
            paddingBottom: theme.spacing(2),
            transition: theme.transitions.create(['top', 'margin', 'padding', 'background'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
        >
          {title || subtitle ? (
            <Box className={classes.topHeader} display="flex">
              <Box alignItems="flex-end" display="flex">
                <Box pb={2}>
                  <Box>
                    <PageHeaderBreadcrumbs
                      navigate={navigate}
                      title={title}
                      facilityName={currentFacilityName}
                      systemId={systemId || ''}
                      isReportPage={isReportPage}
                      multipleFacilitiesAccess={stateClientLevel.isMultiFacility || false}
                      multipleFlightDomains={haveMultipleFlightDomains}
                    />
                    <PageHeaderSectionTitle
                      title={title}
                      currentFacilityName={currentFacilityName}
                      isMultiFacility={stateClientLevel.isMultiFacility || false}
                      showFacilityName={showFacilityName}
                    />
                  </Box>
                  <Box height="auto" display="flex" alignItems="flex-start">
                    <Typography
                      data-testid="c-overview-subtitle"
                      className={classes.subtitle}
                      color="textSecondary"
                      sx={{ fontWeight: 400 }}
                      variant="h6"
                    >
                      {subtitle}
                    </Typography>
                    {showLoadedSince && (
                      <SelectTimeInterval
                        enableIntervalSelectors={enableIntervalSelectors}
                        defaultTimeInterval={defaultTimeInterval}
                        getDataFrom={getDataFrom!}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box className={classes.searchAndMenu}>
                {customBtn}
                {showDownloadBtn && (
                  <TooltipedIcon
                    tooltip="Download"
                    icon={
                      <IconButton
                        onClick={onClickDownload}
                        aria-label="page-header-download-button"
                        size="large"
                      >
                        <GetAppIcon color="secondary" />
                        {downloadSpinning && <Spinner />}
                      </IconButton>
                    }
                  />
                )}
                {showMenuIcon && (
                  <TooltipedIcon
                    tooltip="Menu"
                    icon={
                      <IconButton size="large">
                        <ActionsMenu isTable={false} options={headerActions} />
                      </IconButton>
                    }
                  />
                )}
              </Box>
            </Box>
          ) : (
            <SkeletonPageHeader />
          )}
          <Box style={{ position: 'relative' }} mb={2}>
            {alert && alert.state.show && (
              <Alert
                handleClose={() => alert.close(alert.label)}
                title={alert.state.title}
                message={alert.state.message}
                status={alert.state.status}
                isActive={true}
                preventClose={alert.state.preventClose}
              />
            )}
          </Box>

          {isSchedulingPage && (
            <Button
              sx={{ mt: 2 }}
              onClick={() => navigate(`/${systemId}${INVENTORY_PAGES_URLS.REPORTS}`)}
              startIcon={<ArrowBackIcon />}
              variant="outlined"
              color="primary"
            >
              Back to reports
            </Button>
          )}
        </Box>
      </Container>
    </Container>
  );
};

export default PageHeaderSection;
