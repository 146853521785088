import { useState } from 'react';
import { Paper, Typography } from '@mui/material';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { LoadingSwitch } from 'components/common/LoadingSwitch';
import ActionsMenu from 'components/common/ActionsMenu';
import GroundControlServices from '../../../../services/GroundControlServices';
import { INoFlyZoneItemProps } from '../model/INoFlyZoneItem.model';
import { noFlyZoneNameToSelectedId } from '../utils/noFlyZoneNameToSelectedId';
import { useRequestController } from '../../../../hooks';
import { NEW_DRONE_ZONE_ID } from '../../drone-zones/features/Map/defaults/newDroneZoneId';
import { noFlyZoneItemStyle } from '../style/noFlyZoneItem.style';

const logPrefix: string = getLogPrefixForType('PAGE', 'NoFlyZonesItem');

export const NoFlyZoneItem = ({
  noFlyZone,
  onDelete,
  onEdit,
  onClone,
  onSelect,
  systemId,
  flightDomainId,
  isDisabled,
}: INoFlyZoneItemProps) => {
  const { requestController } = useRequestController(logPrefix);
  const { classes } = noFlyZoneItemStyle();
  const menuOptions = [
    { label: 'Edit', clickHandler: () => onEdit(noFlyZone) },
    {
      label: 'Clone',
      clickHandler: () =>
        onClone({ ...noFlyZone, id: NEW_DRONE_ZONE_ID, name: `${noFlyZone.name} (Copy)` }),
    },
    { label: 'Delete', clickHandler: () => onDelete(noFlyZone) },
  ];

  const [isLoading, setIsLoading] = useState(false);

  const setNFZActive = () => {
    requestController.doRequest({
      request: GroundControlServices.setNoFlyZoneActive,
      callbackBeforeSend: () => setIsLoading(true),
      callbackFinally: () => setIsLoading(false),
      requestParams: [systemId, flightDomainId, noFlyZone.id, noFlyZone.isActive],
    });
  };

  const handleSelect = () => onSelect(noFlyZone);

  const nfzName = noFlyZoneNameToSelectedId(noFlyZone.name);
  const className = noFlyZone.isSelected
    ? `${classes.paper} ${classes.paperActive}`
    : classes.paper;

  return (
    <Paper elevation={3} className={className} id={nfzName}>
      <LoadingSwitch
        aria-label={
          noFlyZone.isActive
            ? `Deactivate drone no-fly zone "${noFlyZone.name}"`
            : `Activate drone no-fly zone "${noFlyZone.name}"`
        }
        isLoading={isLoading}
        isDisabled={isDisabled}
        isChecked={noFlyZone.isActive}
        onChange={setNFZActive}
      />
      <button type="button" className={classes.details} onClick={handleSelect}>
        <Typography variant="h6">{noFlyZone.name}</Typography>
      </button>

      <ActionsMenu options={menuOptions} />
    </Paper>
  );
};
