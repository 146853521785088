import React, { useEffect } from 'react';
import { LicenseInfo } from '@mui/x-license';
import { Routes, Route, useLocation } from 'react-router-dom';
import activeRequests from 'active-requests';
import { ModalsAndPopupsClientLevel } from './components/ModalsAndPopups/ModalsAndPopupsClientLevel';
import { ModalsAndPopupsFacilityLevel } from './components/ModalsAndPopups/ModalsAndPopupsFacilityLevel';
import { ModalsAndPopupsGroundControlLevel } from './components/ModalsAndPopups/ModalsAndPopupGroundControlLevel';
import { AUTH_PAGES_URLS } from './common/pages';
import './App.css';
import ClientRoutes from './routes/ClientRoutes';
import AuthRoutes from './routes/AuthRoutes';
import AuthPage from './pages/Auth/AuthPage';
import { LocalStore } from './common/functions/storageFunctions';
import { useForceUpdate } from './hooks/useForceUpdate';
import { TokenManager } from './common/tokenManager';
import SignOut from './pages/Auth/SignOut';
import WebSocketComponent from './components/WebSocket/WebSocket';
import { WebSocketClient } from './shared/web-socket/web-socket.model';
import { webSocket } from './shared/web-socket/web-socket';
import { initSentry } from './common/sentry';

const tokenManager = TokenManager.getInstance();

LicenseInfo.setLicenseKey(
  'b03f915c37fc3caf802cb5e48626d055Tz02NzYxMCxFPTE3MTcxMzk5ODI0NjcsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);
const websocket: WebSocketClient = webSocket();

const App = () => {
  initSentry();
  const location = useLocation();

  const forceUpdate = useForceUpdate('App');
  LocalStore.setTriggerUpdate(forceUpdate);
  const accessToken = tokenManager.getAccessToken();

  activeRequests.start();

  const deprecatedWebSocket = !tokenManager.userHaveAccess() ? null : <WebSocketComponent />;

  useEffect(() => {
    if (accessToken) {
      websocket.connect();
    }
    return () => {
      websocket.disconnect();
    };
  }, [accessToken]);

  return (
    <div className="App">
      <Routes location={location} key={location.pathname}>
        {/* Authentication pages */}
        <Route path={`${AUTH_PAGES_URLS.ROOT}/*`} element={<AuthRoutes />} />
        {/* Client pages */}
        <Route
          path="/*"
          element={
            accessToken ? (
              <AuthPage>
                <ClientRoutes />
              </AuthPage>
            ) : (
              <SignOut onSignout={() => websocket?.disconnect()} />
            )
          }
        />
      </Routes>

      <ModalsAndPopupsGroundControlLevel />
      <ModalsAndPopupsFacilityLevel />
      <ModalsAndPopupsClientLevel />
      {deprecatedWebSocket}
    </div>
  );
};

export default App;
