import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isTextAreaOrInputActive } from 'common/functions/domFunctions';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { locationStyles } from '../styles';

export const NavigationButtons = (props: {
  locationsData: ILocationData[];
  setCurrentLocationData: React.Dispatch<any>;
  currentLocationIndex: number;
  setCurrentLocationIndex: React.Dispatch<React.SetStateAction<number>>;
  tooltip: string;
}) => {
  const {
    locationsData,
    setCurrentLocationData,
    currentLocationIndex,
    setCurrentLocationIndex,
    tooltip,
  } = props;
  const data = Array.isArray(locationsData) ? locationsData : [locationsData];
  const { classes } = locationStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const goToPreviousLocation = useCallback(
    (locationsData: ILocationData[], currentLocationIndex: number) => {
      // prevent navigation to previous location
      // if user is at first location
      if (currentLocationIndex !== 0) {
        setCurrentLocationData(locationsData[currentLocationIndex - 1]);
        setCurrentLocationIndex(currentLocationIndex - 1);

        searchParams.set('location', locationsData[currentLocationIndex - 1].location);
        setSearchParams(searchParams);
      }
    },
    [searchParams, setCurrentLocationData, setCurrentLocationIndex, setSearchParams],
  );

  const goToNextLocation = useCallback(
    (locationsData: ILocationData[], currentLocationIndex: number) => {
      if (currentLocationIndex !== locationsData.length - 1) {
        setCurrentLocationData(locationsData[currentLocationIndex + 1]);
        setCurrentLocationIndex(currentLocationIndex + 1);

        searchParams.set('location', locationsData[currentLocationIndex + 1].location);
        setSearchParams(searchParams);
      }
    },
    [searchParams, setCurrentLocationData, setCurrentLocationIndex, setSearchParams],
  );

  const navigateToLocation = useCallback(
    (event: KeyboardEvent) => {
      if (isTextAreaOrInputActive()) return;

      const data = Array.isArray(locationsData) ? locationsData : [locationsData];
      if (event.key === 'ArrowLeft') {
        goToPreviousLocation(data, currentLocationIndex);
      } else if (event.key === 'ArrowRight') {
        goToNextLocation(data, currentLocationIndex);
      }
    },
    [currentLocationIndex, locationsData, goToNextLocation, goToPreviousLocation],
  );

  useEffect(() => {
    document.addEventListener('keydown', navigateToLocation);
    return () => {
      document.removeEventListener('keydown', navigateToLocation);
    };
  }, [navigateToLocation]);

  return (
    <Box component="div" alignItems="flex-end" flexDirection="column" display="flex">
      <Typography color="textSecondary" variant="h6" className={classes.navigationButtons}>
        <IconButton
          size="medium"
          disabled={currentLocationIndex === 0}
          onClick={() => goToPreviousLocation(data, currentLocationIndex)}
          data-testid="previous-location-button"
        >
          <NavigateBeforeIcon />
        </IconButton>
        <Tooltip title={tooltip}>
          <Typography data-testid="curr-loc">
            {`${currentLocationIndex + 1} of ${data.length}`}
          </Typography>
        </Tooltip>

        <IconButton
          size="medium"
          disabled={currentLocationIndex === data.length - 1}
          onClick={() => goToNextLocation(data, currentLocationIndex)}
          data-testid="next-location-button"
        >
          <NavigateNextIcon />
        </IconButton>
      </Typography>
    </Box>
  );
};
