import { useState, useCallback } from 'react';
import { useNavigate, useLocation, useSearchParams, useParams } from 'react-router-dom';

import Container from '@mui/material/Container';

import * as tables from 'common/Tables';
import { asyncGetUploadedFiles } from 'common/functions/otherFunctions';
import { getTimeIntervalDates } from 'common/functions/dateTimeFunctions';
import { changeTab } from 'common/functions/navigationFunctions';
import {
  TIMEFRAME_TO_FETCH_UPLOADED_FILES_FROM,
  SHOW_PAGE_HEADER_MENU_ICON,
} from 'common/settings';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import PageHeaderSection from 'components/Page/PageHeaderSection';
import EnhancedTable from 'components/EnhancedTable';
import { OrderValue } from 'components/EnhancedTable/types/rows';
import { IWMSFilesST } from 'codegen/wms_data';
import { LocalStore } from 'common/functions/storageFunctions';
import { useRequestController } from '../../hooks';

import { useSettingsStore } from '../../store/Settings/settingsStore';

import { UPLOADED_FILES_TYPES } from '../../interfaces';
import { useGetActiveTabData } from './hooks/useGetTabData/useGetTabData';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';

const { warehouseUploadsTabs } = tables;

const logPrefix = getLogPrefixForType('PAGE', 'WarehouseDataUploads');

export const WarehouseDataUploads = () => {
  const { WAREHOUSE_UPLOADS_TABLE_TABS } = useSettingsStore();

  const location = useLocation();

  const [searchParams] = useSearchParams();

  const activeTab = Number(searchParams.get('activeTab')) || 0;

  const [dates, setDates] = useState(
    location.state?.loadSince
      ? { from: location.state?.loadSince.from, until: location.state?.loadSince.until }
      : getTimeIntervalDates(TIMEFRAME_TO_FETCH_UPLOADED_FILES_FROM),
  );

  const [snapshotsSpinner, setSnapshotsSpinner] = useState(0);
  const [stockCheckSpinner, setStockCheckSpinner] = useState(0);
  const [movedLocationsSpinner, setMovedLocationsSpinner] = useState(0);
  const [wmsSnapshotFiles, setWmsSnapshotFiles] = useState<IWMSFilesST[]>([]);
  const [stockCheckFiles, setStockCheckFiles] = useState<IWMSFilesST[]>([]);
  const [movedLocationsFiles, setMovedLocationsFiles] = useState<IWMSFilesST[]>([]);

  const navigate = useNavigate();

  const { systemId } = useParams();
  const timezone = LocalStore.getTimezone();

  const { requestController } = useRequestController(logPrefix);

  const currentTabData = useGetActiveTabData({
    wmsSnapshotFiles,
    stockCheckFiles,
    movedLocationsFiles,
    activeTab,
  });
  const tabs = warehouseUploadsTabs({
    WAREHOUSE_UPLOADS_TABLE_TABS,
    numberOfWMSSnapshotFiles: wmsSnapshotFiles.length,
    numberOfStockCheckFiles: stockCheckFiles.length,
    numberOfMovedLocationsFiles: movedLocationsFiles.length,
  });
  const currentActiveSpinner = {
    'uploaded WMS snapshot files': !!snapshotsSpinner,
    'uploaded Stock check files': !!stockCheckSpinner,
    'uploaded Moved locations files': !!movedLocationsSpinner,
  };
  const isLoading = [snapshotsSpinner, stockCheckSpinner, movedLocationsSpinner].some((l) => l);

  /**
   * Retrieve the list for the given dates: this is triggered by changes
   * in the select interval / dates drop-down
   * @param {*} dates dates/interval the list is retrieved for
   */
  const getDataFrom = (dates: { until?: string; from: string }) => {
    if (!dates.until) {
      delete dates.until;
    }
    requestController.doCancelRequest(
      `${logPrefix}interval changed dates to from: ${dates.from} until: ${dates.until}`,
    );
    console.debug(logPrefix, 'Selection dates changed: ', dates);
    setDates(dates);
    getAllUploadedFiles(dates);
  };

  const getFiles = useCallback(
    /**
     * Get the list of all the files of the given type
     * @param {UPLOADED_FILES_TYPES} fileType
     * @param {*} dates dates / interval the list is retrieved for
     * @param {*} setSpinner function which sets/un-sets the spinner
     * @param {*} setFiles callback to set the file list in the required state property
     */
    async (
      fileType: UPLOADED_FILES_TYPES,
      dates: { from?: string; until?: string },
      setSpinner: (params: (repliesPending: number) => number) => void,
      setFiles: (files: IWMSFilesST[]) => void,
    ) => {
      const reservation = requestController.reserveSlotForRequest();
      await asyncGetUploadedFiles({
        systemId: systemId!,
        fileType,
        params: dates,
        timezone,
        requestController,
        setSpinner,
        requestId: reservation.requestId,
        signal: reservation.signal,
        callBackPerPage: (files) => setFiles(files),
      });
    },
    [requestController, systemId, timezone],
  );

  const getAllUploadedFiles = useCallback(
    /**
     * Retrieves all the list of files
     * @param {*} dates dates / interval the list of files are retrieved for
     */
    (dates: { until?: string; from?: string }) => {
      Promise.all([
        getFiles(UPLOADED_FILES_TYPES.WMSSnapshot, dates, setSnapshotsSpinner, setWmsSnapshotFiles),
        getFiles(UPLOADED_FILES_TYPES.StockCheck, dates, setStockCheckSpinner, setStockCheckFiles),
        getFiles(
          UPLOADED_FILES_TYPES.MovedLocations,
          dates,
          setMovedLocationsSpinner,
          setMovedLocationsFiles,
        ),
      ]);
    },
    [getFiles],
  );

  useComponentDidMount(() => {
    window.scrollTo(0, 0);
    getAllUploadedFiles(dates);
  });

  return (
    <>
      <PageHeaderSection
        title="Warehouse data uploads"
        subtitle="Showing uploaded WMS data. Filter by upload date: "
        showMenuIcon={SHOW_PAGE_HEADER_MENU_ICON.WAREHOUSE_DATA_UPLOADS}
        showLoadedSince={true}
        defaultTimeInterval={TIMEFRAME_TO_FETCH_UPLOADED_FILES_FROM}
        getDataFrom={getDataFrom}
      />
      <Container maxWidth="xl">
        <EnhancedTable
          tableFor={currentTabData.tableFor}
          tableTitle=""
          tableSubtitle=""
          headCells={currentTabData.headCells || []}
          rows={currentTabData.rows}
          tabs={tabs}
          tabStatus={true}
          changeTab={(tabValue) =>
            changeTab({
              tabValue,
              navigate,
              state: location.state,
            })
          }
          startingTab={activeTab}
          currentActiveSpinner={currentActiveSpinner}
          initialOrder={tabs[activeTab]?.order as OrderValue}
          initialOrderBy={tabs[activeTab]?.orderBy}
          isLoading={isLoading}
        />
      </Container>
    </>
  );
};
