import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import last from 'lodash/last';
import {
  convertRruleToUIComponents,
  getParamSpec,
  validateAllParamValues,
  validateEndDate,
  validateReportName,
  validateStartDate,
} from 'common/functionsReportSpec';
import { callSubReducer, notEver } from 'common/functions/otherFunctions';
import { calculateValidIntervalOptions } from 'common/functions/dateTimeFunctions';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { WeekDayShort } from 'common/datetimeFormats';
import { INPUT_MODES, PARAM_TYPES } from 'common/reportSpecifications';
import { RRULE_WEEKDAYS } from 'common/weekDays';
import { IReportSpecificationST } from 'codegen/report_specification/api';
import {
  IInventoryRequestST,
  IInventoryRequestParameterST,
  IReportParameterUniqueNameST,
} from 'codegen/inventory_request/api';
import { ReportInputErrors, ReportParamInputError } from 'interfaces/reportsInterfaces';
import { ReportPriority } from '../models/reportPriority.model';
import { defaultRecurrence } from '../features/Recurrence/recurrence.defaults';
import { IntervalLabel, RecurrenceState } from '../features/Recurrence/recurrence.model';
import { RecurrenceAction, recurrenceReducer } from '../features/Recurrence/recurrence.reducer';

export const lp = getLogPrefixForType('FUNCTION', 'ScheduleFormReducer');

// TODO: what are errors really?
export type Errors = {
  [key in keyof ReportInputErrors]: ReportParamInputError[] | ReportParamInputError | string;
};

export type ReportDeadline = 'DEFAULT_DEADLINE' | 'CUSTOM_DEADLINE';

export type ScheduleFormState = {
  /**
   * The name of the report to schedule.
   */
  reportName: string;
  /**
   * Start date of the schedule.
   */
  dateFrom: moment.MomentInput;
  /**
   * End date of the schedule.
   */
  dateUntil: moment.MomentInput;
  /**
   * Number of intervals between occurrences.
   */
  occurrenceInterval: number;
  /**
   * Is confirmation modal open.
   */
  modalConfirm: boolean;
  /**
   * Values of report params.
   */
  reportParamValues: IInventoryRequestParameterST[];
  /**
   * Report priority
   * from -1 the lowest till 10 the highest
   */
  priority: ReportPriority;
  /**
   * View state values of report params. Use where needed.
   */
  customParamValues: Record<IReportParameterUniqueNameST, string>;
  /**
   * In on recurring schedule.
   */
  isRecurring: boolean;
  /**
   * Interval for the schedule.
   */
  interval: IntervalLabel;
  /**
   * Maximum number of occurrences.
   */
  allowedOccurrenceIntervals: number;
  /**
   * Short labels for days of week.
   */
  days: WeekDayShort[];
  /**
   * Form errors
   */
  errors: Errors;
  /**
   * State of Recurrence component.
   */
  recurrenceState: RecurrenceState;
  /**
   * We may get existing request to edit.
   */
  originalRequest: IInventoryRequestST | null;
  /**
   * Report Deadline Automatic or Manual
   */
  reportDeadline: ReportDeadline;
  /**
   * Report Deadline Timeout
   */
  reportDeadlineTimeoutInMinutes: number | null;
  /**
   * Report Deadline Text
   */
  reportDeadlineText: string;
};

export const getInitialScheduleFormState = (
  originalRequest?: IInventoryRequestST,
): ScheduleFormState => ({
  reportName: '',
  dateFrom: moment(),
  dateUntil: moment(),
  occurrenceInterval: 1,
  priority: 0,
  modalConfirm: false,
  reportParamValues: [],
  customParamValues: {} as Record<IReportParameterUniqueNameST, string>,
  isRecurring: false,
  interval: 'daily' as IntervalLabel,
  allowedOccurrenceIntervals: 1,
  days: [],
  errors: {},
  recurrenceState: defaultRecurrence(),
  originalRequest: originalRequest || null,
  reportDeadline: 'DEFAULT_DEADLINE',
  reportDeadlineTimeoutInMinutes: null,
  reportDeadlineText: '',
});

export type ScheduleFormAction =
  | RecurrenceAction
  | {
      type: 'SET_DATE_FROM';
      payload: {
        reportSpec: IReportSpecificationST;
        dateFrom: moment.MomentInput;
        allowPastStartDate: boolean;
      };
    }
  | {
      type: 'SET_DATE_UNTIL';
      payload: {
        reportSpec: IReportSpecificationST;
        dateUntil: moment.MomentInput;
        allowPastStartDate: boolean;
      };
    }
  | {
      type: 'SET_DATE_FROM_AND_UNTIL';
      payload: { dateFrom: moment.MomentInput; dateUntil: moment.MomentInput };
    }
  | {
      type: 'SET_PRIORITY';
      payload: ReportPriority;
    }
  | {
      type: 'INITIALIZE_FORM_VALUES';
      payload: {
        reportName: string;
        dateFrom: moment.MomentInput;
        dateUntil: moment.MomentInput;
        priority: ReportPriority;
        isRecurring: boolean;
        interval: IntervalLabel;
        occurrenceInterval: number;
        days: WeekDayShort[];
        customParamValues: any;
      };
    }
  | {
      type: 'INITIALIZE_EXISTING_REQUEST';
      payload: { reportSpec: IReportSpecificationST; originalRequest: IInventoryRequestST };
    }
  | { type: 'INITIALIZE_NEW_REQUEST'; payload: { reportSpec: IReportSpecificationST } }
  | { type: 'CLEAR_INPUTS' }
  | { type: 'SET_REPORT_PARAM_VALUES'; payload: IInventoryRequestParameterST[] }
  | {
      type: 'SET_REPORT_PARAM_VALUE';
      payload: { paramUniqueName: IReportParameterUniqueNameST; value: string[] };
    }
  | { type: 'ADD_CUSTOM_PARAM_VALUES'; payload: { paramUniqueName: string; values: any } }
  | {
      type: 'VALIDATE_PARAM_VALUE';
      payload: { reportSpec: IReportSpecificationST; paramUniqueName: string };
    }
  | { type: 'SET_ERRORS'; payload: Errors }
  | { type: 'UPDATE_ERRORS'; payload: Partial<Errors> }
  | { type: 'SET_IS_RECURRING'; payload: boolean }
  | { type: 'SET_DAYS'; payload: WeekDayShort[] }
  | { type: 'SET_MODAL_CONFIRM'; payload: boolean }
  | { type: 'SET_ALLOWED_OCCURRENCE_INTERVALS'; payload: number }
  | { type: 'SET_REPORT_NAME'; payload: string }
  | { type: 'SET_OCCURRENCE_INTERVAL'; payload: number }
  | { type: 'SET_INTERVAL'; payload: IntervalLabel }
  | { type: 'SET_REPORT_DEADLINE'; payload: ReportDeadline }
  | { type: 'SET_REPORT_DEADLINE_TIMEOUT_MINUTES'; payload: number | null }
  | { type: 'SET_REPORT_DEADLINE_TEXT'; payload: string };

export const scheduleFormReducer = (
  state: ScheduleFormState,
  action: ScheduleFormAction,
): ScheduleFormState => {
  [state, action] = callSubReducer(state, action, 'recurrenceState', recurrenceReducer);

  switch (action.type) {
    case 'SET_REPORT_NAME': {
      const reportNameValidation = validateReportName(action.payload);
      return {
        ...state,
        reportName: action.payload,
        errors: { ...state.errors, validReportName: reportNameValidation.errorMsg },
      };
    }
    case 'SET_DATE_FROM': {
      const { reportSpec, dateFrom, allowPastStartDate } = action.payload;
      const newDateUntil = moment(dateFrom).isAfter(moment(state.dateUntil))
        ? moment(dateFrom).add(1, 'hours')
        : state.dateUntil;
      const fromDateValidation = validateStartDate(
        reportSpec,
        state.isRecurring,
        dateFrom,
        newDateUntil,
        allowPastStartDate,
      );
      const untilDateValidation = validateEndDate(
        reportSpec,
        state.isRecurring,
        state.dateFrom,
        newDateUntil,
      );
      const intervalOptions = calculateValidIntervalOptions(dateFrom, newDateUntil);

      let { interval } = state;
      const selectedIntervalIndex = intervalOptions.findIndex(
        (option) => option.label === interval,
      );

      if (!interval && intervalOptions[0].enabled) {
        interval = intervalOptions[0].label;
      } else if (!intervalOptions[selectedIntervalIndex]?.enabled) {
        const enabledOptions = intervalOptions.filter((option) => option.enabled);
        const newInterval = !isEmpty(enabledOptions) ? last(enabledOptions)?.label : '';
        interval = newInterval as IntervalLabel;
      }

      return {
        ...state,
        dateFrom,
        dateUntil: newDateUntil,
        interval,
        errors: {
          ...state.errors,
          validDateFrom: fromDateValidation.errorMsg,
          validDateUntil: untilDateValidation.errorMsg,
        },
        recurrenceState: {
          ...state.recurrenceState,
          intervalOptions,
        },
      };
    }
    case 'SET_DATE_UNTIL': {
      const { reportSpec, dateUntil, allowPastStartDate } = action.payload;
      const fromDateValidation = validateStartDate(
        reportSpec,
        state.isRecurring,
        state.dateFrom,
        dateUntil,
        allowPastStartDate,
      );
      const untilDateValidation = validateEndDate(
        reportSpec,
        state.isRecurring,
        state.dateFrom,
        dateUntil,
      );
      const intervalOptions = calculateValidIntervalOptions(state.dateFrom, dateUntil);

      let { interval } = state;
      const selectedIntervalIndex = intervalOptions.findIndex(
        (option) => option.label === interval,
      );

      if (!interval && intervalOptions[0].enabled) {
        interval = intervalOptions[0].label;
      } else if (!intervalOptions[selectedIntervalIndex]?.enabled) {
        const enabledOptions = intervalOptions.filter((option) => option.enabled);
        const newInterval = !isEmpty(enabledOptions) ? last(enabledOptions)?.label : '';
        interval = newInterval as IntervalLabel;
      }

      return {
        ...state,
        dateUntil,
        interval,
        errors: {
          ...state.errors,
          validDateFrom: fromDateValidation.errorMsg,
          validDateUntil: untilDateValidation.errorMsg,
        },
        recurrenceState: {
          ...state.recurrenceState,
          intervalOptions,
        },
      };
    }
    case 'SET_PRIORITY': {
      return { ...state, priority: action.payload };
    }
    case 'SET_DATE_FROM_AND_UNTIL':
      return { ...state, dateFrom: action.payload.dateFrom, dateUntil: action.payload.dateUntil };
    case 'INITIALIZE_FORM_VALUES':
      return { ...state, ...action.payload };
    case 'INITIALIZE_NEW_REQUEST': {
      const { reportSpec } = action.payload;
      const fixedParams = reportSpec.params
        .filter((param) => param.input_mode === INPUT_MODES.FIXED_VALUE)
        .map((param) => ({ unique_name: param.unique_name, values: param.default_values }));

      return {
        ...state,
        reportParamValues: [...fixedParams],
        dateFrom: moment().add(1, 'minutes'),
      };
    }
    case 'INITIALIZE_EXISTING_REQUEST': {
      const { reportSpec, originalRequest } = action.payload;
      // Convert rRule string to object and extract required data
      const {
        isRecurring: isRec,
        until,
        interval: occInterval,
        freq,
        days: daysData,
      } = convertRruleToUIComponents(originalRequest.rrule);

      const reportParamValues = originalRequest.params.map((param) => ({
        unique_name: param.unique_name,
        values: param.values,
      }));

      const customParamValues = originalRequest.params.reduce((acc, param) => {
        const paramSpec = getParamSpec(reportSpec, param.unique_name);
        if (paramSpec?.input_mode === INPUT_MODES.CUSTOM_VALUES) {
          if (param.unique_name === PARAM_TYPES.LOCATIONS_PERCENTAGE) {
            const values = param.values.map((el) => (parseFloat(el) * 100).toFixed(2).toString());
            return { ...acc, [param.unique_name]: values };
          }
          return { ...acc, [param.unique_name]: param.values };
        }
        return acc;
      }, {} as Record<IReportParameterUniqueNameST, string>);

      return {
        ...state,
        reportName: originalRequest.report_name,
        dateFrom: originalRequest.next_scheduling_time_utc,
        priority: originalRequest.priority as ReportPriority,
        dateUntil: until,
        isRecurring: isRec,
        interval: (freq || '') as IntervalLabel,
        occurrenceInterval: occInterval,
        days: daysData,
        reportParamValues,
        customParamValues,
        reportDeadline: originalRequest.timeout ? 'CUSTOM_DEADLINE' : 'DEFAULT_DEADLINE',
        reportDeadlineTimeoutInMinutes: originalRequest.timeout,
      };
    }
    case 'CLEAR_INPUTS': {
      const initialScheduleFormState = getInitialScheduleFormState();
      return {
        ...state,
        reportParamValues: [],
        customParamValues: { ...initialScheduleFormState.customParamValues },
        isRecurring: false,
        reportName: '',
        errors: { ...initialScheduleFormState.errors },
      };
    }
    case 'SET_REPORT_PARAM_VALUES':
      return { ...state, reportParamValues: action.payload };
    case 'SET_REPORT_PARAM_VALUE': {
      const { paramUniqueName, value } = action.payload;
      // Filter out the affected parameter, removing it form the internal state
      const auxReportParamValues = state.reportParamValues.filter(
        (el) => el.unique_name !== paramUniqueName,
      );
      // Re-instate the affected parameter, with its new value
      // if the content of the  value is empty, don't re-instate it.
      // this covers the case when in a multi-select, the user de-selects the options one-by-one
      const newReportParamValues = !isEmpty(value)
        ? [
            ...auxReportParamValues,
            { unique_name: paramUniqueName as IReportParameterUniqueNameST, values: value },
          ]
        : auxReportParamValues;
      return {
        ...state,
        reportParamValues: newReportParamValues as IInventoryRequestParameterST[],
      };
    }
    case 'ADD_CUSTOM_PARAM_VALUES':
      return {
        ...state,
        customParamValues: {
          ...state.customParamValues,
          [action.payload.paramUniqueName]: action.payload.values,
        },
      };
    case 'VALIDATE_PARAM_VALUE': {
      const { reportSpec, paramUniqueName } = action.payload;
      // Get all new errors for all params
      const allNewErrors = validateAllParamValues(
        reportSpec,
        state.reportParamValues,
      ).errorMessages;
      // Get new errors for the given paramUniqueName
      const paramErrors =
        allNewErrors.filter(
          (errorMsg: { unique_name: string }) => errorMsg.unique_name === paramUniqueName,
        ) || [];
      // Get all current errors, except the ones for the given paramUniqueName
      const currentErrorsAux =
        (state.errors?.validParams as ReportParamInputError[])?.filter(
          (p: { unique_name: string }) => p.unique_name !== paramUniqueName,
        ) || [];
      return {
        ...state,
        errors: { ...state.errors, validParams: [...currentErrorsAux, ...paramErrors] },
      };
    }
    case 'SET_ERRORS': {
      return { ...state, errors: action.payload };
    }
    case 'UPDATE_ERRORS': {
      return { ...state, errors: { ...state.errors, ...action.payload } };
    }
    case 'SET_IS_RECURRING': {
      let rRuleDays: WeekDayShort[] = [];
      if (state.isRecurring && !state.originalRequest) {
        const weekDaysList = Object.keys(RRULE_WEEKDAYS);
        rRuleDays = weekDaysList.filter(
          (day, index) => index === moment(state.dateFrom).weekday(),
        ) as WeekDayShort[];
      }
      return { ...state, isRecurring: action.payload, days: rRuleDays };
    }
    case 'SET_DAYS':
      return { ...state, days: action.payload };
    case 'SET_MODAL_CONFIRM':
      return { ...state, modalConfirm: action.payload };
    case 'SET_ALLOWED_OCCURRENCE_INTERVALS':
      return { ...state, allowedOccurrenceIntervals: action.payload };
    case 'SET_OCCURRENCE_INTERVAL':
      return { ...state, occurrenceInterval: action.payload };
    case 'SET_INTERVAL': {
      const interval = action.payload;
      const isSingleOccurrenceInterval = !includes(['weekly', 'monthly', 'yearly'], interval);
      return {
        ...state,
        interval,
        occurrenceInterval: isSingleOccurrenceInterval ? 1 : state.occurrenceInterval,
      };
    }
    case 'SET_REPORT_DEADLINE':
      return { ...state, reportDeadline: action.payload };
    case 'SET_REPORT_DEADLINE_TIMEOUT_MINUTES':
      return {
        ...state,
        reportDeadlineTimeoutInMinutes: action.payload,
      };
    case 'SET_REPORT_DEADLINE_TEXT':
      return { ...state, reportDeadlineText: action.payload };
    default:
      notEver(action);
      return state;
  }
};
