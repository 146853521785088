import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const sharedStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '100%',
  },
  container: {
    height: 'calc(100vh - 170px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '80%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
  },
  paper: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '450px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5),
      width: '100%',
    },
  },
  headerText: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    color: theme.palette.accent.main,
    fontWeight: 'bold',
  },
  textField: {
    width: '100%',
    margin: theme.spacing(3, 0, 4),
  },
  inputField: {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  submit: {
    marginLeft: theme.spacing(2),
    textDecoration: 'none',
  },
  routerLink: {
    color: theme.palette.textPrimary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  actionButtons: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  cardIcon: {
    fontSize: '50px',
    display: 'flex',
    placeItems: 'center',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    padding: theme.spacing(3),
    margin: theme.spacing(2, 0),
  },
  errorBorder: {
    borderColor: theme.palette.error.main,
  },
  description: { margin: '16px 0px' },
  formControl: {
    minWidth: 120,
    width: '100%',
    maxWidth: '100%',
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
  divider: {
    '&:before': {
      top: 0,
    },
    '&:after': {
      top: 0,
    },
  },
}));

export default sharedStyles;
